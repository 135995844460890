import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import {
  Formik,
  FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { MFCheckbox } from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';
import MFSelectField from '../../lib/formik/SelectField';
import MFTextField from '../../lib/formik/TextField';
import { updateApplication } from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import { getStrategies } from '../../redux-store/actions/strategies';
import { RootStateType } from '../../redux-store/reducers';
import { StrategyDetailsType } from '../../redux-store/types/api-types';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  CHECKER_EDIT_ALL_SECTIONS,
  FundInvestStockMarket,
  ModeOfTransaction,
  strategyFeesMaster,
  strategyFeeTypeMaster,
  strategyPerformanceFeeTypeMaster,
  USER_ROLES,
} from '../../utils/constant';
import { strategyDeclarationScheme } from '../../utils/schema';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkInitialApplicationStatusIsChecker,
  checkStrategyBoxSelected,
  currencyConversion,
  getDisableStatusForjointHolder,
  getTotalStrategyAmount,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

export type updatedStrategyDetails = StrategyDetailsType & {
  maxFee: number | null;
  minFee: number | null;
  performanceMaxFee: number | null;
  performanceMinFee: number | null;
  exitMaxFee: number | null;
  exitMinFee: number | null;
};
type Values = {
  applicationstrategydetails: updatedStrategyDetails[];
  totalStrategyInvestmentAmount: string | number | null;
  ownBorrowedFunds: string | null;
  cheque: boolean;
  rtgs: boolean;
  saveType: string;
};

export const newStrategy: updatedStrategyDetails = {
  strategyName: '',
  strategyType: '',
  fee: null,
  strategyFees: null,
  performanceFee: null,
  exitFee: null,
  performanceFeeNature: '',
  managementFeeNature: '',
  investmentAmount: null,
  isActive: false,
  maxFee: null,
  minFee: null,
  performanceMaxFee: null,
  performanceMinFee: null,
  exitMaxFee: null,
  exitMinFee: null,
};

const initialValues: Values = {
  applicationstrategydetails: [],
  totalStrategyInvestmentAmount: null,
  ownBorrowedFunds: '',
  cheque: false,
  rtgs: false,
  saveType: 'save and proceed',
};

function StrategyAccordion({
  values,
  setValues,
  strategyMasterData,
  strategy,
  applicationStrategyIndex,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  strategyMasterData: GetStrategiesResponseBody[];
  strategy: updatedStrategyDetails;
  applicationStrategyIndex: number;
}): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);

  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        mb: 3,
        cursor: 'default',
        '&::before': { display: 'none' },
        '& .MuiFormControl-root': { m: 0, height: 44 },
        '& .MuiOutlinedInput-root': {
          mt: 0,
        },
        '& .label': { fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)', mb: 1 },
      }}
      expanded={strategy?.isActive}>
      <AccordionSummary
        sx={{
          bgcolor: 'rgba(221, 234, 243, 0.2)',
          borderRadius: '5px',
          cursor: 'default',
          '&.Mui-expanded': { minHeight: 'unset', cursor: 'default' },
          '& .MuiAccordionSummary-content': { m: 0, py: 0.7, '&.Mui-expanded': { m: 0 } },
        }}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack>
          <Typography
            sx={{
              color: '#131836',
              fontSize: 16,
              fontWeight: 500,
              width: '100%',
              cursor: 'default',
              display: 'flex',
              alignItems: 'center',
            }}>
            <MFCheckbox
              name={`applicationstrategydetails.${applicationStrategyIndex}.isActive`}
              label=""
              sx={{ letterSpacing: '1px', mr: 0 }}
              onChange={({ target: { checked } }) => {
                const updatedStrategy = values.applicationstrategydetails?.map(
                  (strategyDetails, _index) => {
                    if (applicationStrategyIndex === _index) {
                      return {
                        ...strategyDetails,
                        isActive: checked,
                        fee: checked ? strategyMasterData[applicationStrategyIndex].fee : '',
                        investmentAmount: checked
                          ? strategyMasterData[applicationStrategyIndex].investmentAmount
                          : '',
                        managementFeeNature: checked ? strategyDetails.managementFeeNature : '',
                        performanceFeeNature: checked ? strategyDetails.performanceFeeNature : '',
                        performanceFee: checked
                          ? strategyMasterData[applicationStrategyIndex].performanceFee
                          : '',
                        exitFee: checked
                          ? strategyMasterData[applicationStrategyIndex].exitFee || 0
                          : '',
                      };
                    }
                    return strategyDetails;
                  }
                ) as updatedStrategyDetails[];

                // setValues({
                //   ...values,
                //   applicationstrategydetails: updatedStrategy,
                //   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
                // });
              }}
              disabled={isFieldDisabled || [USER_ROLES.AMCAPPROVER].includes(role)}
            />
            {strategy?.strategyName}{' '}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={{ xs: 4, sm: 8 }}>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Management Fee Nature *</Typography>
            <MFSelectField
              name={`applicationstrategydetails.${applicationStrategyIndex}.managementFeeNature`}
              items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
                key: strategyFeeTypeMaster[fee],
                value: fee,
              }))}
              applyLabelStyles={true}
              onChange={({ target: { value } }) => {
                const updatedStrategy = values.applicationstrategydetails?.map(
                  (strategyDetails, _index) => {
                    if (applicationStrategyIndex === _index) {
                      return {
                        ...strategyDetails,
                        managementFeeNature: value,
                        fee: value !== strategyFeeTypeMaster.none ? strategy.fee : 0,
                      };
                    }
                    return strategyDetails;
                  }
                ) as updatedStrategyDetails[];

                // setValues({
                //   ...values,
                //   applicationstrategydetails: updatedStrategy,
                //   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
                // });
              }}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 1 }}>
            <Typography className="label">Management Fee *</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFTextField
                name={`applicationstrategydetails.${applicationStrategyIndex}.fee`}
                placeholder="Enter Fee"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  isFieldDisabled || strategy.managementFeeNature === strategyFeeTypeMaster.none
                }
              />
              {strategyFeeTypeMaster[strategy.managementFeeNature] ===
                strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Performance Fee Nature *</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFSelectField
                name={`applicationstrategydetails.${applicationStrategyIndex}.performanceFeeNature`}
                items={Object.keys(strategyPerformanceFeeTypeMaster).map((fee) => ({
                  key: strategyPerformanceFeeTypeMaster[fee],
                  value: fee,
                }))}
                applyLabelStyles={true}
                onChange={({ target: { value } }) => {
                  const updatedStrategy = values.applicationstrategydetails?.map(
                    (strategyDetails, _index) => {
                      if (applicationStrategyIndex === _index) {
                        return {
                          ...strategyDetails,
                          performanceFeeNature: value,
                          performanceFee:
                            value !== strategyPerformanceFeeTypeMaster.none
                              ? strategy.performanceFee
                              : 0,
                        };
                      }
                      return strategyDetails;
                    }
                  ) as updatedStrategyDetails[];

                  // setValues({
                  //   ...values,
                  //   applicationstrategydetails: updatedStrategy,
                  //   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
                  // });
                }}
                disabled={isFieldDisabled}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 1 }}>
            <Typography className="label">Performance Fee *</Typography>
            <Stack
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFTextField
                name={`applicationstrategydetails.${applicationStrategyIndex}.performanceFee`}
                placeholder="Enter Fee"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  isFieldDisabled ||
                  strategy.performanceFeeNature === strategyPerformanceFeeTypeMaster.none
                }
              />
              {[
                strategyPerformanceFeeTypeMaster.without_catchup,
                strategyPerformanceFeeTypeMaster.with_catchup,
              ].includes(strategyPerformanceFeeTypeMaster[strategy.performanceFeeNature]) && (
                <Typography sx={{ ml: 1 }}>%</Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <Typography className="label">Exit Fee *</Typography>
            <MFTextField
              name={`applicationstrategydetails.${applicationStrategyIndex}.exitFee`}
              placeholder="Enter Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Investment Amount *</Typography>
            <Box
              sx={{
                width: '100%',
                '& .MuiFormControl-root': { m: 0 },
              }}>
              <MFTextField
                name={`applicationstrategydetails.${applicationStrategyIndex}.investmentAmount`}
                placeholder="Enter Investment Amount"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  const updatedAmount = values.applicationstrategydetails?.map(
                    (strategyDetails, _index) => {
                      if (applicationStrategyIndex === _index) {
                        return {
                          ...strategyDetails,
                          investmentAmount: value ? Number(value) : null,
                        };
                      }
                      return strategyDetails;
                    }
                  );

                  // setValues({
                  //   ...values,
                  //   applicationstrategydetails: updatedAmount,
                  //   totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
                  // });
                }}
                trimOnBlur={false}
                disabled={isFieldDisabled}
              />
              {Number(strategy?.investmentAmount) > 0 && strategy?.isActive && (
                <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
                  {currencyConversion(strategy?.investmentAmount)}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="label">Benchmark</Typography>{' '}
            <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
              {strategy?.benchmark || '-'}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function StrategyTable({
  values,
  setValues,
  strategyMasterData,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  strategyMasterData: GetStrategiesResponseBody[];
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const { application } = useSelector((store: RootStateType) => store.application);
  // const { staggeredStrategyFrom = '', staggeredStrategyTo = '' } = application || {};

  const headers = [
    {
      header: 'Strategy',
      label: 'strategy',
      renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
        return (
          <>
            <MFCheckbox
              name={`applicationstrategydetails.${index}.isActive`}
              label={params.strategyName}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              onChange={({ target: { checked } }) =>
                values.applicationstrategydetails.map((investment, ind) => {
                  const Checked = ind === index;
                  return setFieldValue(`applicationstrategydetails.${ind}`, {
                    ...values.applicationstrategydetails[ind],
                    isActive: Checked,
                    strategyFees: Checked ? params.strategyFees : '',
                  });
                })
              }
            />
          </>
        );
      },
    },
    {
      header: 'Fees',
      label: 'strategyFees',
      renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
        return (
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              display: 'inline-flex',
              width: 350,
              maxWidth: '100%',
              py: 1,
              '& .MuiFormControl-root': { width: '100%', m: 0 },
            }}>
            <MFSelectField
              sx={{
                '& .MuiOutlinedInput-root': { m: 0 },
                '& .Mui-disabled': {
                  cursor: params.isActive ? 'pointer' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
              name={`applicationstrategydetails.${index}.strategyFees`}
              items={Object.keys(strategyFeesMaster).map((fee) => ({
                key: strategyFeesMaster[fee],
                value: fee,
              }))}
              disabled={!params.isActive}
            />
          </Stack>
        );
      },
    },
  ];
  // const headers = [
  //   {
  //     header: 'Strategy',
  //     label: 'strategy',
  //     renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
  //       return (
  //         <>
  //           <MFCheckbox
  //             name={`applicationstrategydetails.${index}.isActive`}
  //             label={params.strategyName}
  //             sx={{ letterSpacing: '1px', width: 140, maxWidth: '100%', mt: 1 }}
  //             onChange={({ target: { checked } }) => {
  //               const updatedStrategy = values.applicationstrategydetails?.map(
  //                 (strategyDetails, _index) => {
  //                   if (index === _index) {
  //                     return {
  //                       ...strategyDetails,
  //                       isActive: checked,
  //                       fee: checked ? strategyMasterData[index].fee : '',
  //                       investmentAmount: checked ? strategyMasterData[index].investmentAmount : '',
  //                       managementFeeNature: checked ? strategyDetails.managementFeeNature : '',
  //                       performanceFeeNature: checked ? strategyDetails.performanceFeeNature : '',
  //                       performanceFee: checked ? strategyMasterData[index].performanceFee : '',
  //                     };
  //                   }
  //                   return strategyDetails;
  //                 }
  //               ) as updatedStrategyDetails[];

  //               setValues({
  //                 ...values,
  //                 applicationstrategydetails: updatedStrategy,
  //                 totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
  //               });
  //             }}
  //           />
  //           <Typography
  //             sx={{
  //               ml: 4,
  //               mt: -2,
  //             }}>
  //             ({params?.strategyType})
  //           </Typography>
  //           {(staggeredStrategyFrom || staggeredStrategyTo) &&
  //             params?.id &&
  //             (Number(params.id) === Number(staggeredStrategyFrom) ||
  //               Number(params.id) === Number(staggeredStrategyTo)) && (
  //               <Typography sx={{ fontSize: 13, textAlign: 'left', color: 'primary.main' }}>
  //                 This Strategy was selected in staggered investment plan
  //                 {/* This Strategy is selected as{' '}
  //                 {Number(params.id) === Number(staggeredStrategyFrom)
  //                   ? 'strategy from'
  //                   : 'strategy to'}{' '}
  //                 in staggered investment plan details */}
  //               </Typography>
  //             )}
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     header: 'Management Fee Nature',
  //     label: 'managementFeeNature',
  //     renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
  //       return (
  //         <Stack
  //           sx={{
  //             flexDirection: 'row',
  //             alignItems: 'center',
  //             display: 'inline-flex',
  //             width: 150,
  //             maxWidth: '100%',
  //             py: 1,
  //             '& .MuiFormControl-root': { width: 180, m: 0 },
  //           }}>
  //           <MFSelectField
  //             name={`applicationstrategydetails.${index}.managementFeeNature`}
  //             items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
  //               key: strategyFeeTypeMaster[fee],
  //               value: fee,
  //             }))}
  //             applyLabelStyles={true}
  //             onChange={({ target: { value } }) => {
  //               const updatedStrategy = values.applicationstrategydetails?.map(
  //                 (strategyDetails, _index) => {
  //                   if (index === _index) {
  //                     return {
  //                       ...strategyDetails,
  //                       managementFeeNature: value,
  //                       fee:
  //                         value !== strategyFeeTypeMaster.none ? strategyMasterData[index].fee : 0,
  //                     };
  //                   }
  //                   return strategyDetails;
  //                 }
  //               ) as updatedStrategyDetails[];

  //               setValues({
  //                 ...values,
  //                 applicationstrategydetails: updatedStrategy,
  //                 totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
  //               });
  //             }}
  //             disabled={!params?.isActive}
  //           />
  //         </Stack>
  //       );
  //     },
  //   },
  //   {
  //     header: 'Management Fee',
  //     label: 'fee',
  //     renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
  //       return (
  //         <Stack
  //           sx={{
  //             flexDirection: 'row',
  //             alignItems: 'center',
  //             display: 'inline-flex',
  //             width: 110,
  //             maxWidth: '100%',
  //             pb: 1.3,
  //             pt: 2,
  //             '& .MuiFormControl-root': { width: 120, m: 0 },
  //           }}>
  //           <MFTextField
  //             name={`applicationstrategydetails.${index}.fee`}
  //             placeholder="Enter Fee"
  //             type="number"
  //             onKeyDown={(e) => {
  //               allowOnlyNumbers(e);
  //             }}
  //             trimOnBlur={false}
  //             disabled={
  //               !params?.isActive || params.managementFeeNature === strategyFeeTypeMaster.none
  //             }
  //           />
  //           {strategyFeeTypeMaster[params.managementFeeNature] ===
  //             strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
  //         </Stack>
  //       );
  //     },
  //   },

  //   {
  //     header: 'Performance Fee Nature',
  //     label: 'performanceFeeNature',
  //     renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
  //       return (
  //         <Stack
  //           sx={{
  //             flexDirection: 'row',
  //             alignItems: 'center',
  //             display: 'inline-flex',
  //             width: 150,
  //             maxWidth: '100%',
  //             py: 1,
  //             '& .MuiFormControl-root': { width: 180, m: 0 },
  //           }}>
  //           <MFSelectField
  //             name={`applicationstrategydetails.${index}.performanceFeeNature`}
  //             items={Object.keys(strategyFeeTypeMaster).map((fee) => ({
  //               key: strategyFeeTypeMaster[fee],
  //               value: fee,
  //             }))}
  //             applyLabelStyles={true}
  //             onChange={({ target: { value } }) => {
  //               const updatedStrategy = values.applicationstrategydetails?.map(
  //                 (strategyDetails, _index) => {
  //                   if (index === _index) {
  //                     return {
  //                       ...strategyDetails,
  //                       performanceFeeNature: value,
  //                       performanceFee:
  //                         value !== strategyFeeTypeMaster.none
  //                           ? strategyMasterData[index].performanceFee
  //                           : 0,
  //                     };
  //                   }
  //                   return strategyDetails;
  //                 }
  //               ) as updatedStrategyDetails[];

  //               setValues({
  //                 ...values,
  //                 applicationstrategydetails: updatedStrategy,
  //                 totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
  //               });
  //             }}
  //             disabled={!params?.isActive}
  //           />
  //         </Stack>
  //       );
  //     },
  //   },
  //   {
  //     header: 'Performance Fee',
  //     label: 'performanceFee',
  //     renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
  //       return (
  //         <Stack
  //           sx={{
  //             flexDirection: 'row',
  //             alignItems: 'center',
  //             display: 'inline-flex',
  //             width: 110,
  //             maxWidth: '100%',
  //             pb: 1.3,
  //             pt: 2,
  //             '& .MuiFormControl-root': { width: 120, m: 0 },
  //           }}>
  //           <MFTextField
  //             name={`applicationstrategydetails.${index}.performanceFee`}
  //             placeholder="Enter Fee"
  //             type="number"
  //             onKeyDown={(e) => {
  //               allowOnlyNumbers(e);
  //             }}
  //             trimOnBlur={false}
  //             disabled={
  //               !params?.isActive || params.performanceFeeNature === strategyFeeTypeMaster.none
  //             }
  //           />
  //           {strategyFeeTypeMaster[params.performanceFeeNature] ===
  //             strategyFeeTypeMaster.flatOnAum && <Typography sx={{ ml: 1 }}>%</Typography>}
  //         </Stack>
  //       );
  //     },
  //   },
  //   {
  //     header: 'Investment Amount',
  //     label: 'investmentAmount',
  //     renderEditDeleteCell: (params: updatedStrategyDetails, index: number): JSX.Element => {
  //       return (
  //         <Box
  //           sx={{
  //             width: 200,
  //             maxWidth: '100%',
  //             pb: 1.3,
  //             pt: 2,
  //             '& .MuiFormControl-root': { m: 0 },
  //           }}>
  //           <MFTextField
  //             name={`applicationstrategydetails.${index}.investmentAmount`}
  //             placeholder="Enter Investment Amount"
  //             type="number"
  //             onKeyDown={(e) => {
  //               allowOnlyNumbers(e);
  //             }}
  //             onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
  //               const updatedAmount = values.applicationstrategydetails?.map(
  //                 (strategyDetails, _index) => {
  //                   if (index === _index) {
  //                     return {
  //                       ...strategyDetails,
  //                       investmentAmount: value ? Number(value) : null,
  //                     };
  //                   }
  //                   return strategyDetails;
  //                 }
  //               );

  //               setValues({
  //                 ...values,
  //                 applicationstrategydetails: updatedAmount,
  //                 totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedAmount) || '',
  //               });
  //             }}
  //             trimOnBlur={false}
  //             disabled={!params?.isActive}
  //           />
  //           {Number(params?.investmentAmount) > 0 && params?.isActive && (
  //             <Typography sx={{ fontSize: 13, mt: 1, textAlign: 'left' }}>
  //               {currencyConversion(params?.investmentAmount)}
  //             </Typography>
  //           )}
  //         </Box>
  //       );
  //     },
  //   },
  // ];

  return (
    <DataTable
      tableData={values.applicationstrategydetails}
      tableHeader={headers}
      renderAdditionalRow={false}
      tableHeaderCustomStyles={{
        '.MuiTableCell-head': {
          fontFamily: 'Poppins, sans-serif',
          fontSize: 14,
          fontWeight: 500,
          color: 'primary.main',
          textAlign: 'left',
          pl: 2,
          '&:nth-of-type(1)': {
            pl: 6,
          },
        },
      }}
      rowCustomStyles={{
        '.MuiTableCell-root': {
          py: 0,
          overflowWrap: 'anywhere',
          textAlign: 'left',
          verticalAlign: 'top',
        },
      }}
      boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
    />
  );
}

export default function StrategyDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [strategyData, setStrategyData] = useState<GetStrategiesResponseBody[]>([]);
  const [selectedStrategyData, setSelectedStrategyData] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(
          getStrategies({ isActive: true })
        )) as unknown as GetStrategiesResponseBody[];
        if (!isComponentAlive) {
          return;
        }
        setStrategyData(response);
        const { applicationstrategydetails = [] } = application || {};

        setSelectedStrategyData({
          ...selectedStrategyData,
          totalStrategyInvestmentAmount: application?.totalStrategyInvestmentAmount || null,
          ownBorrowedFunds: application?.ownBorrowedFunds || '',
          cheque: application?.modeOfPayment ? application.modeOfPayment.includes('cheque') : false,
          rtgs: application?.modeOfPayment ? application.modeOfPayment.includes('rtgs') : false,
          applicationstrategydetails: response
            ?.map((strategy) => {
              const existingStrategyDetails = applicationstrategydetails?.filter(
                (strategyDetails) => strategyDetails.strategyId === strategy.id
              );

              return existingStrategyDetails.length
                ? existingStrategyDetails?.map((existingStrategy) => {
                    return {
                      ...existingStrategy,
                      maxFee: strategy.maxFee,
                      minFee: strategy.minFee,
                      performanceMaxFee: strategy.maxPerformanceFee,
                      performanceMinFee: strategy.minPerformanceFee,
                      exitMaxFee: strategy.maxExitFee,
                      exitMinFee: strategy.minExitFee,
                    };
                  })
                : [
                    {
                      ...newStrategy,
                      strategyName: strategy.strategyName,
                      strategyType: strategy.strategyType,
                      strategyId: strategy.id,
                      maxFee: strategy.maxFee,
                      minFee: strategy.minFee,
                      performanceMaxFee: strategy.maxPerformanceFee,
                      performanceMinFee: strategy.minPerformanceFee,
                      exitMaxFee: strategy.maxExitFee,
                      exitMinFee: strategy.minExitFee,
                      benchmark: strategy.benchmark,
                      managementFeeNature: strategy.managementFeeNature,
                      performanceFeeNature: strategy.performanceFeeNature,
                    },
                  ];
            })
            .flat(),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicationstrategydetails, cheque, rtgs, saveType, ...rest } = values;

      // if (
      //   applicationstrategydetails.length &&
      //   !checkStrategyBoxSelected(applicationstrategydetails)
      // ) {
      //   throw 'Please Select atleast One Strategy';
      // }
      let paymentMode = '';
      if (cheque && rtgs) {
        paymentMode = Object.keys(ModeOfTransaction)?.toString();
      } else if (cheque && !rtgs) {
        paymentMode = 'cheque';
      } else if (!cheque && rtgs) {
        paymentMode = 'rtgs';
      }

      const updatedPayload = {
        ...application,
        ...rest,
        totalStrategyInvestmentAmount: rest.totalStrategyInvestmentAmount || null,
        modeOfPayment: paymentMode,
        applicationstrategydetails: applicationstrategydetails
          ?.filter((strategyDetails) => strategyDetails.id || strategyDetails.isActive)
          ?.map((strategy) => {
            const {
              maxFee,
              minFee,
              performanceMaxFee,
              performanceMinFee,
              exitMaxFee,
              exitMinFee,
              ...rest
            } = strategy;
            return {
              ...rest,
              investmentAmount: rest.investmentAmount || null,
              fee: rest.fee || rest.fee === 0 ? rest.fee : null,
              performanceFee:
                rest.performanceFee || rest.performanceFee === 0 ? rest.performanceFee : null,
              exitFee: rest.exitFee || rest.exitFee === 0 ? rest.exitFee : null,
            };
          }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          applicationstrategydetails: application?.applicationstrategydetails?.sort(
            (details1, details2) => Number(details1.id) - Number(details2.id)
          ),
        },
        {
          ...updatedPayload,
          applicationstrategydetails: updatedPayload?.applicationstrategydetails?.sort(
            (details1, details2) => Number(details1.id) - Number(details2.id)
          ),
          currentStep: !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep:
                USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? currentStep : 9,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
            ? history.push(`/application-details/${id}`)
            : history.push('disclosure-of-interest', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
            ? history.push(`/application-details/${id}`)
            : history.push('disclosure-of-interest', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  return (
    <>
      <Formik
        initialValues={selectedStrategyData}
        validate={(values: Values) => {
          try {
            validateYupSchema(values, strategyDeclarationScheme, true, values);
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}>
        {({ handleSubmit, setValues, values, errors }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              {/* <Notes
                displayContent={'Any strategy change could affect the staggered investment plan.'}
              /> */}
              {values.applicationstrategydetails?.length !== 0 && (
                // <Box sx={{ mt: 3 }}>
                //   {values.applicationstrategydetails?.map((strategy, index) => (
                //     <StrategyAccordion
                //       key={index}
                //       values={values}
                //       setValues={setValues}
                //       strategyMasterData={strategyData}
                //       strategy={strategy}
                //       applicationStrategyIndex={index}
                //     />
                //     ))}
                // </Box>
                <StrategyTable
                  values={values}
                  setValues={setValues}
                  strategyMasterData={strategyData}
                />
              )}
              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontSize: 25, fontWeight: 500, color: '#131836', mb: 4 }}>
                  Source of Investment
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                  Complete the funding source details by which the Holder(s) wish to fund this PMS
                  investment.
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                  <Typography
                    component="span"
                    sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                    Note :{' '}
                  </Typography>
                  This section is required to be filled based on SEBI’s regulation pertaining to the
                  prevention of money laundering act, 2002.
                </Typography>
                {/* <SubHeading sx={{ color: '#131836', fontSize: 18, fontWeight: 500, mt: 0, mb: 3 }}>
                  Fund details
                </SubHeading>
                <Box sx={{ mb: 5, maxWidth: 310, '& .MuiFormControl-root': { mb: 0 } }}>
                  <MFTextField
                    name="totalStrategyInvestmentAmount"
                    label="Total Investment Amount (in INR)"
                    placeholder="Enter Total Investment Amount"
                    type="number"
                    onKeyDown={(e) => {
                      allowOnlyNumbers(e);
                    }}
                    trimOnBlur={false}
                    disabled={true}
                  />
                  {values.totalStrategyInvestmentAmount && (
                    <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                      {currencyConversion(values.totalStrategyInvestmentAmount)}
                    </Typography>
                  )}
                </Box> */}
                <UseRadioGroup
                  formLabel="Do you intend to invest in the stock market with own Funds/Borrowed Funds? *"
                  name="ownBorrowedFunds"
                  items={Object.keys(FundInvestStockMarket).map((fundType) => ({
                    label: FundInvestStockMarket[fundType],
                    value: fundType,
                  }))}
                  disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
                />
                {/* <Box sx={{ mt: 5 }}>
                  <Typography
                    sx={{ fontSize: 14, color: 'rgba(38, 48, 60, 0.7)', fontWeight: 500 }}>
                    What is the mode of transaction for this investment? *
                  </Typography>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      position: 'relative',
                      '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(38, 48, 60, 0.6)',
                      },
                      '& .MuiFormHelperText-root': {
                        position: 'absolute',
                        left: 0,
                        bottom: -15,
                      },
                    }}>
                    <MFCheckbox
                      name="cheque"
                      label="Cheque"
                      sx={{ letterSpacing: '1px', mr: 4 }}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}
                    />
                    <Grid item xs={12} sm={12} sx={{ mb: { xs: 2.8, md: 1 } }}>
                      <MFCheckbox
                        name="rtgs"
                        label="Online Transfer (RTGS)"
                        sx={{ letterSpacing: '1px' }}
                        disabled={getDisableStatusForjointHolder(
                          role,
                          referenceDetail.applicant_type
                        )}
                      />
                    </Grid>
                  </Stack>
                </Box> */}
              </Box>
              <ProceedSaveLater
                saveLater={() => {
                  setValues({
                    ...values,
                    saveType: 'save for later',
                  });
                }}
                saveAndProceed={() => {
                  setValues({
                    ...values,
                    saveType: 'save and proceed',
                  });
                }}
                loader={loading}
                clickedButton={values.saveType}
                saveButtonText={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS
                    ? 'Save Application'
                    : 'Save and Proceed'
                }
                showSaveForLater={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? false : true
                }
                showEndIcon={
                  USER_ROLES.AMCAPPROVER === role && !CHECKER_EDIT_ALL_SECTIONS ? false : true
                }
              />
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
