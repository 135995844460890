import { FormControl, FormHelperText, InputLabel, TextField, Theme } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { SxProps } from '@mui/system';
import { useField } from 'formik';
import { checkAsterikInString, RemoveAsterikFromstring } from '../../utils/utilityFunctions';

const defaultTextFieldStyles: SxProps<Theme> = {
  'label + &': {
    mt: 4,
  },
  '& .MuiInputBase-root': {
    border: '1px solid #DDEAF3',
  },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    backgroundColor: 'common.white',
    border: '1px solid #DDEAF3',
    fontSize: 16,
    '&:hover': {
      borderColor: 'primary.main',
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '.MuiInputBase-input': {
      p: '10px 12px',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
};

export const DatePicker = ({
  label = '',
  placeholder = '',
  name,
  inputLabelStyles,
  textFieldStyles = defaultTextFieldStyles,
  maxDate = new Date(),
  minDate,
  disabled = false,
  onClick,
}: {
  label?: string;
  placeholder: string;
  value?: string | null;
  onClick?: () => void;
  name: string;
  inputLabelStyles?: SxProps<Theme>;
  textFieldStyles?: SxProps<Theme>;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
}): JSX.Element => {
  const [field, meta, { setValue }] = useField(name);

  const onChange = (date: Date | null) => {
    setValue(date);
    onClick && onClick();
  };
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl variant="outlined" fullWidth sx={{ my: { xs: 1, md: 2 } }}>
        <InputLabel shrink htmlFor="date-picker" sx={{ ...inputLabelStyles }}>
          {RemoveAsterikFromstring(label)}
          {checkAsterikInString(label) && <span style={{ color: 'red' }}>*</span>}
        </InputLabel>
        <MobileDatePicker
          // value={value ? value : maxDate}
          maxDate={maxDate}
          minDate={minDate}
          components={{ OpenPickerIcon: KeyboardArrowRightSharpIcon }}
          value={field.value}
          inputFormat="dd/MM/yyyy"
          onChange={onChange}
          disabled={disabled}
          views={['year', 'month', 'day']}
          renderInput={(params) => (
            <TextField
              fullWidth
              id="date-picker"
              placeholder={placeholder}
              {...params}
              sx={{
                ...textFieldStyles,
                '& .Mui-disabled': {
                  cursor: !disabled ? 'text' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
            />
          )}
          disableCloseOnSelect={false}
          showToolbar={false}
        />
        {errorText && (
          <FormHelperText error sx={{ marginLeft: 'unset' }}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};
