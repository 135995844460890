/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { number } from 'yargs';
import { CALL_API } from '../middleware';
import {
  DistributorListType,
  RMType,
  addRmDetails,
  AddDistributorRequestBody,
  Trustee,
  AddTrusteeRequestBody,
  AddFundManagerRequestBody,
  AddAifApproverRequestBody,
  AdddistributorRM,
  DistributorRM,
  FundManager,
  AuthorisedSignatories,
  AddAuthorisedSignatoryRequestBody,
  poaApprover,
  AddPoaApproverRequestBody,
  nationaliyType,
  AddDistributorRMRequestBody,
  SubDistributorListType,
  RmWithDistributors,
  AmcAdmin,
  AddAmcAdminRequestBody,
  AMCAuthorisedSignatoryType,
  AddAmcAuthorisedSignatoryType,
} from '../types/api-types';
import {
  GET_DISTRIBUTORS_LIST,
  GET_DISTRIBUTORS_LIST_SUCCESS,
  GET_RM_LIST,
  GET_RM_LIST_SUCCESS,
  ADD_RMS_LIST_SUCCESS,
  ADD_RMS_LIST,
  ADD_DISTRIBUTOR,
  ADD_DISTRIBUTOR_SUCCESS,
  EDIT_DISTRIBUTOR_SUCCESS,
  GET_SUBDISTRIBUTOR_SUCCESS,
  GET_SUBDISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  UPDATE_FUND_MANAGER_SUCCESS,
  UPDATE_FUND_MANAGER,
  UPDATE_TRUSTEE_SUCCESS,
  GET_Countries_SUCCESS,
  GET_Countries,
  UPDATE_TRUSTEE,
  EDIT_RM_SUCCESS,
  EDIT_RM,
  GET_AIF_APPROVER_LIST_SUCCESS,
  GET_AIF_APPROVER_LIST,
  ADD_DISTRIBUTOR_RM_SUCCESS,
  ADD_DISTRIBUTOR_RM,
  UPDATE_DISTRIBUTOR_RM_SUCCESS,
  UPDATE_DISTRIBUTOR_RM,
  GET_TRUSTEE_LIST_SUCCESS,
  GET_TRUSTEE_LIST,
  ADD_TRUSTEE_SUCCESS,
  ADD_TRUSTEE,
  GET_FUND_MANAGERS_LIST_SUCCESS,
  GET_FUND_MANAGERS_LIST,
  ADD_FUND_MANAGER_SUCCESS,
  ADD_FUND_MANAGER,
  GET_AUTHORISEDSIGNATORY_LIST_SUCCESS,
  GET_AUTHORISEDSIGNATORY_LIST,
  ADD_AUTHORISEDSIGNATORY_SUCCESS,
  ADD_AUTHORISEDSIGNATORY,
  UPDATE_AUTHORISEDSIGNATORY_SUCCESS,
  UPDATE_AUTHORISEDSIGNATORY,
  GET_POAAPPROVER_LIST_SUCCESS,
  GET_POAAPPROVER_LIST,
  ADD_POAAPPROVER_SUCCESS,
  ADD_POAAPPROVER,
  UPDATE_POAAPPROVER_SUCCESS,
  UPDATE_POAAPPROVER,
  GET_AIFAPPROVERACCESS_SUCCESS,
  GET_AIFAPPROVERACCESS,
  GET_RMBYID,
  GET_RMBYID_SUCCESS,
  GET_DISTRIBUTORRMBYID_SUCCESS,
  GET_DISTRIBUTORRMBYID,
  GET_AUTHORISEDSIGNATORYBYID_SUCCESS,
  GET_AUTHORISEDSIGNATORYBYID,
  GET_POAAPPROVERBYID_SUCCESS,
  GET_POAAPPROVERBYID,
  GET_TRUSTEEBYID_SUCCESS,
  GET_TRUSTEEBYID,
  GET_AIF_APPROVERBYID_SUCCESS,
  GET_AIF_APPROVERBYID,
  GET_FUND_MANAGERSBYID_SUCCESS,
  GET_FUND_MANAGERSBYID,
  GET_DISTRIBUTORSOFRM_BY_RMID_SUCCESS,
  GET_DISTRIBUTORSOFRM_BY_RMID,
  GET_TRUSTEEACCESS,
  GET_TRUSTEEACCESS_SUCCESS,
  GET_IMACCESS_SUCCESS,
  GET_IMACCESS,
  GET_AMCADMINACCESS,
  GET_AMCADMINACCESS_SUCCESS,
  GET_AMCADMIN_LIST_SUCCESS,
  GET_AMCADMIN_LIST,
  GET_AMCADMINBYID,
  GET_AMCADMINBYID_SUCCESS,
  ADD_AMCADMIN_SUCCESS,
  ADD_AMCADMIN,
  UPDATE_AMCADMIN_SUCCESS,
  UPDATE_AMCADMIN,
  GET_AMC_AUTHORISED_SIGNATORY_LIST_SUCCESS,
  GET_AMC_AUTHORISED_SIGNATORY_LIST,
  ADD_AMC_AUTHORISED_SIGNATORY_SUCCESS,
  ADD_AMC_AUTHORISED_SIGNATORY,
  UPDATE_AMC_AUTHORISED_SIGNATORY_SUCCESS,
  UPDATE_AMC_AUTHORISED_SIGNATORY,
  GET_AMCBYID_SUCCESS,
  GET_AMCBYID,
  GET_REQUIRED_FOR_ESIGN,
  GET_REQUIRED_FOR_ESIGN_SUCCESS,
  SET_REQUIRED_FOR_ESIGN_SUCCESS,
  SET_REQUIRED_FOR_ESIGN,
} from '../types/onBoarding';
import { RMListFilter } from './mdms';

export const getRMsList =
  (params?: { isActive: boolean }) =>
  async (dispatch: any): Promise<RMType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/rm-list`,
        method: 'GET',
        types: [GET_RM_LIST_SUCCESS, GET_RM_LIST],
        params,
      },
    });
  };

export const getDistributorsList =
  (params: { type: string }) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors`,
        method: 'GET',
        types: [GET_DISTRIBUTORS_LIST_SUCCESS, GET_DISTRIBUTORS_LIST],
        params,
      },
    });
  };

export const editRM =
  (Id: number, body: addRmDetails) =>
  async (dispatch: any): Promise<RMType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-rm/${Id}`,
        method: 'POST',
        types: [EDIT_RM_SUCCESS, EDIT_RM],
        body,
      },
    });
  };

export const addRM =
  (body: addRmDetails) =>
  async (dispatch: any): Promise<RMType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-rm`,
        method: 'POST',
        types: [ADD_RMS_LIST_SUCCESS, ADD_RMS_LIST],
        body,
      },
    });
  };

export const addDistributor =
  (body: AddDistributorRequestBody) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-distributor`,
        method: 'POST',
        types: [ADD_DISTRIBUTOR_SUCCESS, ADD_DISTRIBUTOR],
        body,
      },
    });
  };

export const editDistributor =
  (Id: number, body: AddDistributorRequestBody | any) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-distributor/${Id}`,
        method: 'POST',
        types: [EDIT_DISTRIBUTOR_SUCCESS, EDIT_DISTRIBUTOR],
        body,
      },
    });
  };

export const getAMCAuthorisedSignatoryList =
  (params?: { isActive: boolean }) =>
  async (dispatch: any): Promise<AMCAuthorisedSignatoryType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcauthorisedsignatory-list`,
        method: 'GET',
        types: [GET_AMC_AUTHORISED_SIGNATORY_LIST_SUCCESS, GET_AMC_AUTHORISED_SIGNATORY_LIST],
        params,
      },
    });
  };

export const addAMCAuthorisedSignatory =
  (body: AddAmcAuthorisedSignatoryType) =>
  async (dispatch: any): Promise<AMCAuthorisedSignatoryType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-amcauthorisedsignatory`,
        method: 'POST',
        types: [ADD_AMC_AUTHORISED_SIGNATORY_SUCCESS, ADD_AMC_AUTHORISED_SIGNATORY],
        body,
      },
    });
  };

export const UpdateAMCAuthorisedSignatory =
  (Id: number, body: AddAmcAuthorisedSignatoryType) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-amcauthorisedsignatory/${Id}`,
        method: 'POST',
        types: [UPDATE_AMC_AUTHORISED_SIGNATORY_SUCCESS, UPDATE_AMC_AUTHORISED_SIGNATORY],
        body,
      },
    });
  };

export const getAMCAuthorisedSignatoryById =
  (id: number) =>
  async (dispatch: any): Promise<AMCAuthorisedSignatoryType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcauthorisedsignatory/${id}`,
        method: 'GET',
        types: [GET_AMCBYID_SUCCESS, GET_AMCBYID],
      },
    });
  };

export const getTrustessList =
  (params?: { isActive: boolean }) =>
  async (dispatch: any): Promise<Trustee[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/trustee-list`,
        method: 'GET',
        types: [GET_TRUSTEE_LIST_SUCCESS, GET_TRUSTEE_LIST],
        params,
      },
    });
  };

export const addTrustee =
  (body: AddTrusteeRequestBody) =>
  async (dispatch: any): Promise<Trustee[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-trustee`,
        method: 'POST',
        types: [ADD_TRUSTEE_SUCCESS, ADD_TRUSTEE],
        body,
      },
    });
  };

export const UpdateTrustee =
  (Id: number, body: AddTrusteeRequestBody) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-trustee/${Id}`,
        method: 'POST',
        types: [UPDATE_TRUSTEE_SUCCESS, UPDATE_TRUSTEE],
        body,
      },
    });
  };

export const getFundManagersList =
  (params?: { isActive: boolean }) =>
  async (dispatch: any): Promise<FundManager[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/fundmanager-list`,
        method: 'GET',
        types: [GET_FUND_MANAGERS_LIST_SUCCESS, GET_FUND_MANAGERS_LIST],
        params,
      },
    });
  };

export const getAIFApproverList =
  () =>
  async (dispatch: any): Promise<FundManager[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcapprover-list`,
        method: 'GET',
        types: [GET_AIF_APPROVER_LIST_SUCCESS, GET_AIF_APPROVER_LIST],
      },
    });
  };

export const addFundManager =
  (body: AddFundManagerRequestBody) =>
  async (dispatch: any): Promise<FundManager[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-fundmanager`,
        method: 'POST',
        types: [ADD_FUND_MANAGER_SUCCESS, ADD_FUND_MANAGER],
        body,
      },
    });
  };

export const UpdateFundManager =
  (Id: number, body: AddFundManagerRequestBody) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-fundmanager/${Id}`,
        method: 'POST',
        types: [UPDATE_FUND_MANAGER_SUCCESS, UPDATE_FUND_MANAGER],
        body,
      },
    });
  };

export const addAifApprover =
  (body: AddAifApproverRequestBody) =>
  async (dispatch: any): Promise<FundManager[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-amcapprover`,
        method: 'POST',
        types: [ADD_FUND_MANAGER_SUCCESS, ADD_FUND_MANAGER],
        body,
      },
    });
  };

export const UpdateAifApprover =
  (Id: number, body: AddAifApproverRequestBody) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-amcapprover/${Id}`,
        method: 'POST',
        types: [EDIT_DISTRIBUTOR_SUCCESS, EDIT_DISTRIBUTOR],
        body,
      },
    });
  };

export const getAuthorisedSignatoryList =
  (params?: { distributorId: number }) =>
  async (dispatch: any): Promise<AuthorisedSignatories[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/authorisedsignatory-list`,
        method: 'GET',
        types: [GET_AUTHORISEDSIGNATORY_LIST_SUCCESS, GET_AUTHORISEDSIGNATORY_LIST],
        params,
      },
    });
  };

export const addAuthorisedSignatory =
  (body: AddAuthorisedSignatoryRequestBody) =>
  async (dispatch: any): Promise<AuthorisedSignatories[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-authorisedsignatory`,
        method: 'POST',
        types: [ADD_AUTHORISEDSIGNATORY_SUCCESS, ADD_AUTHORISEDSIGNATORY],
        body,
      },
    });
  };

export const updateAuthorisedSignatory =
  (authorisedSignatoryId: number, body: AddAuthorisedSignatoryRequestBody) =>
  async (dispatch: any): Promise<AuthorisedSignatories[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-authorisedsignatory/${authorisedSignatoryId}`,
        method: 'POST',
        types: [UPDATE_AUTHORISEDSIGNATORY_SUCCESS, UPDATE_AUTHORISEDSIGNATORY],
        body,
      },
    });
  };

export const getPoaApproverList =
  (params?: { distributorId: number }) =>
  async (dispatch: any): Promise<poaApprover[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/poaapprover-list`,
        method: 'GET',
        types: [GET_POAAPPROVER_LIST_SUCCESS, GET_POAAPPROVER_LIST],
        params,
      },
    });
  };

export const addDistributorRM =
  (body: any) =>
  async (dispatch: any): Promise<DistributorListType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-subdistributor`,
        method: 'POST',
        types: [ADD_DISTRIBUTOR_RM_SUCCESS, ADD_DISTRIBUTOR_RM],
        body,
      },
    });
  };

export const updateDistributorRM =
  (Id: number, body: any) =>
  async (dispatch: any): Promise<SubDistributorListType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-subdistributor/${Id}`,
        method: 'POST',
        types: [UPDATE_DISTRIBUTOR_RM_SUCCESS, UPDATE_POAAPPROVER],
        body,
      },
    });
  };

export const addPOAAppprover =
  (body: AddPoaApproverRequestBody) =>
  async (dispatch: any): Promise<poaApprover[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-poaapprover`,
        method: 'POST',
        types: [ADD_POAAPPROVER_SUCCESS, ADD_POAAPPROVER],
        body,
      },
    });
  };

export const updatePOAApprover =
  (POAapproverId: number, body: AddPoaApproverRequestBody) =>
  async (dispatch: any): Promise<poaApprover[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-poaapprover/${POAapproverId}`,
        method: 'POST',
        types: [UPDATE_POAAPPROVER_SUCCESS, UPDATE_POAAPPROVER],
        body,
      },
    });
  };

export const getDistributorById =
  (DistributorId: string | number) =>
  async (dispatch: any): Promise<poaApprover[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors/${DistributorId}`,
        method: 'GET',
        types: [GET_SUBDISTRIBUTOR_SUCCESS, GET_SUBDISTRIBUTOR],
      },
    });
  };

export const getNationalityList =
  () =>
  async (dispatch: any): Promise<nationaliyType> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/default-countries.json',
        method: 'GET',
        types: [GET_Countries_SUCCESS, GET_Countries],
      },
    });
  };

export type AifApproverAccess = {
  amcApproverAccess: boolean;
};
export const getAmcApproverAccess =
  () =>
  async (dispatch: any): Promise<AifApproverAccess> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcApproverAccess`,
        method: 'GET',
        types: [GET_AIFAPPROVERACCESS_SUCCESS, GET_AIFAPPROVERACCESS],
      },
    });
  };

export const getRMById =
  (rmId: number) =>
  async (dispatch: any): Promise<RMType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/rms/${rmId}`,
        method: 'GET',
        types: [GET_RMBYID_SUCCESS, GET_RMBYID],
      },
    });
  };

export const getDistributorRMbyId =
  (Id: number) =>
  async (dispatch: any): Promise<SubDistributorListType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/subdistributors/${Id}`,
        method: 'GET',
        types: [GET_DISTRIBUTORRMBYID_SUCCESS, GET_DISTRIBUTORRMBYID],
      },
    });
  };

export const getAuthorisedSignatoryById =
  (id: number) =>
  async (dispatch: any): Promise<AuthorisedSignatories> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/authorisedsignatories/${id}`,
        method: 'GET',
        types: [GET_AUTHORISEDSIGNATORYBYID_SUCCESS, GET_AUTHORISEDSIGNATORYBYID],
      },
    });
  };
export const getPoaApproverById =
  (Id: number) =>
  async (dispatch: any): Promise<poaApprover> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/poaapprovers/${Id}`,
        method: 'GET',
        types: [GET_POAAPPROVERBYID_SUCCESS, GET_POAAPPROVERBYID],
      },
    });
  };

export const getTrustesById =
  (id: number) =>
  async (dispatch: any): Promise<Trustee> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/trustees/${id}`,
        method: 'GET',
        types: [GET_TRUSTEEBYID_SUCCESS, GET_TRUSTEEBYID],
      },
    });
  };
export const getFundManagersById =
  (id: number) =>
  async (dispatch: any): Promise<FundManager> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/fundmanagers/${id}`,
        method: 'GET',
        types: [GET_FUND_MANAGERSBYID_SUCCESS, GET_FUND_MANAGERSBYID],
      },
    });
  };

export const getAIFApproverById =
  (Id: number) =>
  async (dispatch: any): Promise<FundManager> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcapprovers/${Id}`,
        method: 'GET',
        types: [GET_AIF_APPROVERBYID_SUCCESS, GET_AIF_APPROVERBYID],
      },
    });
  };

export const getDistributorAndSRMByRmid =
  (rmId: number) =>
  async (dispatch: any): Promise<RmWithDistributors> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors-of-rm-by-id/${rmId}`,
        method: 'GET',
        types: [GET_DISTRIBUTORSOFRM_BY_RMID_SUCCESS, GET_DISTRIBUTORSOFRM_BY_RMID],
      },
    });
  };

export type trusteeAccess = {
  trusteeAccess: boolean;
};
export const gettrusteeAccess =
  () =>
  async (dispatch: any): Promise<trusteeAccess> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/trusteeAccess`,
        method: 'GET',
        types: [GET_TRUSTEEACCESS_SUCCESS, GET_TRUSTEEACCESS],
      },
    });
  };

export type getRequiredForEsignType = { requiredAmcAuthorisedSignatoriesForEsign: number };

export const getRequiredForEsign =
  () =>
  async (dispatch: any): Promise<getRequiredForEsignType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/get-required-amcauthorisedsignatories-foresign`,
        method: 'GET',
        types: [GET_REQUIRED_FOR_ESIGN_SUCCESS, GET_REQUIRED_FOR_ESIGN],
      },
    });
  };

export const setRequiredForEsign =
  (body: getRequiredForEsignType) =>
  async (dispatch: any): Promise<getRequiredForEsignType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/set-required-amcauthorisedsignatories-foresign`,
        method: 'POST',
        types: [SET_REQUIRED_FOR_ESIGN_SUCCESS, SET_REQUIRED_FOR_ESIGN],
        body,
      },
    });
  };

//IM-->Investment Manager or fundManager
export type IMAccess = {
  fundManagerAccess: boolean;
};
export const getIMAccess =
  () =>
  async (dispatch: any): Promise<IMAccess> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/fundManagerAccess`,
        method: 'GET',
        types: [GET_IMACCESS_SUCCESS, GET_IMACCESS],
      },
    });
  };

export type AmcAdminAccess = {
  amcAdminAccess: boolean;
};
export const getAmcAdminAccess =
  () =>
  async (dispatch: any): Promise<AmcAdminAccess> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcAdminAccess`,
        method: 'GET',
        types: [GET_AMCADMINACCESS_SUCCESS, GET_AMCADMINACCESS],
      },
    });
  };

export const getAmcAdminList =
  () =>
  async (dispatch: any): Promise<AmcAdmin[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcadmin-list`,
        method: 'GET',
        types: [GET_AMCADMIN_LIST_SUCCESS, GET_AMCADMIN_LIST],
      },
    });
  };

export const getAMCAdminById =
  (Id: number) =>
  async (dispatch: any): Promise<AmcAdmin> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/amcadmins/${Id}`,
        method: 'GET',
        types: [GET_AMCADMINBYID_SUCCESS, GET_AMCADMINBYID],
      },
    });
  };

export const addAmcAdmin =
  (body: AddAmcAdminRequestBody) =>
  async (dispatch: any): Promise<AmcAdmin[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/add-amcadmin`,
        method: 'POST',
        types: [ADD_AMCADMIN_SUCCESS, ADD_AMCADMIN],
        body,
      },
    });
  };

export const updateAmcAdmin =
  (amcAdminId: number, body: AddAmcAdminRequestBody) =>
  async (dispatch: any): Promise<AmcAdmin[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/update-amcadmin/${amcAdminId}`,
        method: 'POST',
        types: [UPDATE_AMCADMIN_SUCCESS, UPDATE_AMCADMIN],
        body,
      },
    });
  };
