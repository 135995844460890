import { Box, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Item } from '.';
import { RootStateType } from '../../redux-store/reducers';
import { Applicant } from '../../redux-store/types/api-types';
import { PHOTOCAPTURE_STATUS, USER_ROLES } from '../../utils/constant';
import { checkEditiable } from '../../utils/utilityFunctions';
import { Gridstyles, ItemDetails } from '../Investments/investor-application';
import { SubHeading } from '../investors/components';

export function DisplayImgFields({
  data,
  displayScore,
}: {
  data: Partial<Applicant>;
  displayScore: string;
}): JSX.Element {
  return (
    <Gridstyles>
      {data?.photocaptureFile?.viewUrl && (
        <CardMedia
          component="img"
          src={data?.photocaptureFile?.viewUrl}
          alt="investor image"
          width="150"
          height="150"
          sx={{
            display: 'flex',
            // justifyContent: 'space-between',
            // alignItems: 'center',
            width: 'unset',
            pb: 3,
            // ml: { xs: 11, sm: 3 },
            // mt: 8,
          }}
        />
      )}
      <Grid
        item
        container
        pt={1}
        pb={5}
        rowSpacing={2}
        columnSpacing={13}
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start">
        <Grid item xs={12} sm={6}>
          <Item>Name</Item>
          <ItemDetails> {data.name || '-'}</ItemDetails>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Item>Status</Item>
          <ItemDetails
            className={PHOTOCAPTURE_STATUS[data.photocaptureStatus || '']}
            sx={{
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: 15,
              color: '#B78813',
              '&.Pending': {
                color: 'rgba(51, 127, 201, 0.7)',
              },
              '&.Completed': {
                fontWeight: 600,
                color: 'rgba(35, 133, 63, 0.7)',
              },
            }}>
            {' '}
            {data.photocaptureStatus ? PHOTOCAPTURE_STATUS[data.photocaptureStatus] : '-'}
          </ItemDetails>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Item>Location</Item>
          <ItemDetails> {data.geolocation || '-'}</ItemDetails>
        </Grid>

        {!displayScore && (
          <>
            <Grid item xs={12} sm={6}>
              <Item>Real Score</Item>
              <ItemDetails>
                {' '}
                {data.realScore ? Number(Number(data.realScore) * 100) + '%' : '-'}
              </ItemDetails>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Item>Spoof Score</Item>
              <ItemDetails>
                {' '}
                {data.spoofScore ? Number(Number(data.spoofScore) * 100) + '%' : '-'}
              </ItemDetails>
            </Grid>
            {data.photocaptureError && (
              <Grid item xs={12} sm={6}>
                <Item>Photo Capture Error</Item>
                <ItemDetails> {data.photocaptureError || '-'}</ItemDetails>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Gridstyles>
  );
}

export default function DisplayImg({
  applicants = [],
  editable,
}: {
  applicants: Partial<Applicant>[];
  editable: boolean;
}): JSX.Element {
  const history = useHistory();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mb={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Investor(s) Photograph
              </Typography>
            </Grid>
            {applicants?.map((applicant, index: number) => {
              const title = [
                'First Applicant Photo Capture Details',
                'Second Applicant Photo Capture Details',
                'Third Applicant Photo Capture Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <Box sx={{ width: '100%' }}>
                    <SubHeading
                      sx={{
                        color: 'common.black',
                        letterSpacing: 0.5 + 'px',
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                        {title}
                        {role === USER_ROLES.INVESTOR &&
                          editable &&
                          Number(referenceDetail.applicant_type) === index + 1 && (
                            <CardMedia
                              component="img"
                              src="/images/edit-icon-outlined.svg"
                              alt="Edit Icon"
                              // sizes="small"
                              sx={{ width: '30px', ml: 0, cursor: 'pointer', pointerEvents: 'all' }}
                              onClick={() =>
                                history.push(`location-capture`, { applicantId: applicant.id })
                              }
                            />
                          )}
                      </Box>
                    </SubHeading>
                    <DisplayImgFields data={applicant} displayScore={referenceDetail.referenceId} />
                  </Box>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
