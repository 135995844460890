import { Typography, Grid, Hidden, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import RelationshipManagers from './relationShipManagers';
import { UserManagementCommonLayout } from '../commonComponents';
import Distributors from './Distributors';
import ManagementInputs from './ManagementInputs';
import InviteRmDetails from './inviteRm';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddDistributor from './addDistributors';
import Trustees from './Trustees';
import AddTrustee from './addTrustee';
import FundManagers from './FundManagers';
import AddFundManager from './addFundManagers';
import aifApprover from './aifApprover';
import AddAIFApprover from './addAifApprover';
import ListCompanyDistributors from './companyDistributors';
import { Location } from 'history';
import {
  DistributorListType,
  DistributorNewList,
  SubDistributorListType,
} from '../../redux-store/types/api-types';
import MappedSrmsForRm from './srmsInRm';
import MappedPlansForIMAndTrustee from './plansForTrusteeAndIM';
import RMDetails from './viewRM';
import UserManagementDistributorDetails from './viewDistributor';
import TrusteeDetails from './viewTrustee';
import IMDetails from './viewIm';
import AIFApproverDetails from './viewAIFApprover';
import amcAdmin from './amcAdmin';
import AmcAdminDetails from './viewAmcAdmin';
import AddAmcAdmin from './addAmcAdmin';
import AMCAuthorisedSignatory from './amcAuthorisedSignatory';
import AddAMCAuthorisedSignatory from './addAMCAuthorisedSignatory';
import AMCAuthoisedSignatoryDetails from './viewAMCAuthoisedSignatory';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { USER_ROLES } from '../../utils/constant';

export function IdDependencyRoutes({
  idDetails,
  path,
  component,
  replaceUrl,
}: {
  idDetails: any;
  path: string;
  component: React.ComponentType<any>;
  replaceUrl: string;
}): JSX.Element {
  const history = useHistory();
  useEffect(() => {
    if (!idDetails) {
      history.replace(replaceUrl);
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route path={path} component={component} />
      </Switch>
    </Router>
  );
}

function Routes({ historyLocationState }: { historyLocationState: any }): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={path}
          render={() => (
            <Redirect
              to={role === USER_ROLES.RM ? `${path}/distributors` : `${path}/relationship-managers`}
              from={location?.pathname}
            />
          )}
        />
        <Route path={`${path}/relationship-managers`} component={RelationshipManagers} />
        <Route path={`${path}/distributors`} component={Distributors} />
        <Route path={`${path}/trustee`} component={Trustees} />
        <Route path={`${path}/amc-authorised-signatory`} component={AMCAuthorisedSignatory} />
        <Route path={`${path}/invest-managers`} component={FundManagers} />
        <Route path={`${path}/aif-approver`} component={aifApprover} />
        <Route path={`${path}/amc-admin`} component={amcAdmin} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-aifapprover`}
          component={AddAIFApprover}
          replaceUrl={'/user-management'}
        />
        <Route path={`${path}/add-aif-approver`} component={AddAIFApprover} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-trustee`}
          component={AddTrustee}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-amc-authorised-signatory`}
          component={AddAMCAuthorisedSignatory}
          replaceUrl={'/user-management'}
        />
        <Route path={`${path}/add-Invest-manager`} component={AddFundManager} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-Invest-manager`}
          component={AddFundManager}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-distributor`}
          component={AddDistributor}
          replaceUrl={'/user-management'}
        />
        <Route path={`${path}/add-trustee`} component={AddTrustee} />
        <Route
          path={`${path}/add-amc-authorised-signatory`}
          component={AddAMCAuthorisedSignatory}
        />
        {/* <Route path={`${path}/onboarding-rm-details`} component={ManagementInputs} /> */}
        <Route path={`${path}/invite-rm-details`} component={InviteRmDetails} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-rm-details`}
          component={InviteRmDetails}
          replaceUrl={'/user-management'}
        />
        <Route path={`${path}/add-distributor`} component={AddDistributor} />
        <Route path={`${path}/distributor`} component={ListCompanyDistributors} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/srms`}
          component={MappedSrmsForRm}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/trustee-plans`}
          component={MappedPlansForIMAndTrustee}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/IM-plans`}
          component={MappedPlansForIMAndTrustee}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/relationship-manager-details`}
          component={RMDetails}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/distributor-details`}
          component={UserManagementDistributorDetails}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/trustee-details`}
          component={TrusteeDetails}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/amc-authorised-signatory-details`}
          component={AMCAuthoisedSignatoryDetails}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/Invest-manager-details`}
          component={IMDetails}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/aif-approver-details`}
          component={AIFApproverDetails}
          replaceUrl={'/user-management'}
        />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/amc-admin-details`}
          component={AmcAdminDetails}
          replaceUrl={'/user-management'}
        />
        <Route path={`${path}/add-amc-admin`} component={AddAmcAdmin} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-amc-admin`}
          component={AddAmcAdmin}
          replaceUrl={'/user-management'}
        />
      </Switch>
    </Router>
  );
}

function UserManagementLayOutItems({ item, link }: { item: string; link: string }) {
  const history = useHistory();
  const pathLink = history.location.pathname.includes(link);
  return (
    <Grid
      item
      sx={{
        py: 2,
        px: 4,
        borderBottom: pathLink ? '3px solid #57B6BA' : 'none',
      }}>
      <Typography
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          fontWeight: 600,
        }}
        to={{ pathname: `${link}` }}
        component={RouterLink}>
        {item}
      </Typography>
    </Grid>
  );
}

export default function UserManagementLayOut(): JSX.Element {
  const history = useHistory();
  const pathName = history.location.pathname;
  const { path } = useRouteMatch();

  const links = [
    `${path}/relationship-managers`,
    `${path}/distributors`,
    // `${path}/trustee`,
    `${path}/amc-authorised-signatory`,
    // `${path}/invest-managers`,
    `${path}/aif-approver`,
    `${path}/amc-admin`,
  ];
  const items = [
    { title: 'Relationship Managers', routerLink: `relationship-managers` },
    { title: 'Distributors', routerLink: `distributors` },
    // { title: 'Trustees', routerLink: `trustee` },
    { title: 'AMC Authorized Signatory', routerLink: `amc-authorised-signatory` },
    // { title: 'Investment Managers', routerLink: `invest-managers` },
    { title: 'Checker', routerLink: `aif-approver` },
    { title: 'AMC Admin', routerLink: `amc-admin` },
  ];

  const MenulinksSrms = [
    `${path}/srms`,
    `${path}/distributors`,
    // `${path}/trustee`,
    `${path}/amc-authorised-signatory`,
    `${path}/invest-managers`,
    `${path}/aif-approver`,
    `${path}/amc-admin`,
  ];
  const MenuItemsSrms = [
    { title: 'Relationship Managers', routerLink: `srms` },
    { title: 'Distributors', routerLink: `distributors` },
    // { title: 'Trustees', routerLink: `trustee` },
    { title: 'AMC Authorized Signatory', routerLink: `amc-authorised-signatory` },
    { title: 'Investment Managers', routerLink: `invest-managers` },
    { title: 'Checker', routerLink: `aif-approver` },
    { title: 'AMC Admin', routerLink: `amc-admin` },
  ];

  const MenulinksTrusteePlans = [
    `${path}/relationship-managers`,
    `${path}/distributors`,
    `${path}/trustee-plans`,
    `${path}/invest-managers`,
    `${path}/aif-approver`,
    `${path}/amc-admin`,
  ];
  const MenuItemsTrusteePlans = [
    { title: 'Relationship Managers', routerLink: `relationship-managers` },
    { title: 'Distributors', routerLink: `distributors` },
    { title: 'Trustees', routerLink: `trustee-plans` },
    { title: 'Investment Managers', routerLink: `invest-managers` },
    { title: 'Checker', routerLink: `aif-approver` },
    { title: 'AMC Admin', routerLink: `amc-admin` },
  ];

  const MenulinksIMPlans = [
    `${path}/relationship-managers`,
    `${path}/distributors`,
    `${path}/trustee`,
    `${path}/IM-plans`,
    `${path}/aif-approver`,
    `${path}/amc-admin`,
  ];
  const MenuItemsIMPlans = [
    { title: 'Relationship Managers', routerLink: `relationship-managers` },
    { title: 'Distributors', routerLink: `distributors` },
    // { title: 'Trustees', routerLink: `trustee` },
    { title: 'AMC Authorized Signatory', routerLink: `amc-authorised-signatory` },
    { title: 'Investment Managers', routerLink: `IM-plans` },
    { title: 'Checker', routerLink: `aif-approver` },
    { title: 'AMC Admin', routerLink: `amc-admin` },
  ];

  const Menulinks = links.includes(pathName)
    ? links
    : MenulinksSrms.includes(pathName)
    ? MenulinksSrms
    : MenulinksTrusteePlans.includes(pathName)
    ? MenulinksTrusteePlans
    : MenulinksIMPlans.includes(pathName)
    ? MenulinksIMPlans
    : [];
  const MenuItems = links.includes(pathName)
    ? items
    : MenulinksSrms.includes(pathName)
    ? MenuItemsSrms
    : MenulinksTrusteePlans.includes(pathName)
    ? MenuItemsTrusteePlans
    : MenulinksIMPlans.includes(pathName)
    ? MenuItemsIMPlans
    : [];

  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <UserManagementCommonLayout>
      <>
        {Menulinks.includes(pathName) && (
          <>
            <Hidden only="xs">
              {role === USER_ROLES.AMC_ADMIN && (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  flexWrap="nowrap"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'white',
                    marginTop: 3,
                    borderRadius: '5px',
                    mb: 0.5,
                  }}>
                  {MenuItems.map((itemList, index) => {
                    return (
                      <React.Fragment key={index}>
                        <UserManagementLayOutItems
                          item={itemList.title}
                          link={itemList.routerLink}
                        />
                      </React.Fragment>
                    );
                  })}
                </Grid>
              )}
            </Hidden>
            <Hidden smUp={true}>
              <Box
                sx={{
                  bgcolor: 'white',
                  pb: 4,
                  borderRadius: '5px',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 1,
                    mb: 2,
                    py: 2,
                    pl: { xs: 3, sm: 0 },
                    color: 'primary.main',
                    bgcolor: 'rgba(238, 244, 251, 0.8)',
                  }}>
                  <PersonOutlineOutlinedIcon fontSize="medium" />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      pl: 1,
                    }}>
                    User Management
                  </Typography>
                </Box>
                {role === USER_ROLES.AMC_ADMIN && (
                  <Box sx={{ px: 4, pt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel shrink id="user-items"></InputLabel>
                      <Select
                        labelId="user-items"
                        value={MenuItems[Menulinks.indexOf(pathName)].routerLink}
                        onChange={(e) => {
                          history.push(`${e.target.value}`);
                        }}
                        sx={{
                          color: 'primary.main',
                          fontWeight: 500,
                        }}
                        IconComponent={(props) => (
                          <ArrowDropDownIcon
                            {...props}
                            sx={{
                              paddingRight: '13px',
                              color: '#00D9DA !important',
                              cursor: 'pointer',
                            }}
                            fontSize="large"
                          />
                        )}>
                        {MenuItems.map((itemlist, ind) => (
                          <MenuItem
                            sx={{ borderBottom: '1px solid #ccc' }}
                            value={itemlist.routerLink}
                            key={ind}>
                            {itemlist.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </Hidden>
          </>
        )}
        <Routes historyLocationState={history.location.state} />
      </>
    </UserManagementCommonLayout>
  );
}
