import { AnyAction } from 'redux';
import { monthWiseInvest } from '../../components/dashboard/commitmentAndContribution';
import { monthwiseTotalInvestmentAmountAndApplicantsType } from '../types/api-types';
import { GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS } from '../types/application';
import { LOGOUT_SUCCESS } from '../types/auth';

function investmentAmountAndApplicantsReducer(
  state = monthWiseInvest,
  action: AnyAction
): Partial<monthwiseTotalInvestmentAmountAndApplicantsType> {
  switch (action.type) {
    case GET_MONTHWISE_TOTALINVESTMENTAMOUNT_APPLICANTS_SUCCESS:
      return {
        monthWiseInvest: monthWiseInvest,
        ...action.body,
      };
    case LOGOUT_SUCCESS: {
      return monthWiseInvest;
    }
    default:
      return state;
  }
}

export default investmentAmountAndApplicantsReducer;
