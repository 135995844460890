import { string } from 'yargs';

export const GET_STRATEGIES_SUCCESS = 'GET_STRATEGIES_SUCCESS';
export const GET_STRATEGIES = 'GET_STRATEGIES';

export type GetStrategiesResponseBody = {
  createdAt: string;
  updatedAt: string;
  id?: string | null;
  strategyName: string;
  strategyType?: string;
  fee: number;
  strategyFees?: string | null;
  performanceFee: number;
  investmentAmount: number;
  isActive: boolean;
  benchmark: string;
  exitFee: number;
  maxFee: number;
  minFee: number;
  maxPerformanceFee: number;
  minPerformanceFee: number;
  maxExitFee: number;
  minExitFee: number;
  managementFeeNature: string;
  performanceFeeNature: string;
};

export const GET_STARATEGY = 'GET_STARATEGY';
export const GET_STARATEGY_SUCCESS = 'GET_STARATEGY_SUCCESS';

export const GET_STARATEGY_DETAILS = 'GET_STARATEGY_DETAILS';
export const GET_STARATEGY_DETAILS_SUCCESS = 'GET_STARATEGY_DETAILS_SUCCESS';

export const ADD_STARATEGY = 'ADD_STARATEGY';
export const ADD_STARATEGY_SUCCESS = 'ADD_STARATEGY_SUCCESS';

export const UPDATE_STARATEGY = 'UPDATE_STARATEGY';
export const UPDATE_STARATEGY_SUCCESS = 'UPDATE_STARATEGY_SUCCESS';

export const GET_FEE_SLAB = 'GET_FEE_SLAB';
export const GET_FEE_SLAB_SUCCESS = 'GET_FEE_SLAB_SUCCESS';

export const GET_FEE_SLAB_DETAILS = 'GET_FEE_SLAB_DETAILS';
export const GET_FEE_SLAB_DETAILS_SUCCESS = 'GET_FEE_SLAB_DETAILS_SUCCESS';

export const ADD_FEE_SLAB = 'ADD_FEE_SLAB';
export const ADD_FEE_SLAB_SUCCESS = 'ADD_FEE_SLAB_SUCCESS';

export const UPDATE_FEE_SLAB = 'UPDATE_FEE_SLAB';
export const UPDATE_FEE_SLAB_SUCCESS = 'UPDATE_FEE_SLAB_SUCCESS';
