import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { addPlan, fundAccess, getPlanDetail, updatePlan } from '../../redux-store/actions/funds';
import {
  accessType,
  addFundPlanType,
  addPlanType,
  ClassPlanProps,
} from '../../redux-store/types/funds';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { FundManager, Trustee } from '../../redux-store/types/api-types';
import { addPlanValidation } from '../../utils/schema';
import { allowOnlyNumbers, currencyConversion } from '../../utils/utilityFunctions';

const initialValues: addPlanType = {
  // id: '',
  schemeId: 0,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: '',
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  faceValue: 0,
  fundAddress1: '',
  fundManagerId: null,
  trusteeId: null,
  fundAddress2: '',
  fundAddress3: '',
  inActive: true,
  isActive: true,
  custodian: '',
  setupFee: 0,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  managementFee: 0,
  tcLink: '',
};

export default function AddPlan({
  location,
}: {
  location: Location<{ fundId: string; PlanId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fundManager, setFundManager] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [planInitial, setPlanInitial] = useState(initialValues);
  const [access, setAccess] = useState(false);
  const { fundId, PlanId } = location.state || {};
  const [trustee, setTrustee] = useState<any[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(getTrustessList())) as unknown as Trustee[];
        const fundManagerList = (await dispatch(getFundManagersList())) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList);
        setTrustee(trusteeList);
        if (PlanId) {
          const GetFundDetail = (await dispatch(
            getPlanDetail(PlanId)
          )) as unknown as ClassPlanProps;
          const {
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            managementFee,
            fundManagerId,
            trusteeId,
            tcLink,
          } = GetFundDetail || {};

          setPlanInitial({
            ...planInitial,
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            managementFee,
            fundManagerId,
            trusteeId,
            tcLink,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: addPlanType) => {
    try {
      const {
        planCode,
        schemeId,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        managementFee,
        fundManagerId,
        trusteeId,
        tcLink,
      } = values;

      if (!PlanId) {
        await dispatch(
          addPlan({
            schemeId: fundId,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: setupFee ? setupFee : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            tcLink,
          })
        );
      } else {
        await dispatch(
          updatePlan(Number(PlanId), {
            schemeId,
            planCode,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            fundManagerId,
            trusteeId,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee: setupFee ? setupFee : 0,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
            managementFee: managementFee ? managementFee : 0,
            tcLink,
          })
        );
      }
      history.push(PlanId ? 'plan-details' : 'view-plans', {
        fundId: fundId,
        PlanId: PlanId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={planInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addPlanValidation}>
        {({ handleSubmit, values, errors }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {PlanId ? 'Edit Plan' : 'Add Plan'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                    }}>
                    {'Plan Details'}
                  </SubHeading>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="planCode"
                      label="Plan Code *"
                      placeholder="Enter Plan Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="planDescription"
                      label="Plan Description *"
                      placeholder="Enter Plan Description"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeNature"
                      label="Scheme Nature *"
                      placeholder="Enter Scheme Nature"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="schemeCategory"
                      label="Scheme Category *"
                      placeholder="Enter Scheme Category"
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="registrationNumber"
                      label="Registration Number *"
                      placeholder="Enter Registration Number"
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="fundManagerId"
                      label="Investment Manager Name *"
                      items={fundManager.map((item) => ({
                        key: item.name,
                        value: item.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="trusteeId"
                      label="Trustee Name *"
                      items={trustee.map((item) => ({
                        key: item.name,
                        value: item.id,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="currency" label="Currency" placeholder="Enter Currency" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      label={'Scheme Start Date'}
                      inputLabelStyles={{
                        transform: 'unset',

                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                      }}
                      placeholder={'Enter Scheme Start Date'}
                      // maxDate={minDateForContributor()}
                      name={`schemeStartDate`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="lockInPeriod"
                      label="Lock In Period(In Year)"
                      placeholder="Enter Lock In Period(In Year)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="lockInExtension"
                      label="Lock In Extension(In Year)"
                      disabled={!access}
                      placeholder="Enter Lock In Extension(In Year)"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="faceValue"
                      label="Face value"
                      placeholder="Enter Face value"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress1"
                      label="Fund Address 1"
                      placeholder="Enter Fund Address 1"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress2"
                      label="Fund Address 2"
                      placeholder="Enter Fund Address 2"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="fundAddress3"
                      label="Fund Address 3"
                      placeholder="Enter Fund Address 3"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="custodian" label="Custodian" placeholder="Enter Custodian" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="setupFee"
                      label="Setup Fee"
                      placeholder="Enter Setup Fee"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minCommitmentAmount"
                      label="Minimum Commitment Amount *"
                      placeholder="Enter Minimum Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.minCommitmentAmount != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.minCommitmentAmount)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="maxCommitmentAmount"
                      label="Maximum Commitment Amount *"
                      placeholder="Enter Maximum Commitment Amount"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                    {values.maxCommitmentAmount != 0 && (
                      <Typography sx={{ fontSize: 13 }}>
                        {currencyConversion(values.maxCommitmentAmount)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="minContributionPercentage"
                      label="Minimum Contribution Percentage"
                      placeholder="Enter Minimum Contribution Percentage"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="managementFee"
                      label="Management Fee"
                      placeholder="Enter Management Fee"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="tcLink" label={`T&C Link`} placeholder={`Enter T&C Link`} />
                  </Grid>
                </Grid>
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
