import {
  Box,
  Button,
  Typography,
  Hidden,
  IconButton,
  Tooltip,
  Stack,
  Switch,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAMCAuthorisedSignatoryList,
  getRequiredForEsign,
  getRequiredForEsignType,
  setRequiredForEsign,
  UpdateAMCAuthorisedSignatory,
} from '../../redux-store/actions/userManagement';
import {
  AddAmcAuthorisedSignatoryType,
  AMCAuthorisedSignatoryType,
} from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { getAmcAuthorisedMaster } from '../../utils/utilityFunctions';

const columns: ColumnType[] = [
  {
    header: 'Name',
    renderCell: (row: AMCAuthorisedSignatoryType) => {
      return (
        <Typography
          variant="h5"
          sx={{
            color: '#61D1D6',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: '14px',
            ':hover': {
              color: 'primary.main',
            },
          }}
          component={RouterLink}
          to={{
            pathname: 'amc-authorised-signatory-details',
            state: { amcAuthorisedSignatoryId: row.id },
          }}>
          {row.user.firstName || 'N/A'}
        </Typography>
      );
    },
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: AMCAuthorisedSignatoryType) => row.user.phone || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: AMCAuthorisedSignatoryType) => row.user.email || 'N/A',
  },
];

function AMCAuthorisedSignatory(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [amcAuthorisedSignatoryList, setAmcAuthorisedSignatoryList] = useState<
    AMCAuthorisedSignatoryType[]
  >([]);
  const [requiredEsignVal, setRequiredEsignVal] = useState<number[]>([]);
  const [RequireEsignValueRes, SetRequireEsignValueRes] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [openDialog, setDialog] = useState<{
    amcAuthorisedSignatoryRowData: AddAmcAuthorisedSignatoryType;
  } | null>(null);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const RequiredForEsign = (await dispatch(
          getRequiredForEsign()
        )) as unknown as getRequiredForEsignType;
        SetRequireEsignValueRes(RequiredForEsign.requiredAmcAuthorisedSignatoriesForEsign);
        const res = (await dispatch(
          getAMCAuthorisedSignatoryList()
        )) as unknown as AMCAuthorisedSignatoryType[];
        setRequiredEsignVal(
          getAmcAuthorisedMaster(res.filter((isActiveData) => isActiveData.isActive).length)
        );
        if (!isComponentAlive) return;
        setAmcAuthorisedSignatoryList(res);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const AccessCell = [
    {
      header: AccessHeader,
      renderCell: (row: AddAmcAuthorisedSignatoryType): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ amcAuthorisedSignatoryRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>
            List of AMC Authorized Signatory
          </Typography>
          <Grid
            sx={{
              display: 'flex',
            }}
            alignItems="center">
            <Typography sx={{ color: 'primary.main', fontSize: '16px', ml: 3 }}>
              No. of Authorized signatories to esign:
            </Typography>
            <Box
              sx={{
                width: '100px',
                ml: { md: 1 },
                '.MuiOutlinedInput-root': {
                  marginTop: '0',
                  backgroundColor: 'rgba(97, 209, 214, 0.07)',
                  '&.MuiInputBase-root': {
                    '& .MuiSelect-select': {
                      padding: '7px 38px',
                      border: 'none',
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },
                '&.Mui-focused,&:hover': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 'none',
                  },
                },
                svg: {
                  color: '#337FC9',
                },
              }}>
              <Select
                labelId="user-items"
                onChange={async (e) => {
                  try {
                    const resSetForEsign = await dispatch(
                      setRequiredForEsign({
                        requiredAmcAuthorisedSignatoriesForEsign: Number(e.target.value),
                      }) as unknown as getRequiredForEsignType
                    );
                    SetRequireEsignValueRes(
                      resSetForEsign.requiredAmcAuthorisedSignatoriesForEsign
                    );
                  } catch (e) {
                    console.error((e as Error).message);
                  }
                }}
                value={RequireEsignValueRes}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                }}>
                {requiredEsignVal.map((thresholdValue, ind) => (
                  <MenuItem
                    sx={{
                      borderBottom: '1px solid #ccc',
                      textAlign: 'center',
                      '&:last-child': { border: 'none' },
                      justifyContent: 'center',
                    }}
                    value={thresholdValue}
                    key={ind}>
                    {thresholdValue}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Hidden only="xs">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                color: 'white',
                fontWeight: 500,
                fontSize: 14,
                mr: 2.5,
                py: 0.5,
                px: 3,
              }}
              onClick={() => history.push('add-amc-authorised-signatory')}>
              Add AMC Authorized Signatory
            </Button>
          </Hidden>
          <Hidden smUp={true}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 13,
                // mr: 2.5,
                py: 0.5,
                // px: 3,
                // whiteSpace: 'nowrap',
              }}
              onClick={() => history.push('add-amc-authorised-signatory')}>
              Add AMC Authorise Signatory
            </Button>
          </Hidden>
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={amcAuthorisedSignatoryList}
              tableHeader={[...columns, ...AccessCell]}
              currentPage={currentPage}
              isLoading={isLoading}
              noOfPages={1}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [amcAuthorisedSignatoryList, currentPage, isLoading]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.amcAuthorisedSignatoryRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const { user, ...rest } = openDialog?.amcAuthorisedSignatoryRowData;
              const updatedResponse = (await dispatch(
                UpdateAMCAuthorisedSignatory(
                  openDialog.amcAuthorisedSignatoryRowData.id as number,
                  {
                    ...rest,
                    name: openDialog.amcAuthorisedSignatoryRowData.name,
                    email: user.email,
                    isActive: !rest.isActive,
                  }
                )
              )) as unknown as AMCAuthorisedSignatoryType;
              const res = (await dispatch(
                getAMCAuthorisedSignatoryList()
              )) as unknown as AMCAuthorisedSignatoryType[];
              setRequiredEsignVal(
                getAmcAuthorisedMaster(res.filter((isActiveData) => isActiveData.isActive).length)
              );
              setAmcAuthorisedSignatoryList(
                amcAuthorisedSignatoryList?.map((amcAuthorisedSignatory) => {
                  if (Number(amcAuthorisedSignatory.id) === Number(updatedResponse.id)) {
                    return {
                      ...amcAuthorisedSignatory,
                      ...updatedResponse,
                    };
                  }
                  return amcAuthorisedSignatory;
                })
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}

export default AMCAuthorisedSignatory;
