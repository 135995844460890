import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { DistributorListType, StrategyType } from '../../../redux-store/types/api-types';
import { Location } from 'history';
import { getDistributorsListForFilters } from '../../../redux-store/actions';
import { MultipleSelect } from '../../../lib/formik/MultipleSelectField';
import MFCheckbox from '../../../lib/formik/Checkbox';
import {
  addStarategy,
  getStarategyDetailById,
  updateStarategyById,
} from '../../../redux-store/actions/strategies';
import { addStrategyValidation } from '../../../utils/schema';
import { showError } from '../../../redux-store/actions/auth';

const initialValues: StrategyType = {
  id: '',
  strategyName: '',
  isApplicableForDirect: null,
  isApplicableForDistributor: null,
  isActive: null,
  distributorDetails: {
    distributorIds: [],
    distributors: [],
  },
  distributorIds: null,
};

export default function AddStrategy({
  location,
}: {
  location: Location<{ strategyId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editField, setEditField] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { strategyId } = location.state || {};
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);
  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(
          getDistributorsListForFilters({ isActive: true })
        )) as unknown as DistributorListType[];
        setDistributorList(res);
        if (strategyId) {
          const GetStrategyDetail = (await dispatch(
            getStarategyDetailById(strategyId.toString())
          )) as unknown as StrategyType;
          setEditField({
            ...editField,
            ...GetStrategyDetail,
            distributorIds: GetStrategyDetail.distributorDetails.distributorIds || [],
            isApplicableForDistributor:
              GetStrategyDetail?.distributorDetails?.distributorIds?.length > 0 ? true : false,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: StrategyType) => {
    try {
      if (!values.isApplicableForDirect && !values.isApplicableForDistributor) {
        throw 'Please select Direct or Distributor';
      }
      if (strategyId) {
        await dispatch(
          updateStarategyById(strategyId, {
            ...values,
            id: strategyId,
            distributorIds: values.isApplicableForDistributor ? values.distributorIds : [],
          })
        );
      } else {
        (await dispatch(
          addStarategy({
            ...values,
            id: null,
            isActive: true,
            distributorIds: values.isApplicableForDistributor ? values.distributorIds : [],
          })
        )) as unknown as StrategyType[];
      }
      history.push(strategyId ? 'strategy-details' : 'strategy-list', { strategyId: strategyId });
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={editField}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addStrategyValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(strategyId ? 'strategy-details' : 'strategy-list', {
                      strategyId: strategyId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Strategy
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(strategyId ? 'strategy-details' : 'strategy-list', {
                      strategyId: strategyId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {strategyId ? 'Edit Strategy' : 'Add Strategy'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="strategyName"
                      label="Strategy Name *"
                      placeholder="Enter Strategy Name"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 6 }}>
                    <Box sx={{ display: 'flex' }}>
                      <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                      <MFCheckbox
                        name={`isApplicableForDistributor`}
                        label="Distributor"
                        onChange={({ target: { checked } }) => {
                          setValues({
                            ...values,
                            isApplicableForDistributor: checked,
                            distributorIds: [],
                          });
                        }}
                        // checked={values.isApplicableForDistributor || false}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    {values.isApplicableForDistributor && (
                      <MultipleSelect
                        name="distributorIds"
                        label="Applicable Distributor(s) *"
                        items={distributorList.map((item) => ({
                          key: item.id,
                          value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                        }))}
                        renderText="Selected Distributor(s)"
                      />
                    )}
                  </Grid>
                </Grid>
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
