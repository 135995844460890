import { AnyAction } from 'redux';
import { getDetailsByRefrenceType } from '../types/api-types';
import { LOGOUT_SUCCESS } from '../types/auth';
import { GET_DETAILS_BY_REFRENCE_SUCCESS, GET_OTP_SUCCESS } from '../types/onBoarding';

const initState: Partial<getDetailsByRefrenceType> = {
  applicationId: '',
  referenceId: '',
  applicant_type: '',
  esignReferenceId: '',
  id: '',
};

function refrenceIdReducer(
  state = initState,
  action: AnyAction
): Partial<getDetailsByRefrenceType> {
  switch (action.type) {
    case GET_DETAILS_BY_REFRENCE_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case LOGOUT_SUCCESS:
    case GET_OTP_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default refrenceIdReducer;
