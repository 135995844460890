import { Typography, Grid, Hidden, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import { UserManagementCommonLayout } from '../commonComponents';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { USER_ROLES } from '../../utils/constant';
import { IdDependencyRoutes } from '../userManagement/router';
import AddStrategy from './Strategy/addStrategy';
import StrategyDetails from './Strategy/StrategyDetail';
import StrategyList from './Strategy/StrategyList';
import AddFeeSlab from './FeeSlabs/addFeeSlab';
import FeeSlabDetails from './FeeSlabs/feeSlabDetail';
import FeeSlabsList from './FeeSlabs/feeSlabList';

function Routes({ historyLocationState }: { historyLocationState: any }): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={path}
          render={() => <Redirect to={`${path}/strategy-list`} from={location?.pathname} />}
        />
        <Route path={`${path}/add-strategy`} component={AddStrategy} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/strategy-details`}
          component={StrategyDetails}
          replaceUrl={'/strategy-fee-slabs'}
        />
        <Route path={`${path}/strategy-list`} component={StrategyList} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-strategy`}
          component={AddStrategy}
          replaceUrl={'/strategy-fee-slabs'}
        />
        <Route path={`${path}/add-fee-slab`} component={AddFeeSlab} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/fee-slab-details`}
          component={FeeSlabDetails}
          replaceUrl={'/strategy-fee-slabs'}
        />
        <Route path={`${path}/fee-slab-list`} component={FeeSlabsList} />
        <IdDependencyRoutes
          idDetails={historyLocationState}
          path={`${path}/edit-fee-slab`}
          component={AddFeeSlab}
          replaceUrl={'/strategy-fee-slabs'}
        />
      </Switch>
    </Router>
  );
}

function StrategyAndFeeSlabManagementLayOutItems({ item, link }: { item: string; link: string }) {
  const history = useHistory();
  const pathLink = history.location.pathname.includes(link);
  return (
    <Grid
      item
      sx={{
        py: 2,
        px: 4,
        borderBottom: pathLink ? '3px solid #57B6BA' : 'none',
      }}>
      <Typography
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          fontWeight: 600,
        }}
        to={{ pathname: `${link}` }}
        component={RouterLink}>
        {item}
      </Typography>
    </Grid>
  );
}

export default function StrategyAndFeeSlabLayOut(): JSX.Element {
  const history = useHistory();
  const pathName = history.location.pathname;
  const { path } = useRouteMatch();

  const links = [`${path}/strategy-list`, `${path}/fee-slab-list`];
  const items = [
    { title: 'Strategies', routerLink: `strategy-list` },
    { title: 'Fee Slabs', routerLink: `fee-slab-list` },
  ];

  const Menulinks = links.includes(pathName) ? links : [];
  const MenuItems = links.includes(pathName) ? items : [];

  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <UserManagementCommonLayout>
      <>
        {Menulinks.includes(pathName) && (
          <>
            <Hidden only="xs">
              {role === USER_ROLES.AMC_ADMIN && (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-around"
                  flexWrap="nowrap"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'white',
                    marginTop: 3,
                    borderRadius: '5px',
                    mb: 0.5,
                  }}>
                  {MenuItems.map((itemList, index) => {
                    return (
                      <React.Fragment key={index}>
                        <StrategyAndFeeSlabManagementLayOutItems
                          item={itemList.title}
                          link={itemList.routerLink}
                        />
                      </React.Fragment>
                    );
                  })}
                </Grid>
              )}
            </Hidden>
            <Hidden smUp={true}>
              <Box
                sx={{
                  bgcolor: 'white',
                  pb: 4,
                  borderRadius: '5px',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 1,
                    mb: 2,
                    py: 2,
                    pl: { xs: 3, sm: 0 },
                    color: 'primary.main',
                    bgcolor: 'rgba(238, 244, 251, 0.8)',
                  }}>
                  <PersonOutlineOutlinedIcon fontSize="medium" />
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 600,
                      pl: 1,
                    }}>
                    Strategy And Fee Slab Management
                  </Typography>
                </Box>
                {role === USER_ROLES.AMC_ADMIN && (
                  <Box sx={{ px: 4, pt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel shrink id="user-items"></InputLabel>
                      <Select
                        labelId="user-items"
                        value={MenuItems[Menulinks.indexOf(pathName)].routerLink}
                        onChange={(e) => {
                          history.push(`${e.target.value}`);
                        }}
                        sx={{
                          color: 'primary.main',
                          fontWeight: 500,
                        }}
                        IconComponent={(props) => (
                          <ArrowDropDownIcon
                            {...props}
                            sx={{
                              paddingRight: '13px',
                              color: '#00D9DA !important',
                              cursor: 'pointer',
                            }}
                            fontSize="large"
                          />
                        )}>
                        {MenuItems.map((itemlist, ind) => (
                          <MenuItem
                            sx={{ borderBottom: '1px solid #ccc' }}
                            value={itemlist.routerLink}
                            key={ind}>
                            {itemlist.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </Hidden>
          </>
        )}
        <Routes historyLocationState={history.location.state} />
      </>
    </UserManagementCommonLayout>
  );
}
