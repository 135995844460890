import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  Radio,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Formik,
  FormikHelpers,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { MFCheckbox } from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';
import MFSelectField from '../../lib/formik/SelectField';
import MFTextField from '../../lib/formik/TextField';
import { updateApplication } from '../../redux-store/actions/application';
import { showError } from '../../redux-store/actions/auth';
import {
  getStarategyListOfDirect,
  getStrategies,
  getFeeSlabListOfDirect,
} from '../../redux-store/actions/strategies';
import { RootStateType } from '../../redux-store/reducers';
import {
  FeeSlabsType,
  FeeType,
  StrategyDetailsType,
  StrategyType,
} from '../../redux-store/types/api-types';
import { GetStrategiesResponseBody } from '../../redux-store/types/strategies';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  FundInvestStockMarket,
  ModeOfTransaction,
  USER_ROLES,
} from '../../utils/constant';
import { StrategyDetailSchema, strategyDeclarationScheme } from '../../utils/schema';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkInitialApplicationStatusIsChecker,
  checkStrategyBoxSelected,
  checkStrategyFeeSlabSelected,
  checkStrategyFeeTypeSelected,
  currencyConversion,
  getDisableStatusForjointHolder,
  getTotalStrategyAmount,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Distributor } from '../../redux-store/actions';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { numberRegex, numberRegexWithDigit } from '../../utils/regex';

export type updatedFeeSlabDetails = FeeSlabsType;
export type updatedStrategyDetails = StrategyDetailsType & {
  maxFee: number | null;
  minFee: number | null;
  performanceMaxFee: number | null;
  performanceMinFee: number | null;
  exitMaxFee: number | null;
  exitMinFee: number | null;
  feeType: FeeType;
};

type Values = {
  applicationstrategydetails: Partial<updatedStrategyDetails>[];
  totalStrategyInvestmentAmount: string | number | null;
  investmentAmount: string | number | null;
  feeSlabdetails: Partial<updatedFeeSlabDetails>[];
  cheque: boolean;
  rtgs: boolean;
  saveType: string;
};

export const newStrategy: Partial<updatedStrategyDetails> = {
  strategyName: '',
  strategyType: '',
  fee: null,
  strategyFees: null,
  performanceFee: null,
  exitFee: null,
  performanceFeeNature: '',
  managementFeeNature: '',
  investmentAmount: null,
  isActive: false,
  maxFee: null,
  minFee: null,
  performanceMaxFee: null,
  performanceMinFee: null,
  exitMaxFee: null,
  exitMinFee: null,
  feeType: '',
};

const initialValues: Values = {
  applicationstrategydetails: [],
  feeSlabdetails: [],
  totalStrategyInvestmentAmount: null,
  investmentAmount: null,
  cheque: false,
  rtgs: false,
  saveType: 'save and proceed',
};

const styles: any = {
  header: {
    my: 0,
    py: 0,
    border: 0,
    minWidth: 90,
  },
  tableCellBorder: { my: 0, border: 0, py: 0 },
  boxTitle: {
    fontSize: { xs: 11, md: 14 },
    fontWeight: 400,
    color: '#2057A6',
    textAlign: 'left',
    px: { xs: 0.5, md: 1.5 },
    py: { xs: 0.5, md: 1 },
    height: 48,
    display: 'flex',
    alignItems: 'center',
    background: '#EBF2FA',
  },
  boxValues: {
    py: { xs: 0.5, md: 1 },
    px: { xs: 0.5, md: 1.5 },
    background: '#fafafb',
  },
  boxValuesText: {
    fontSize: { xs: 14, md: 16 },
    fontWeight: 400,
    color: '#293139',
    mb: 0.5,
  },
};

function FeeSlabBox({
  values,
  setValues,
  feeSlabs,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  feeSlabs: Partial<updatedFeeSlabDetails>[];
}): JSX.Element {
  return (
    <>
      {feeSlabs.map((fee, slabIndex) => (
        <Box
          key={slabIndex}
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            border: '1px solid #CBCBCB',
            borderRadius: '2px',
            mb: 1,
          }}>
          <MFCheckbox
            name={`feeSlabdetails.${slabIndex}.isActive`}
            label={''}
            icon={<RadioButtonUnchecked />}
            checkedIcon={<RadioButtonChecked />}
            size={'small'}
            sx={{ minWidth: 'unset', py: 2, px: { xs: 0, md: 1 }, m: 0 }}
            onChange={({ target: { value } }) => {
              setValues({
                ...values,
                feeSlabdetails: values.feeSlabdetails.map((fee, ind) => {
                  const Checked = ind === slabIndex;
                  return {
                    ...fee,
                    isActive: Checked,
                  };
                }),
              });
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexGrow: 1,
              flexWrap: 'wrap',
              background: '#fafafb',
            }}>
            <Box sx={{ width: { xs: '50%', lg: '25%' } }}>
              <Typography sx={styles.boxTitle}>Management Fees %</Typography>
              <Box sx={styles.boxValues}>
                <Typography sx={styles.boxValuesText}>{fee.mgmtFee}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '50%', lg: '25%' } }}>
              <Typography sx={styles.boxTitle}>Operating Expenses %</Typography>
              <Box sx={styles.boxValues}>
                <Typography sx={styles.boxValuesText}>{fee.optFee}</Typography>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '100%', lg: '50%' }, background: '#EBF2FA' }}>
              <Typography
                sx={{
                  ...styles.boxTitle,
                  height: 24,
                  py: 0.2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}>
                Exit Load %
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                {fee.exitload?.map((exit, i) => (
                  <Box key={i} sx={{ width: '25%' }}>
                    <Typography
                      sx={{
                        ...styles.boxTitle,
                        fontsize: 12,
                        fontWeight: 600,
                        height: 24,
                        py: 0.2,
                      }}>
                      {exit.frequency}
                    </Typography>
                    <Box sx={styles.boxValues}>
                      <Typography sx={styles.boxValuesText}>{exit.exitload}</Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {fee?.hurdlerates && fee?.hurdlerates?.length > 0 && (
              <>
                <Box sx={{ width: '50%' }}>
                  <Typography sx={styles.boxTitle}>Profit sharing % above hurdle rate</Typography>
                  <Box sx={styles.boxValues}>
                    {fee.hurdlerates?.length ? (
                      fee.hurdlerates?.map((rate, rateIndex) => (
                        <Typography key={rateIndex} sx={styles.boxValuesText}>
                          {rate.profit}
                        </Typography>
                      ))
                    ) : (
                      <Typography sx={styles.boxValuesText}>-</Typography>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: '50%', background: '#EBF2FA' }}>
                  <Typography sx={{ ...styles.boxTitle, height: 24, py: 0.2 }}>
                    Profit Hurdle Rate
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ width: '50%' }}>
                      <Typography
                        sx={{
                          ...styles.boxTitle,
                          fontsize: 12,
                          fontWeight: 600,
                          height: 24,
                          py: 0.2,
                        }}>
                        % from
                      </Typography>
                      <Box sx={styles.boxValues}>
                        {fee.hurdlerates?.length ? (
                          fee.hurdlerates?.map((rate, rateIndex) => (
                            <Typography key={rateIndex} sx={styles.boxValuesText}>
                              {rate?.to?.match(numberRegexWithDigit)
                                ? rate.from
                                : `${rate.from} & ${rate.to}`}
                            </Typography>
                          ))
                        ) : (
                          <Typography sx={styles.boxValuesText}>-</Typography>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ width: '50%' }}>
                      <Typography
                        sx={{
                          ...styles.boxTitle,
                          fontsize: 12,
                          fontWeight: 600,
                          height: 24,
                          py: 0.2,
                        }}>
                        % to
                      </Typography>
                      <Box sx={styles.boxValues}>
                        {fee.hurdlerates?.length ? (
                          fee.hurdlerates?.map((rate, rateIndex) => (
                            <Typography
                              key={rateIndex}
                              sx={{
                                ...styles.boxValuesText,
                                opacity: rate?.to?.match(numberRegexWithDigit) ? 1 : 0,
                              }}>
                              {rate?.to?.match(numberRegexWithDigit)
                                ? rate.to
                                : `${rate.from} & ${rate.to}`}
                            </Typography>
                          ))
                        ) : (
                          <Typography sx={styles.boxValuesText}>-</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
}

function FeeSlabTable({
  _index,
  values,
  setValues,
  feeSlabs,
}: {
  _index: number;
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  feeSlabs: Partial<updatedFeeSlabDetails>[];
}): JSX.Element {
  const headers = [
    {
      header: 'Fee Name',
      label: 'feeName',
      renderEditDeleteCell: (
        params: Partial<updatedFeeSlabDetails>,
        index: number
      ): JSX.Element => {
        return (
          <>
            <MFCheckbox
              name={`feeSlabdetails.${index}.isActive`}
              label={params.feeName || ''}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              size={'small'}
              sx={{ minWidth: '200px' }}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  feeSlabdetails: values.feeSlabdetails.map((fee, ind) => {
                    const Checked = ind === index;
                    return {
                      ...fee,
                      isActive: Checked,
                    };
                  }),
                });
              }}
            />
          </>
        );
      },
    },
    {
      header: 'Management Fees',
      label: 'mgmtFee',
      renderEditDeleteCell: (params: updatedFeeSlabDetails) => {
        return <Typography>{params.mgmtFee}</Typography>;
      },
    },
    {
      header: 'Operating Expenses',
      label: 'optFee',
      renderEditDeleteCell: (params: updatedFeeSlabDetails) => {
        return <Typography>{params.optFee}</Typography>;
      },
    },
    {
      header: () => {
        return (
          <>
            <Hidden only="xs">
              <TableHead sx={{ my: 0, py: 0 }}>
                <TableCell
                  align="center"
                  sx={{
                    ...styles.tableCellBorder,
                    minWidth: 200,
                    pl: '16px !important',
                  }}>
                  Profit sharing % above hurdle rate
                </TableCell>
                <TableCell align="center" sx={styles.tableCellBorder}>
                  <TableRow>
                    <TableCell align="center" colSpan={2} sx={styles.tableCellBorder}>
                      <Typography sx={{ textAlign: 'center' }}>Profit Hurdle Rate</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.header}>% from</TableCell>
                    <TableCell sx={{ ...styles.header, pr: 0 }}>% to</TableCell>
                  </TableRow>
                </TableCell>
              </TableHead>
            </Hidden>
            <Hidden smUp={true}>
              <Typography variant="h6">Hurdle Rate</Typography>
            </Hidden>
          </>
        );
      },
      label: 'hurdlerates',
      isDisplay: ['Hybrid', 'Variable', ''].includes(
        values?.applicationstrategydetails[_index]?.feeType || ''
      ),
      isVisibleInMobileView: (params: updatedFeeSlabDetails) => !!params.hurdlerates?.length,
      renderEditDeleteCell: (params: updatedFeeSlabDetails) => {
        return (
          <>
            <Hidden only="xs">
              {!params.hurdlerates?.length ? (
                <TableRow sx={styles.header}>
                  <TableCell align="center" sx={{ ...styles.header, minWidth: 200 }}>
                    -
                  </TableCell>
                  <TableCell sx={styles.header}>
                    <TableRow>
                      <TableCell align="center" sx={{ ...styles.header, pl: 0 }}>
                        -
                      </TableCell>
                      <TableCell align="center" sx={styles.header}>
                        -
                      </TableCell>
                    </TableRow>
                  </TableCell>
                </TableRow>
              ) : (
                params.hurdlerates?.map((rate, i) => {
                  return (
                    <TableRow sx={styles.header} key={i}>
                      <TableCell align="center" sx={{ ...styles.header, minWidth: 200 }}>
                        {rate.profit}
                      </TableCell>
                      {rate.from && rate?.to?.match(numberRegex) ? (
                        <TableCell sx={styles.header}>
                          <TableRow>
                            <TableCell align="center" sx={{ ...styles.header, pl: 0 }}>
                              {rate.from}
                            </TableCell>
                            <TableCell align="center" sx={styles.header}>
                              {rate.to}
                            </TableCell>
                          </TableRow>
                        </TableCell>
                      ) : (
                        <TableCell colSpan={2} sx={styles.header}>
                          {`${rate.from} & ${rate.to}`}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              )}
            </Hidden>
            <Hidden smUp={true}>
              {params.hurdlerates?.map((rate) => {
                return (
                  <>
                    <Grid item>
                      <Typography variant="h6">Profit sharing % above hurdle rate</Typography>
                      <Typography>{rate.profit}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6">Profit Hurdle Rate</Typography>
                      {rate.from && rate?.to?.match(numberRegex) ? (
                        <>
                          <Grid item>
                            <Typography variant="h6">% from</Typography>
                            <Typography>{rate.from}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6">% to</Typography>
                            <Typography>{rate.to}</Typography>
                          </Grid>
                        </>
                      ) : (
                        <Grid item>
                          <Typography>{`${rate.from} & ${rate.to}`}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </>
                );
              })}
            </Hidden>
          </>
        );
      },
    },
    {
      header: () => {
        return (
          <>
            <Hidden only="xs">
              <TableHead sx={{ my: 0, py: 0 }}>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={4}
                    sx={{
                      my: 0,
                      border: 0,
                      py: 0,
                    }}>
                    <Typography sx={{ textAlign: 'center' }}>Exit Load with in days</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={styles.header}>0 to 365</TableCell>
                  <TableCell sx={styles.header}>366 to 730</TableCell>
                  <TableCell sx={styles.header}>731 to 1095</TableCell>
                  <TableCell sx={styles.header}>1095 & more</TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <Hidden smUp={true}>
              <Typography variant="h6">Exit Load with in days</Typography>
            </Hidden>
          </>
        );
      },
      label: 'exitFee',
      renderEditDeleteCell: (params: updatedFeeSlabDetails) => {
        return (
          <>
            <Hidden only="xs">
              <TableCell sx={styles.header}>
                {params.exitload?.map((exit, i) => {
                  return (
                    <TableCell key={i} align="center" sx={styles.header}>
                      {exit.exitload}
                    </TableCell>
                  );
                })}
              </TableCell>
            </Hidden>
            <Hidden smUp={true}>
              <Box minWidth={90}>
                {params.exitload?.map((exit, i) => {
                  return (
                    <>
                      <Typography variant="h6">{`${exit.frequency}`}</Typography>
                      <Typography>{exit.exitload}</Typography>
                    </>
                  );
                })}
              </Box>
            </Hidden>
          </>
        );
      },
    },
    {
      header: '',
      label: 'end',
      renderCell: (params: updatedFeeSlabDetails) => {
        return <>{params.end}</>;
      },
    },
  ];

  return (
    <DataTable
      tableData={feeSlabs || []}
      tableHeader={
        headers
          .filter((header) => header.isDisplay !== false)
          .map((header) => {
            const { isDisplay, ...rest } = header;
            return rest;
          }) || []
      }
      renderAdditionalRow={false}
      tableHeaderCustomStyles={{
        '.MuiTableCell-head': {
          fontFamily: 'Poppins, sans-serif',
          fontSize: 14,
          fontWeight: 500,
          color: 'primary.main',
          textAlign: 'left',
          pl: 2,
          '&:nth-of-type(1)': {
            pl: 6,
          },
          '&:nth-of-type(3)': {
            pr: 0,
          },
          '&:nth-of-type(4)': {
            pl: 0,
          },
          '& .MuiTableRow-root': {
            '.MuiTableCell-head': {
              pl: 0,
              '&:nth-of-type(3)': {
                pr: 2,
              },
            },
          },
        },
      }}
      rowCustomStyles={{
        '.MuiTableCell-root': {
          fontSize: '1rem',
          lineHeight: 1.5,
          py: 0,
          overflowWrap: 'anywhere',
          textAlign: 'left',
          verticalAlign: 'middle',
          '&:nth-of-type(3)': {
            pr: 0,
          },
          '&:nth-of-type(4)': {
            pl: 0,
          },
          '& .MuiTableRow-root': {
            '.MuiTableCell-head': {
              '&:nth-of-type(1)': {
                pl: 0,
              },
            },
          },
        },
      }}
      boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
    />
  );
}

function StrategyAccordionAndFeeSlabsTable({
  values,
  setValues,
  feeSlabsData,
  strategyMasterData,
  strategy,
  applicationStrategyIndex,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  strategyMasterData: Partial<StrategyType>[];
  feeSlabsData: FeeSlabsType[];
  strategy: Partial<updatedStrategyDetails>;
  applicationStrategyIndex: number;
}): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);

  const FeeTypeMaster = feeSlabsData
    .map((fee) => fee.feeType)
    .filter((value, index, array) => array.indexOf(value) === index);
  return (
    <Accordion
      sx={{
        boxShadow: 'none',
        mb: 3,
        cursor: 'default',
        '&::before': { display: 'none' },
        '& .MuiFormControl-root': { m: 0, height: 44 },
        '& .MuiOutlinedInput-root': {
          mt: 0,
        },
        '& .label': { fontSize: 14, fontWeight: 500, color: 'rgba(0,0,0,0.7)', mb: 1 },
      }}
      expanded={strategy?.isActive}>
      <AccordionSummary
        sx={{
          bgcolor: 'rgba(221, 234, 243, 0.2)',
          borderRadius: '5px',
          cursor: 'default',
          '&.Mui-expanded': { minHeight: 'unset', cursor: 'default' },
          '& .MuiAccordionSummary-content': { m: 0, py: 0.7, '&.Mui-expanded': { m: 0 } },
        }}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack>
          <Typography
            sx={{
              color: '#131836',
              fontSize: 16,
              fontWeight: 500,
              width: '100%',
              cursor: 'default',
              display: 'flex',
              alignItems: 'center',
            }}>
            <MFCheckbox
              name={`applicationstrategydetails.${applicationStrategyIndex}.isActive`}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              label=""
              sx={{ letterSpacing: '1px', mr: 0 }}
              onChange={({ target: { checked } }) => {
                const updatedStrategy = values.applicationstrategydetails?.map(
                  (strategyDetails, _index) => {
                    const Checked = applicationStrategyIndex === _index;
                    return {
                      ...strategyDetails,
                      isActive: Checked,
                      feeType: Checked ? strategyDetails.feeType : null,
                    };
                  }
                ) as updatedStrategyDetails[];
                setValues({
                  ...values,
                  applicationstrategydetails: updatedStrategy,
                  feeSlabdetails:
                    updatedStrategy.find((strategy) => strategy.isActive)?.strategyId ===
                    values.applicationstrategydetails.find((strategy) => strategy.isActive)
                      ?.strategyId
                      ? values.feeSlabdetails
                      : feeSlabsData,
                  totalStrategyInvestmentAmount: getTotalStrategyAmount(updatedStrategy) || '',
                });
              }}
              disabled={isFieldDisabled}
            />
            {strategy?.strategyName}{' '}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              '& .MuiFormControl-root': {
                mb: 3,
                height: 'auto',
                '& .MuiFormControlLabel-root': { mb: { xs: 1, sm: 0 } },
              },
            }}>
            <UseRadioGroup
              formLabel="Fee Type *"
              name={`applicationstrategydetails.${applicationStrategyIndex}.feeType`}
              items={FeeTypeMaster.map((fee) => {
                return { label: fee, value: fee };
              })}
              onChange={({ target: { value } }) => {
                setValues({
                  ...values,
                  applicationstrategydetails: values.applicationstrategydetails.map(
                    (strategy, indx) => {
                      if (indx === applicationStrategyIndex)
                        return {
                          ...strategy,
                          feeType: value as FeeType,
                        };
                      return strategy;
                    }
                  ),
                  feeSlabdetails: feeSlabsData.filter((fee) => fee.feeType === value),
                });
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ borderTop: '1px solid #CBCBCB', pt: 2 }}>
          <FeeSlabBox setValues={setValues} values={values} feeSlabs={values.feeSlabdetails} />
        </Box>
        {/* <FeeSlabTable
          _index={applicationStrategyIndex}
          setValues={setValues}
          values={values}
          feeSlabs={values.feeSlabdetails}
        /> */}
      </AccordionDetails>
    </Accordion>
  );
}

export default function StrategyDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [strategyData, setStrategyData] = useState<Partial<StrategyType>[]>([]);
  const [feeSlabsData, setFeeSlabsData] = useState<FeeSlabsType[]>([]);
  const [selectedStrategyData, setSelectedStrategyData] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        let response = [] as Partial<StrategyType>[];
        let responseFeeSlab = [] as FeeSlabsType[];
        const {
          applicationstrategydetail,
          applicationfeeslab,
          distributor_id,
          totalStrategyInvestmentAmount,
          investmentAmount,
        } = application || {};
        if (distributor_id) {
          const distributor = (await dispatch(
            getDistributorById(distributor_id)
          )) as unknown as Distributor;
          response = distributor.strategies as Partial<StrategyType>[];
          responseFeeSlab = distributor.feeSlabs as FeeSlabsType[];
          // console.log(responseFeeSlab);
        } else {
          response = (await dispatch(
            getStarategyListOfDirect()
          )) as unknown as Partial<StrategyType>[];
          responseFeeSlab = (await dispatch(getFeeSlabListOfDirect())) as unknown as FeeSlabsType[];
        }
        if (!isComponentAlive) {
          return;
        }
        response = applicationstrategydetail?.strategyId
          ? !response
              .map((strategy) => strategy?.id)
              .includes(applicationstrategydetail?.strategyId)
            ? response.concat({
                ...applicationstrategydetail,
                id: applicationstrategydetail?.strategyId,
              })
            : response
          : response;
        responseFeeSlab = applicationfeeslab?.feeSlabId
          ? !responseFeeSlab.map((fee) => fee?.id).includes(applicationfeeslab?.feeSlabId)
            ? responseFeeSlab.concat({ ...applicationfeeslab, id: applicationfeeslab?.feeSlabId })
            : responseFeeSlab
          : responseFeeSlab;
        setStrategyData(
          response.map((strategy) => {
            return {
              ...strategy,
              strategyId: strategy?.id,
            };
          })
        );
        setFeeSlabsData(
          responseFeeSlab?.map((fee) => {
            const { id, ...rest } = fee;
            return {
              ...rest,
              feeSlabId: id,
              isActive: false,
              hurdlerates: fee.hurdlerates?.length ? fee.hurdlerates : [],
            };
          })
        );
        const data = {
          ...selectedStrategyData,
          feeSlabdetails: responseFeeSlab.map((fee) => {
            const { id, ...rest } = fee;
            return {
              ...rest,
              feeSlabId: id,
              isActive: fee.id === applicationfeeslab?.feeSlabId,
              hurdlerates: fee.hurdlerates?.length ? fee.hurdlerates : [],
            };
          }),
          applicationstrategydetails: response
            ?.map((strategy) => {
              return [
                {
                  ...newStrategy,
                  ...strategy,
                  strategyName: strategy.strategyName,
                  strategyId: strategy.id,
                  isActive: applicationstrategydetail?.strategyId === strategy.id,
                  feeType:
                    applicationstrategydetail?.strategyId === strategy.id
                      ? (responseFeeSlab.find((fee) => fee?.id === applicationfeeslab?.feeSlabId)
                          ?.feeType as FeeType)
                      : '',
                },
              ];
            })
            .flat(),
        };
        setSelectedStrategyData({
          ...data,
          investmentAmount: investmentAmount || null,
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      // console.log('valuesss', values);
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicationstrategydetails, feeSlabdetails, cheque, rtgs, saveType, ...rest } =
        values;

      if (!checkStrategyBoxSelected(applicationstrategydetails)) {
        throw 'Please Select One Strategy';
      }
      if (
        applicationstrategydetails.length &&
        !checkStrategyFeeTypeSelected(applicationstrategydetails)
      ) {
        throw 'Please Select One Fee Type ';
      }
      if (!checkStrategyFeeSlabSelected(applicationstrategydetails, feeSlabdetails)) {
        throw 'Please Select One Fee Slab';
      }
      let paymentMode = '';
      if (cheque && rtgs) {
        paymentMode = Object.keys(ModeOfTransaction)?.toString();
      } else if (cheque && !rtgs) {
        paymentMode = 'cheque';
      } else if (!cheque && rtgs) {
        paymentMode = 'rtgs';
      }
      const updatedPayload = {
        ...application,
        ...rest,
        totalStrategyInvestmentAmount: rest.totalStrategyInvestmentAmount || null,
        investmentAmount: rest.investmentAmount || null,
        modeOfPayment: paymentMode,
        applicationfeeslab: feeSlabdetails.find((fee) => fee.isActive)?.feeSlabId
          ? {
              ...application?.applicationfeeslab,
              feeSlabId: feeSlabdetails.find((fee) => fee.isActive)?.feeSlabId,
            }
          : null,
        applicationstrategydetail: applicationstrategydetails.find((strategy) => strategy.isActive)
          ?.strategyId
          ? {
              ...application?.applicationstrategydetail,
              strategyId: applicationstrategydetails.find((strategy) => strategy.isActive)
                ?.strategyId,
            }
          : null,
      };
      const checkApplication = applicationComparison(
        {
          ...application,
        },
        {
          ...updatedPayload,
          currentStep: !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 9,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('disclosure-of-interest', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('disclosure-of-interest', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };
  return (
    <>
      <Formik
        initialValues={selectedStrategyData}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validate={(values: Values) => {
          try {
            validateYupSchema(values, StrategyDetailSchema, true, values);
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}>
        {({ handleSubmit, setValues, values }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              {values.applicationstrategydetails?.length !== 0 ? (
                <Box sx={{ mt: 3 }}>
                  {values.applicationstrategydetails?.map((strategy, index) => (
                    <StrategyAccordionAndFeeSlabsTable
                      key={index}
                      values={values}
                      setValues={setValues}
                      strategyMasterData={strategyData}
                      feeSlabsData={feeSlabsData}
                      strategy={strategy}
                      applicationStrategyIndex={index}
                    />
                  ))}
                </Box>
              ) : (
                <Box sx={{ mt: 3, textAlign: 'center' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    No strategies mapped for this distributor
                  </Typography>
                </Box>
              )}
              {/* <SubHeading sx={{ color: '#131836', fontSize: 18, fontWeight: 500, mt: 0, mb: 3 }}>
                Fund details
              </SubHeading> */}
              <Box sx={{ mb: 5, maxWidth: 310, '& .MuiFormControl-root': { mb: 0 } }}>
                <MFTextField
                  name="investmentAmount"
                  label="Total Investment Amount (in INR) *"
                  placeholder="Enter Total Investment Amount"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  // disabled={true}
                />
                {values.investmentAmount && (
                  <Typography sx={{ fontSize: 13, mt: 0.5 }}>
                    {currencyConversion(values.investmentAmount)}
                  </Typography>
                )}
              </Box>
              <ProceedSaveLater
                saveLater={() => {
                  setValues({
                    ...values,
                    saveType: 'save for later',
                  });
                }}
                saveAndProceed={() => {
                  setValues({
                    ...values,
                    saveType: 'save and proceed',
                  });
                }}
                loader={loading}
                clickedButton={values.saveType}
              />
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}
