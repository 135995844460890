import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import SourceOfInvestment from './sourceOfInvestment';
import { grossAnnualMasters } from '../../utils/constant';

export default function KycDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Additional KYC Details
            </Typography>
            {application.applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={15}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {/* {Number(applicant.applicant_type) < 3 && ( */}
                      <KeyValue
                        title={'Gross Annual Income'}
                        description={grossAnnualMasters[applicant.grossAnnualIncome || '']}
                      />
                      {/* )} */}
                      <KeyValue
                        title={'Gross Annual Income as on Date'}
                        description={formatShortDate(applicant.grossAnnualIncomeDate)}
                      />
                      <KeyValue title={'NetWorth (in INR)'} description={applicant.netWorth} />
                      <KeyValue
                        title={'Networth as on Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      />
                      {/* <KeyValue
                        title={'Net-Worth (Mandatory For Non-Individuals)'}
                        description={applicant.netWorth}
                      />
                      <KeyValue
                        title={'Net Worth Date'}
                        description={formatShortDate(applicant.netWorthDate)}
                      /> */}
                      <KeyValue
                        title={'Politically Exposed Person (PEP) Status'}
                        description={applicant.politicallyExposedPersonStatus}
                      />
                      <KeyValue title={'CKYC Number'} description={applicant.ckycNo} />
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
          <SourceOfInvestment application={application} />
        </Box>
      </Box>
    </>
  );
}
