import OtpInput from 'react-otp-input';
import { Children, cloneElement, Component } from 'react';
import { Typography } from '@mui/material';

const isStyleObject = (obj: any) => typeof obj === 'object';

class CustumOtpInput extends OtpInput {
  render() {
    const { containerStyle } = this.props;
    const inputs = this.renderInputs();

    return (
      <Typography
        component={'div'}
        style={Object.assign({ display: 'flex' }, isStyleObject(containerStyle) && containerStyle)}
        //className={!isStyleObject(containerStyle) ? containerStyle : ''}
      >
        {Children.map(inputs, (child, index) =>
          cloneElement(child, {
            inputmode: 'numeric',
          })
        )}
      </Typography>
    );
  }
}

export default CustumOtpInput;
