import { CALL_API } from '../middleware';

export type checkWhitelistedResponceBody = {
  id: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  countryNameAndCode: string;
  phone: string;
  email: string;
  role: string;
  createdBy: string;
  updatedBy: string;
  isActive: boolean;
  isMobileNumberWhiteListed: boolean;
  createdAt: string;
  updatedAt: string;
};

export type whitelistUnlockUserRequestBody = {
  phone: string;
  countryCode: string;
};

export type checkWhitelistRequestBody = {
  phone: string;
  countryCode: string;
  whiteList: boolean;
};
export type fromExpire = {
  applicationNumber: string;
  applicationStatus: string;
};
export type moveToDraft = {
  applicationNumber: string;
};

export type supportTypeBody = {
  message: string;
};

export type unlockAccessBody = {
  unlockingAccess: boolean;
};

export type whitelistAccessBody = {
  whitelistingAccess: boolean;
};

export type statusAccessBody = {
  applicationStatusChangeAccess: boolean;
};

const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS';
const UNLOCK_USER = 'UNLOCK_USER';

const WHITELIST_USER_SUCCESS = 'WHITELIST_USER_SUCCESS';
const WHITELIST_USER = 'WHITELIST_USER';

const CHECK_WHITELIST_USER_SUCCESS = 'CHECK_WHITELIST_USER_SUCCESS';
const CHECK_WHITELIST_USER = 'CHECK_WHITELIST_USER_SUCCESS';

const UNLOCK_USER_ACCESS_SUCCESS = 'UNLOCK_USER_ACCESS_SUCCESS';
const UNLOCK_USER_ACCESS = 'UNLOCK_USER_ACCESS';

const WHITELIST_USER_ACCESS_SUCCESS = 'WHITELIST_USER_ACCESS_SUCCESS';
const WHITELIST_USER_ACCESS = 'WHITELIST_USER_ACCESS';

const STATUS_ACCESS_SUCCESS = 'STATUS_ACCESS_SUCCESS';
const STATUS_ACCESS = 'STATUS_ACCESS';

const STATUS_CHANGE_FROM_EXPIRED_SUCCESS = 'STATUS_CHANGE_FROM_EXPIRED_SUCCESS';
const STATUS_CHANGE_FROM_EXPIRED = 'STATUS_CHANGE_FROM_EXPIRED';

const STATUS_CHANGE_TO_DRAFT_SUCCESS = 'STATUS_CHANGE_TO_DRAFT_SUCCESS';
const STATUS_CHANGE_TO_DRAFT = 'STATUS_CHANGE_TO_DRAFT';

export const unlockUser =
  (body: whitelistUnlockUserRequestBody) =>
  async (dispatch: any): Promise<supportTypeBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/users/unlock-user`,
        method: 'POST',
        types: [UNLOCK_USER_SUCCESS, UNLOCK_USER],
        body,
      },
    });
  };

export const whitelistUser =
  (body: checkWhitelistRequestBody) =>
  async (dispatch: any): Promise<supportTypeBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/users/whitelist-user`,
        method: 'POST',
        types: [WHITELIST_USER_SUCCESS, WHITELIST_USER],
        body,
      },
    });
  };

export const checkWhitelistUser =
  (body: whitelistUnlockUserRequestBody) =>
  async (dispatch: any): Promise<checkWhitelistedResponceBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/users/check-whitelisted`,
        method: 'POST',
        types: [CHECK_WHITELIST_USER_SUCCESS, CHECK_WHITELIST_USER],
        body,
      },
    });
  };

export const changeStatusFromExpiredexport =
  (body: fromExpire) =>
  async (dispatch: any): Promise<fromExpire> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/application-status-change/from-expired`,
        method: 'POST',
        types: [STATUS_CHANGE_FROM_EXPIRED_SUCCESS, STATUS_CHANGE_FROM_EXPIRED],
        body,
      },
    });
  };

export const changeStatusToDraft =
  (body: moveToDraft) =>
  async (dispatch: any): Promise<moveToDraft> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/application-status-change/move-to-draft`,
        method: 'POST',
        types: [STATUS_CHANGE_TO_DRAFT_SUCCESS, STATUS_CHANGE_TO_DRAFT],
        body,
      },
    });
  };

export const unlockAccess =
  () =>
  async (dispatch: any): Promise<unlockAccessBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/users/unlocking-access`,
        method: 'GET',
        types: [UNLOCK_USER_ACCESS_SUCCESS, UNLOCK_USER_ACCESS],
      },
    });
  };

export const WhitelistingAccess =
  () =>
  async (dispatch: any): Promise<whitelistAccessBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/users/users/whitelisting-access`,
        method: 'GET',
        types: [WHITELIST_USER_ACCESS_SUCCESS, WHITELIST_USER_ACCESS],
      },
    });
  };

export const changeStatusAccess =
  () =>
  async (dispatch: any): Promise<statusAccessBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/application-status-change/access`,
        method: 'GET',
        types: [STATUS_ACCESS_SUCCESS, STATUS_ACCESS],
      },
    });
  };
