/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MFCheckbox from '../../lib/formik/Checkbox';
import { NomineeType } from '../../redux-store/types/api-types';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkAddressField,
  checkInitialApplicationStatusIsChecker,
  getApplicantName,
  getDisableStatusForjointHolder,
  getNomineeRelation,
  isMinor,
  removeSingleQuote,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory, useParams } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { nomineeDetailsSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  NomineeRelations,
  USER_ROLES,
} from '../../utils/constant';
import { Notes } from './components';
import { useSnackbar } from 'notistack';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';

type updateNominee = NomineeType & {
  Relationship: string | null;
  sno?: number;
  otherGuardianRelationship: string | null;
};

type Values = {
  doNotWishToNominate: boolean;
  nominees: updateNominee[];
  saveType: string;
  countryDropdown: string[];
};

export const newNominee: updateNominee = {
  nomineeName: '',
  nomineeRelationship: '',
  dateOfBirth: null,
  nomineePercentage: 0,
  guardianName: '',
  guardianFirstName: '',
  guardianLastName: '',
  guardianDOB: null,
  nomineePan: '',
  nomineeGuardianPan: '',
  isActive: true,
  Relationship: '',
  nomineeCountry: null,
  guardianCountry: null,
  sno: 0,
  guardianRelationship: '',
  otherGuardianRelationship: '',
  firstName: '',
  lastName: '',
};

const initialValues: Values = {
  doNotWishToNominate: true,
  nominees: [newNominee],
  saveType: 'save and proceed',
  countryDropdown: [],
};

const Nominee = ({
  nomineeName,
  index,
  values,

  nationalitiesMdmsResponse,
  address,
}: {
  nomineeName: number;
  index: number;
  values: Values;
  nationalitiesMdmsResponse: mdmsCountriesList[];
  address: any;
}): JSX.Element => {
  const { dateOfBirth = '' } = values.nominees[index];
  const { setFieldValue, setValues } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  useEffect(() => {
    setValues({
      ...values,
      nominees: values.nominees.map((nominee, i) => {
        if (i === index) {
          return {
            ...nominee,
            guardianAddress1:
              nominee.guardianAddress1 === null
                ? checkAddressField(address?.address1)
                : nominee.guardianAddress1,
            guardianAddress2:
              nominee.guardianAddress2 === null
                ? checkAddressField(address?.address2)
                : nominee.guardianAddress2,
            guardianAddress3:
              nominee.guardianAddress3 === null
                ? checkAddressField(address?.address3)
                : nominee.guardianAddress3,
            guardianCity:
              nominee.guardianCity === null
                ? checkAddressField(address?.city)
                : nominee.guardianCity,
            guardianState:
              nominee.guardianState === null
                ? checkAddressField(address?.state)
                : nominee.guardianState,
            guardianCountry:
              nominee.guardianCountry === null
                ? checkAddressField(address?.country)
                : nominee.guardianCountry?.toUpperCase(),
            guardianPincode:
              nominee.guardianPincode === null ? address?.pincode : nominee.guardianPincode,
          };
        }
        return nominee;
      }),
    });
  }, [values.nominees[index].dateOfBirth]);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.firstName`}
          label={`Nominee First Name ${nomineeName} *`}
          placeholder={`Enter Nominee First Name ${nomineeName}`}
          disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.lastName`}
          label={`Nominee Last Name ${nomineeName} *`}
          placeholder={`Enter Nominee Last Name ${nomineeName}`}
          disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`nominees.${index}.Relationship`}
          label="Nominee Relationship *"
          items={Object.keys(NomineeRelations)
            .filter((relation) => {
              const { nominees } = values;
              const selectedRelations = nominees
                .filter(
                  (nominee, ind) =>
                    ind !== index &&
                    nominee.Relationship &&
                    nominee.isActive &&
                    [
                      NomineeRelations.mother,
                      NomineeRelations.father,
                      NomineeRelations.spouse,
                    ].includes(NomineeRelations[nominee.Relationship || ''])
                )
                .map((nominee) => nominee.Relationship);
              return !selectedRelations.includes(relation);
            })
            .map((relation) => ({
              key: NomineeRelations[relation],
              value: relation,
            }))}
          disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
        />
      </Grid>
      {values.nominees.map((nominee, idx: number) => {
        if (
          NomineeRelations[nominee?.Relationship || ''] === NomineeRelations.others &&
          idx === index
        ) {
          return (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.nomineeRelationship`}
                label="Relationship *"
                placeholder={`Enter Nominee Relationship`}
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
          );
        }
      })}
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Date of Birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of Birth'}
          disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          name={`nominees.${index}.dateOfBirth`}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePercentage`}
          label="Nominee % *"
          placeholder="Enter Nominee %"
          disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
        />
      </Grid>
      {/* <---------Nominee Address-------> */}
      <>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`nominees.${index}.nomineeAddress1`}
            label={`Nominee Address Line 1 *`}
            placeholder="Enter Nominee Address Line 1"
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`nominees.${index}.nomineeAddress2`}
            label={`Nominee Address Line 2 *`}
            placeholder="Enter Nominee Address Line 2"
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`nominees.${index}.nomineeAddress3`}
            label={`Nominee Address Line 3 *`}
            placeholder="Enter Nominee Address Line 3"
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`nominees.${index}.nomineePincode`}
            label={`Nominee Pincode *`}
            placeholder="Enter Nominee Pincode"
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`nominees.${index}.nomineeCity`}
            label={`Nominee City *`}
            placeholder="Enter Nominee City"
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`nominees.${index}.nomineeState`}
            label={`Nominee State *`}
            placeholder="Enter Nominee State"
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SearchableSelect
            name={`nominees.${index}.nomineeCountry`}
            label="Nominee Country *"
            items={nationalitiesMdmsResponse.map((nationality) => ({
              key: nationality.name,
              value: nationality.name,
            }))}
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
            searchFieldPlaceholder={'Search Country'}
          />
        </Grid>
      </>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`nominees.${index}.nomineePan`}
          label={`Nominee PAN/ Aadhaar *`}
          placeholder="Enter Nominee PAN/ Aadhaar"
          inputProps={{ style: { textTransform: 'uppercase' } }}
          disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          onChange={(e) => {
            setFieldValue(`nominees.${index}.nomineePan`, e.target.value.toUpperCase());
          }}
        />
      </Grid>
      {isMinor(dateOfBirth ? dateOfBirth : '') && (
        <>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.guardianFirstName`}
              label={`Guardian First Name ${nomineeName} *`}
              placeholder={`Enter Guardian First Name ${nomineeName}`}
              disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.guardianLastName`}
              label={`Guardian Last Name ${nomineeName} *`}
              placeholder={`Enter Guardian Last Name ${nomineeName}`}
              disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextDatePicker
              label={'Guardian Date of Birth *'}
              inputLabelStyles={{
                transform: 'unset',
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
              }}
              placeholder={'Enter Guardian Date of Birth'}
              disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              name={`nominees.${index}.guardianDOB`}
              minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFTextField
              name={`nominees.${index}.nomineeGuardianPan`}
              label="Guardian PAN *"
              placeholder="Enter Guardian Pan"
              inputProps={{ style: { textTransform: 'uppercase' } }}
              disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              onChange={(e) => {
                setFieldValue(`nominees.${index}.nomineeGuardianPan`, e.target.value.toUpperCase());
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={`nominees.${index}.guardianRelationship`}
              label="Guardian Relationship *"
              items={Object.keys(NomineeRelations).map((relation) => ({
                key: NomineeRelations[relation],
                value: relation,
              }))}
              disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
            />
          </Grid>
          {values.nominees.map((nominee, idx: number) => {
            if (
              NomineeRelations[nominee.guardianRelationship || ''] === NomineeRelations.others &&
              idx === index
            ) {
              return (
                <Grid item xs={12} sm={6}>
                  <MFTextField
                    name={`nominees.${index}.otherGuardianRelationship`}
                    label="Guardian Relationship *"
                    placeholder={`Enter Guardian Relationship`}
                    disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
                  />
                </Grid>
              );
            }
          })}
          {/* <---------Guardian Address-------> */}
          <>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.guardianAddress1`}
                label={`Guardian Address Line 1 *`}
                placeholder="Enter Guardian Address Line 1"
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.guardianAddress2`}
                label={`Guardian Address Line 2 *`}
                placeholder="Enter Guardian Address Line 2"
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.guardianAddress3`}
                label={`Guardian Address Line 3 *`}
                placeholder="Enter Guardian Address Line 3"
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.guardianPincode`}
                label={`Guardian Pincode *`}
                placeholder="Enter Guardian Pincode"
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.guardianCity`}
                label={`Guardian City *`}
                placeholder="Enter Guardian City"
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={`nominees.${index}.guardianState`}
                label={`Guardian State *`}
                placeholder="Enter Guardiane State"
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchableSelect
                name={`nominees.${index}.guardianCountry`}
                label="Guardian Country *"
                items={nationalitiesMdmsResponse.map((nationality) => ({
                  key: nationality.name,
                  value: nationality.name,
                }))}
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
                searchFieldPlaceholder={'Search Country'}
              />
            </Grid>
          </>
        </>
      )}
    </>
  );
};

export default function NomineeDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [nomineesData, setNomineesData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [nomineeToDelete, setNomineeToDelete] = useState<{
    nominee: Partial<NomineeType>;
    index: number;
  } | null>(null);
  const [bulkNomineeDelete, setBulkNomineeDelete] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();
  const [defaultAddress, setDefaultAddress] = useState<string>('');
  const [address, setAddress] = useState<any>();
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);

  const onSubmit = async (values: Values) => {
    try {
      const {
        nominees: existingNominees = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { nominees, doNotWishToNominate, saveType } = values;
      const updatedPayload = {
        ...application,
        doNotWishToNominate,
        nominees: nominees
          .filter((nominee) => nominee?.id || nominee.isActive)
          .map((nominee, index) => {
            const { id = null } = existingNominees[index] || {};
            if (nominee.id === id) {
              const { Relationship, otherGuardianRelationship, sno, ...rest } = nominee;
              return {
                ...(existingNominees[index] || {}),
                ...rest,
                nomineeRelationship: getNomineeRelation(Relationship)
                  ? Relationship
                  : removeSingleQuote(nominee.nomineeRelationship),
                dateOfBirth: !nominee.dateOfBirth
                  ? null
                  : new Date(nominee.dateOfBirth).toISOString(),
                isActive: nominee.isActive && !doNotWishToNominate,
                nomineeName: removeSingleQuote(rest.nomineeName),
                firstName: removeSingleQuote(rest.firstName),
                lastName: removeSingleQuote(rest.lastName),
                guardianName: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianName)
                  : null,
                guardianFirstName: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianFirstName)
                  : null,
                guardianLastName: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianLastName)
                  : null,
                guardianDOB: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? !rest.guardianDOB
                    ? null
                    : new Date(rest.guardianDOB).toISOString()
                  : null,
                nomineeGuardianPan: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.nomineeGuardianPan)
                  : null,
                guardianRelationship: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? getNomineeRelation(nominee.guardianRelationship)
                    ? nominee.guardianRelationship
                    : removeSingleQuote(otherGuardianRelationship)
                  : null,
                guardianAddress1: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianAddress1)
                  : null,
                guardianAddress2: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianAddress2)
                  : null,
                guardianAddress3: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianAddress3)
                  : null,
                guardianPincode: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianPincode)
                  : null,
                guardianCity: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianCity)
                  : null,
                guardianState: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? removeSingleQuote(rest.guardianState)
                  : null,
                guardianCountry: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? rest.guardianCountry
                  : null,
                nomineePercentage:
                  nominee.nomineePercentage === 0 || nominee.nomineePercentage
                    ? Number(nominee.nomineePercentage)
                    : null,
              };
            }
            const { Relationship, otherGuardianRelationship, sno, ...rest1 } =
              nominees[nominee.sno as number];
            return {
              ...rest1,
              nomineeRelationship: removeSingleQuote(rest1.nomineeRelationship),
              nomineeName: removeSingleQuote(rest1.nomineeName),
              firstName: removeSingleQuote(rest1.firstName),
              lastName: removeSingleQuote(rest1.lastName),
              guardianName: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianName)
                : null,
              guardianFirstName: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianFirstName)
                : null,
              guardianLastName: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianLastName)
                : null,
              guardianDOB: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? !rest1.guardianDOB
                  ? null
                  : new Date(rest1.guardianDOB).toISOString()
                : null,
              nomineeGuardianPan: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.nomineeGuardianPan)
                : null,
              guardianRelationship: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? getNomineeRelation(rest1.guardianRelationship)
                  ? rest1.guardianRelationship
                  : removeSingleQuote(otherGuardianRelationship)
                : null,
              guardianAddress1: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianAddress1)
                : null,
              guardianAddress2: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianAddress2)
                : null,
              guardianAddress3: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianAddress3)
                : null,
              guardianPincode: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianPincode)
                : null,
              guardianCity: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianCity)
                : null,
              guardianState: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? removeSingleQuote(rest1.guardianState)
                : null,
              guardianCountry: isMinor(rest1.dateOfBirth ? rest1.dateOfBirth : '')
                ? rest1.guardianCountry
                : null,
            };
          }),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          nominees: application?.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
        },
        {
          ...updatedPayload,
          nominees: updatedPayload.nominees?.sort(
            (nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)
          ),
          currentStep: !!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 6,
              //!!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('bank-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('bank-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };
  const [ModeOfHolding, SetModeOfHolding] = useState<boolean>(true);
  useEffect(() => {
    (async function () {
      try {
        const {
          nominees = [],
          doNotWishToNominate = true,
          modeOfHolding = '',
          applicants = [],
        } = application || {};
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
        const paramanantAddress = applicants
          .find((applicant) => applicant.applicant_type === '1')
          ?.addresses?.find((address) => address.address_type?.toLowerCase() === 'permanent');
        const address = `${checkAddressField(paramanantAddress?.address1)}${checkAddressField(
          paramanantAddress?.address2
        )}
    ${checkAddressField(paramanantAddress?.city)}${checkAddressField(
          paramanantAddress?.state
        )}${checkAddressField(paramanantAddress?.country)}${paramanantAddress?.pincode}`;
        setAddress(paramanantAddress);
        setDefaultAddress(address);
        SetModeOfHolding(modeOfHolding === 'single');
        setNomineesData({
          ...nomineesData,
          doNotWishToNominate:
            modeOfHolding === 'single'
              ? false
              : nominees.length
              ? false
              : doNotWishToNominate === null
              ? true
              : doNotWishToNominate,
          nominees: nominees.length
            ? nominees.map((nominee, index) => ({
                ...nominee,
                Relationship: getNomineeRelation(nominee.nomineeRelationship?.toLowerCase())
                  ? nominee.nomineeRelationship
                    ? nominee.nomineeRelationship.toLowerCase()
                    : nominee.nomineeRelationship
                  : 'others',
                nomineeRelationship: getNomineeRelation(nominee.nomineeRelationship?.toLowerCase())
                  ? ''
                  : nominee.nomineeRelationship,
                nomineeCountry: nominee?.nomineeCountry?.toUpperCase() || '',
                guardianRelationship: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? getNomineeRelation(nominee.guardianRelationship?.toLowerCase())
                    ? nominee.guardianRelationship
                      ? nominee.guardianRelationship.toLowerCase()
                      : nominee.guardianRelationship
                    : 'others'
                  : null,
                otherGuardianRelationship: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? getNomineeRelation(nominee.guardianRelationship?.toLowerCase())
                    ? ''
                    : nominee.guardianRelationship
                  : null,
                guardianAddress1: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddress1
                    ? nominee.guardianAddress1
                    : ''
                  : null,
                guardianAddress2: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddress2
                    ? nominee.guardianAddress2
                    : ''
                  : null,
                guardianAddress3: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianAddress3
                    ? nominee.guardianAddress3
                    : ''
                  : null,
                guardianCity: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianCity
                    ? nominee.guardianCity
                    : ''
                  : null,
                guardianState: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianState
                    ? nominee.guardianState
                    : ''
                  : null,
                guardianCountry: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianCountry
                    ? nominee.guardianCountry?.toUpperCase()
                    : ''
                  : null,
                guardianPincode: isMinor(nominee.dateOfBirth ? nominee.dateOfBirth : '')
                  ? nominee.guardianPincode
                    ? nominee.guardianPincode
                    : ''
                  : null,
                sno: index,
                nomineePercentage: nominee.nomineePercentage
                  ? nominee.nomineePercentage
                  : nominees.length === 1
                  ? 100
                  : 0,
              }))
            : modeOfHolding === 'single'
            ? [
                {
                  ...newNominee,
                  nomineePercentage: 100,
                  nomineeAddress1: checkAddressField(paramanantAddress?.address1),
                  nomineeAddress2: checkAddressField(paramanantAddress?.address2),
                  nomineeAddress3: checkAddressField(paramanantAddress?.address3),
                  nomineeCity: paramanantAddress?.city,
                  nomineeState: paramanantAddress?.state,
                  nomineeCountry: paramanantAddress?.country?.toUpperCase(),
                  nomineePincode: paramanantAddress?.pincode,
                  guardianAddress1: checkAddressField(paramanantAddress?.address1),
                  guardianAddress2: checkAddressField(paramanantAddress?.address2),
                  guardianAddress3: checkAddressField(paramanantAddress?.address3),
                  guardianCity: paramanantAddress?.city,
                  guardianState: paramanantAddress?.state,
                  guardianCountry: paramanantAddress?.country?.toUpperCase(),
                  guardianPincode: paramanantAddress?.pincode,
                },
              ]
            : [],
          countryDropdown: nationalitiesMdmsMasters.countries.map((list) => list.name),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  return (
    <Formik
      initialValues={nomineesData}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nomineeDetailsSchema(application?.applicants), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, errors }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes displayContent={'Investor(s) have the option to choose upto three nominees'} />
          <Grid item xs={12}>
            <MFCheckbox
              // disabled={ModeOfHolding}
              name="doNotWishToNominate"
              label="I do not wish to nominate."
              sx={{ letterSpacing: '1px' }}
              onChange={({ target: { checked } }) => {
                const updatedNominees = !checked
                  ? [
                      ...values.nominees,
                      {
                        ...newNominee,
                        nomineePercentage: 100,
                        sno: values.nominees.length,
                        nomineeAddress1: checkAddressField(address?.address1),
                        nomineeAddress2: checkAddressField(address?.address2),
                        nomineeAddress3: checkAddressField(address?.address3),
                        nomineeCity: address?.city,
                        nomineeState: address?.state,
                        nomineeCountry: address?.country?.toUpperCase(),
                        nomineePincode: address?.pincode,
                        guardianAddress1: checkAddressField(address?.address1),
                        guardianAddress2: checkAddressField(address?.address2),
                        guardianAddress3: checkAddressField(address?.address3),
                        guardianCity: address?.city,
                        guardianState: address?.state,
                        guardianCountry: address?.country?.toUpperCase(),
                        guardianPincode: address?.pincode,
                      },
                    ]
                  : values.nominees;
                setValues({
                  ...nomineesData,
                  doNotWishToNominate:
                    values.nominees?.filter((item) => item.isActive).length === 0
                      ? checked
                      : !checked,
                  nominees: updatedNominees.map((nominee) => ({
                    ...nominee,
                  })),
                });
                checked && setBulkNomineeDelete(true);
              }}
              checked={values.doNotWishToNominate}
              disabled={
                ModeOfHolding ||
                getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
              }
            />
          </Grid>
          {values.nominees
            .filter((_nominee) => _nominee.isActive)
            .map((nominee, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}>
                  {getApplicantName(index + 1, true)} Nominee Details
                  {((ModeOfHolding &&
                    values.nominees.filter((nominee) => nominee.isActive).length !== 1) ||
                    !ModeOfHolding) && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => setNomineeToDelete({ nominee, index: nominee.sno as number })}>
                      <Typography
                        sx={{
                          color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          cursor: 'pointer',
                        }}>
                        Remove Nominee{' '}
                      </Typography>
                      <IconButton sx={{ p: '2px' }}>
                        <CardMedia
                          component="img"
                          src="/images/delete-red.svg"
                          alt="Delete Icon"
                          sx={{ width: 'unset' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>
                <Nominee
                  nomineeName={index + 1}
                  index={nominee.sno as number}
                  key={nominee.sno as number}
                  values={values}
                  nationalitiesMdmsResponse={mdmsCountriesList}
                  address={address}
                />
              </React.Fragment>
            ))}
          {values.nominees.filter((item) => item.isActive).length < 3 && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 2,
                }}
                disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
                onClick={() =>
                  setValues({
                    ...values,
                    nominees: [
                      ...values.nominees,
                      {
                        ...newNominee,
                        nomineePercentage: values.nominees.filter((item) => item.isActive).length
                          ? 0
                          : 100,
                        sno: values.nominees.length,
                        nomineeAddress1: checkAddressField(address?.address1),
                        nomineeAddress2: checkAddressField(address?.address2),
                        nomineeAddress3: checkAddressField(address?.address3),
                        nomineeCity: address?.city,
                        nomineeState: address?.state,
                        nomineeCountry: address?.country?.toUpperCase(),
                        nomineePincode: address?.pincode,
                        guardianAddress1: checkAddressField(address?.address1),
                        guardianAddress2: checkAddressField(address?.address2),
                        guardianAddress3: checkAddressField(address?.address3),
                        guardianCity: address?.city,
                        guardianState: address?.state,
                        guardianCountry: address?.country?.toUpperCase(),
                        guardianPincode: address?.pincode,
                      },
                    ],
                    doNotWishToNominate: false,
                  })
                }>
                Add Nominee
              </Button>
            </Grid>
          )}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    NomineeRelations[nominee?.Relationship || ''] === NomineeRelations.others
                      ? nominee.nomineeRelationship
                      : nominee.Relationship,
                })),
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                nominees: values.nominees.map((nominee) => ({
                  ...nominee,
                  nomineeRelationship:
                    NomineeRelations[nominee?.Relationship || ''] === NomineeRelations.others
                      ? nominee.nomineeRelationship
                      : nominee?.Relationship,
                })),
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete nominees ?'}
            open={nomineeToDelete !== null || bulkNomineeDelete !== false}
            setOpen={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedApplicants = values.nominees.map((nominee, i) => {
                  if (nominee.sno === nomineeToDelete?.index || bulkNomineeDelete) {
                    return {
                      ...nominee,
                      isActive: false,
                    };
                  }
                  return nominee;
                });
                setValues({
                  ...values,
                  nominees: remainedApplicants,
                  doNotWishToNominate:
                    remainedApplicants.filter((item) => item.isActive).length === 0
                      ? true
                      : values.doNotWishToNominate,
                });
                setBulkNomineeDelete(false);
                setNomineeToDelete(null);
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => {
              setBulkNomineeDelete(false);
              setNomineeToDelete(null);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}
