import { Button, Dialog, Hidden, IconButton, Stack, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import { DataTable } from '../../DataTable';
import { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmationDialog } from '../../commonComponents';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { FeeSlabsType } from '../../../redux-store/types/api-types';
import { getFeeSlabList, updateFeeSlabById } from '../../../redux-store/actions/strategies';

export const AccessHeaderForFund = (): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 14,
            color: 'primary.main',
            fontWeight: 500,
          }}
          component="span">
          Access
          <InfoOutlinedIcon
            color="info"
            fontSize="small"
            onClick={handleOpen}
            sx={{ position: 'relative', top: 4 }}
          />
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 12,
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Enable/Disable
        </Typography>
      </Box>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 550,
            p: { xs: 2, sm: '15px 20px 45px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box sx={{ mb: 2, textAlign: 'center', '.MuiSvgIcon-root': { fontSize: '3.1875rem' } }}>
          <InfoOutlinedIcon color="info" fontSize="large" />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '80%' },
            mx: 'auto',
            '.MuiTypography-root': {
              fontSize: 20,
              fontWeight: 500,
              textAlign: 'center',
            },
          }}>
          <Typography sx={{ color: 'secondary.main', lineHeight: '35px', mt: 2 }}>
            Please ensure that you have at least one active Fee Slab before disabling the Fee Slab.
          </Typography>
        </Box>
      </Dialog>
    </>
  );
};

export default function FeeSlabsList(): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [feeSlabData, setFeeSlabData] = useState<FeeSlabsType[]>();
  const [openDialog, setDialog] = useState<{ FeeSlabData: FeeSlabsType } | null>(null);
  const history = useHistory();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(getFeeSlabList())) as unknown as FeeSlabsType[];
        if (!isComponentActive) {
          return;
        }
        setFeeSlabData(res);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const AccessViewOrEditCell = [
    {
      header: AccessHeaderForFund,
      renderCell: (row: FeeSlabsType): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ FeeSlabData: row })}
                // disabled={!access}
              />
            </Stack>
          </>
        );
      },
    },
  ];

  const columns: any = [
    {
      header: 'Fee Slab Name',
      renderCell: (row: FeeSlabsType) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{ pathname: 'fee-slab-details', state: { feeSlabsId: row.id } }}>
            {row.feeName || 'N/A'}
          </Typography>
        );
      },
    },
  ];

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 25 }, fontWeight: 500 }}>Fee Slabs</Typography>
          <Hidden only="xs">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                color: 'white',
                fontWeight: 500,
                fontSize: 14,
                mr: 2.5,
                py: 0.5,
                px: 3,
              }}
              onClick={() => history.push('add-fee-slab')}>
              Add Fee Slab
            </Button>
          </Hidden>
          <Hidden smUp={true}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 13,
                py: 0.5,
              }}
              onClick={() => history.push('add-fee-slab')}>
              Add Fee Slab
            </Button>
          </Hidden>
        </Box>
        {useMemo(
          () => (
            <DataTable
              tableData={feeSlabData?.length ? feeSlabData : []}
              tableHeader={[...columns, ...AccessViewOrEditCell]}
              currentPage={currentPage}
              noOfPages={1}
              isLoading={isLoading}
              paginationAction={handlePageChange}
              renderAdditionalRow={true}
              tableBodyCustomStyles={{
                '.MuiTableCell-root': {
                  borderBottom: 'none',
                },
              }}
              tableHeaderCustomStyles={{
                '&:last-child th': { border: 0 },
                '.MuiTableCell-head': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 14,
                  fontWeight: 500,
                  color: 'primary.main',
                },
              }}
            />
          ),
          [columns, isLoading]
        )}
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.FeeSlabData.isActive ? 'disable' : 'enable'
        } this Fee Slabs ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedRes = (await dispatch(
                updateFeeSlabById(Number(openDialog.FeeSlabData.id), {
                  ...openDialog.FeeSlabData,
                  isActive: !openDialog?.FeeSlabData.isActive,
                })
              )) as unknown as FeeSlabsType;
              setFeeSlabData(
                feeSlabData?.map((fund) => {
                  if (Number(fund.id) === Number(updatedRes.id)) {
                    return {
                      ...fund,
                      ...updatedRes,
                    };
                  }
                  return fund;
                }) as FeeSlabsType[]
              );
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
