import { Box, Button, CardMedia, Typography } from '@mui/material';
import { ConsentEmailLayout } from '../ConsentEmailComponent';
import { useGeolocated } from 'react-geolocated';
import PlaceIcon from '@mui/icons-material/Place';
import { LoadingButton } from '@mui/lab';
import { useHistory, useRouteMatch } from 'react-router';
import { Location } from 'history';
import { useEffect } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { useSelector } from 'react-redux';
import { checkPathForAuthorizedSignatory } from '../Investments/investor-details';

function LocationCapture({
  location,
}: {
  location: Location<{
    applicantId: string;
  }>;
}): JSX.Element {
  const { applicantId = '' } = location.state || {};
  const history = useHistory();
  const { investor } = useSelector((store: RootStateType) => store);
  const { params, path } = useRouteMatch();
  const { referenceId } = params as unknown as { referenceId: string };
  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled, positionError } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      //   userDecisionTimeout: 5000,
    });

  useEffect(() => {
    const { token } = investor;
    if (!token) {
      history.push(
        checkPathForAuthorizedSignatory(path)
          ? `/authorized-signatories-investment-details/${referenceId}/strategy-details`
          : `/investment-details/${referenceId}/details`
      );
    }
  }, []);

  return (
    <ConsentEmailLayout
      sx={{
        bgcolor: 'grey.50',
        height: { xs: 'unset', sm: '370px' },
        width: 'clamp(300px, 70%, 470px)',
        px: { xs: '25px', sm: '50px' },
        py: '50px',
        mb: 2,
        borderRadius: '10px',
        boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
      }}>
      <>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            textAlign: 'center',
          }}>
          Capturing GPS Location
        </Typography>
        {!isGeolocationAvailable ? (
          <>
            <Box sx={{ mt: 3, mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/warning-icon-red.svg"
                sx={{ width: 'unset', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                '.MuiInputLabel-root': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 16,
                  fontWeight: 500,
                },
              }}
              mt={5}>
              <Typography
                sx={{
                  fontSize: 18,
                  textAlign: 'center',
                }}>
                Your browser does not support Geolocation.
              </Typography>
            </Box>
          </>
        ) : !isGeolocationEnabled && positionError ? (
          <>
            <Box sx={{ mt: 5, mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/access_denied.svg"
                sx={{ width: '60px', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                '.MuiInputLabel-root': {
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: 16,
                  fontWeight: 500,
                },
                textAlign: 'center',
              }}
              mt={4}>
              <Typography
                sx={{
                  fontSize: 18,
                }}>
                {/* {positionError.message
                  ? positionError.message
                  : 'Geolocation is not enabled. please allow the access and refresh the page'} */}
                Location is turned off.Please enable access and click the Refresh button.
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  window.location.reload();
                }}
                sx={{ px: 3, mt: 3, pt: '6px' }}>
                Refresh
              </Button>
            </Box>
          </>
        ) : (
          !coords && (
            <Box
              sx={{
                mt: 5,
                mb: 2,
                textAlign: 'center',
                '.MuiSvgIcon-root': {
                  fontSize: '5.5rem',
                  color: '#2057A6',
                },
              }}>
              <PlaceIcon />
            </Box>
          )
        )}
        {coords && (
          <>
            <Box sx={{ fontSize: 18, textAlign: 'center', my: 8 }}>
              <Typography>Latitude :{coords.latitude}</Typography>
              <Typography>Longitude :{coords.longitude}</Typography>
            </Box>
            <LoadingButton
              loadingPosition="start"
              // type="submit"
              //   loading={loadingApprove}
              variant="contained"
              sx={{
                color: 'common.white',
                lineHeight: 1.5,
                fontSize: 14,
                // mx: '10px',
              }}
              fullWidth={true}
              onClick={() => {
                history.push(`photo-capture`, {
                  applicantId,
                  geolocation: `${coords.latitude},${coords.longitude}`,
                });
              }}>
              Proceed
            </LoadingButton>
          </>
        )}
      </>
    </ConsentEmailLayout>
  );
}

export default LocationCapture;
