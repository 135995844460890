import { AnyAction } from 'redux';
import { GetOtpForInvestorResponse, LoginWithOtpResponseBody } from '../types/api-types';
import {
  AuthState,
  GET_USER_INFO_SUCCESS,
  LOGIN_WITH_OTP_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFY_LOGIN_OTP_SUCCESS,
} from '../types/auth';
import { GET_OTP_SUCCESS, VERIFY_OTP_INVESTOR_SUCCESS } from '../types/onBoarding';

const initState: Partial<LoginWithOtpResponseBody | GetOtpForInvestorResponse> = {
  verificationToken: '',
  phone: '',
  email: '',
  countryCode: '',
  referenceId: '',
};
function userVerificationToken(
  state = initState,
  action: AnyAction
): Partial<LoginWithOtpResponseBody> {
  switch (action.type) {
    case LOGIN_WITH_OTP_SUCCESS:
    case GET_OTP_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case VERIFY_LOGIN_OTP_SUCCESS:
    case VERIFY_OTP_INVESTOR_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default userVerificationToken;
