import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  AddAmcAuthorisedSignatoryType,
  AMCAuthorisedSignatoryType,
} from '../../redux-store/types/api-types';
import { useDispatch } from 'react-redux';
import {
  addAMCAuthorisedSignatory,
  UpdateAMCAuthorisedSignatory,
  getAMCAuthorisedSignatoryById,
} from '../../redux-store/actions/userManagement';
import { TrusteeAndFundManagerAMCAuthorisedSchema } from '../../utils/schema';
import { Location } from 'history';

const initialValues: AddAmcAuthorisedSignatoryType = {
  name: '',
  email: '',
  phone: '',
  countryNameAndCode: 'India: +91',
  countryCode: '+91',
  panNumber: '',
  pincode: '',
  buildingNo: '',
  streetName: '',
  city: '',
  state: '',
  country: '',
  leegalityName: '',
  isActive: true,
};

export default function AddAMCAuthorisedSignatory({
  location,
}: {
  location: Location<{ amcAuthorisedSignatoryId: number }>;
}): JSX.Element {
  const { amcAuthorisedSignatoryId } = location.state || {};
  const history = useHistory();
  const dispatch = useDispatch();

  const [amcAuthorisedSignatoryDetails, setAmcAuthorisedSignatoryTypeDetails] =
    useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        if (amcAuthorisedSignatoryId) {
          const getAmcAuthorisedSignatoryTypeDetails = (await dispatch(
            getAMCAuthorisedSignatoryById(amcAuthorisedSignatoryId)
          )) as unknown as AMCAuthorisedSignatoryType;
          const {
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            isActive,
          } = getAmcAuthorisedSignatoryTypeDetails;
          const {
            firstName: name,
            phone,
            email,
            countryCode,
            countryNameAndCode,
          } = getAmcAuthorisedSignatoryTypeDetails.user;
          setAmcAuthorisedSignatoryTypeDetails({
            ...amcAuthorisedSignatoryDetails,
            name,
            pincode,
            buildingNo,
            streetName,
            city,
            state,
            country,
            panNumber,
            id,
            leegalityName,
            phone,
            email,
            isActive,
            countryNameAndCode: countryNameAndCode ? countryNameAndCode : 'India: +91',
            countryCode: countryCode ? countryCode : '+91',
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();

    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: AddAmcAuthorisedSignatoryType) => {
    try {
      if (!amcAuthorisedSignatoryId) {
        await dispatch(
          addAMCAuthorisedSignatory({
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('amc-authorised-signatory');
      } else {
        await dispatch(
          UpdateAMCAuthorisedSignatory(amcAuthorisedSignatoryId, {
            ...values,
            countryCode: values.countryNameAndCode?.split(':')[1].trim(),
          })
        );
        history.push('amc-authorised-signatory-details', { amcAuthorisedSignatoryId });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
    //setFormikValues({ ...values, countryCode: values.countryNameAndCode?.split(':')[1].trim() });
  };

  return (
    <>
      <Formik
        initialValues={amcAuthorisedSignatoryDetails}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={TrusteeAndFundManagerAMCAuthorisedSchema}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    amcAuthorisedSignatoryId
                      ? history.push('amc-authorised-signatory-details', {
                          amcAuthorisedSignatoryId: values.id,
                        })
                      : history.push('amc-authorised-signatory')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    amcAuthorisedSignatoryId
                      ? history.push('amc-authorised-signatory-details', {
                          amcAuthorisedSignatoryId: values.id,
                        })
                      : history.push('amc-authorised-signatory')
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {amcAuthorisedSignatoryId
                  ? 'AMC Authorized Signatory'
                  : 'Onboarding AMC Authorized Signatory'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={4} pt={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="name"
                      label="Name (Individual) *"
                      placeholder="Enter AMC Authorized Signatory Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="leegalityName"
                      label="Leegality Name *"
                      placeholder="Enter Leegality Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="panNumber"
                      label="Pan"
                      placeholder="Enter Pan "
                      onChange={(e) => {
                        setFieldValue('panNumber', e.target.value.toUpperCase());
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="email" label="Email Id *" placeholder="Enter Email Id" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="phone"
                      label="Mobile Number *"
                      placeholder="Enter Mobile Number"
                      // startAdornment={
                      //   <CountryCodesDropDown
                      //     name={`countryNameAndCode`}
                      //     value={values.countryNameAndCode}
                      //   />
                      // }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="pincode" label="Pincode" placeholder="Enter Pincode" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="buildingNo"
                      label="Building Number"
                      placeholder="Enter Building Number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="streetName"
                      label="Street Name"
                      placeholder="Enter Street Name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="state" label="State" placeholder="Enter State" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="city" label="City" placeholder="Enter City" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="country" label="Country" placeholder="Enter Country" />
                  </Grid>
                </Grid>

                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
