import { Box, Grid, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  FundInvestStockMarket,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkEditiable,
  checkInitialApplicationStatusIsChecker,
  currencyConversion,
  getApplicantName,
  getDisableStatusForjointHolder,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory, useParams } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
import { DatePicker } from '../../lib/formik/DatePicker';
import { TextDatePicker } from '../../lib/DatePickerWithTextFeild';
import UseRadioGroup from '../../lib/formik/Radio';
import { showError } from '../../redux-store/actions/auth';

type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
  ownBorrowedFunds: string | null;
};

const initialValues: Values = {
  applicants: [
    {
      grossAnnualIncomeDate: null,
      netWorth: '',
      grossAnnualIncome: '',
      netWorthDate: null,
      politicallyExposedPersonStatus: 'Not Applicable',
      ckycNo: '',
    },
  ],
  ownBorrowedFunds: '',
  saveType: 'save and proceed',
};

const KYCDetails = ({
  applicant,
  index,
  value,
}: {
  applicant: string;
  index: number;
  value: any;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      {/* {applicant !== 'Third' && ( */}
      <>
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.grossAnnualIncome`}
            label="Gross Annual Income (in INR) *"
            items={Object.keys(grossAnnualMasters).map((grossIncome) => ({
              key: grossAnnualMasters[grossIncome],
              value: grossIncome,
            }))}
            disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextDatePicker
            label={'Gross Annual Income as on Date *'}
            inputLabelStyles={{
              transform: 'unset',
              fontSize: 14,
              fontWeight: 500,
              color: 'rgba(0,0,0,0.7)',
            }}
            placeholder={'Enter Net worth as on Date'}
            name={`applicants.${index}.grossAnnualIncomeDate`}
            disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
          />
        </Grid>
      </>
      {/* )} */}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.netWorth`}
          label="Networth (in INR) *"
          placeholder="Enter Net Worth (in INR)"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
        />
        {value.netWorth != 0 && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
            }}>
            {currencyConversion(value.netWorth)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Networth as on Date *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Net worth as on'}
          name={`applicants.${index}.netWorthDate`}
          disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
          disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number"
          placeholder={'Enter CKYC number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={checkEditiable(referenceDetail.applicant_type, role, index + 1)}
          // disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
        />
      </Grid>
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  useEffect(() => {
    const { applicants: exisitingApplicants = [] } = application || {};
    setkycDetails({
      ...kycDetails,
      ownBorrowedFunds: application?.ownBorrowedFunds || '',
      applicants: exisitingApplicants.map((applicant) => ({
        occupationDetails: applicant.occupationDetails || '',
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        ckycNo: applicant.ckycNo || '',
        netWorth: applicant.netWorth || '',
        grossAnnualIncomeDate: applicant.grossAnnualIncomeDate || null,
        netWorthDate: applicant.netWorthDate || null,
      })),
    });
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicants, saveType, ...rest } = values;
      const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
        ...applicant,
        ...applicants[index],
      }));
      const updatedPayload = {
        ...application,
        ...rest,
        ownBorrowedFunds: rest.ownBorrowedFunds,
      };
      const checkApplication = applicationComparison(
        { ...application },
        {
          ...updatedPayload,
          applicants: updatedApplicants,
          currentStep: !!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              applicants: updatedApplicants,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 4,
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId: referenceIdForSaveProceedRoute })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('fatca', { id, applicant1ReferenceId: referenceIdForSaveProceedRoute })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, KYCDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_applicant, index) => (
            <KYCDetails
              applicant={getApplicantName(index + 1)}
              value={_applicant}
              key={index}
              index={index}
            />
          ))}
          <Box sx={{ mt: 4 }}>
            <Typography sx={{ fontSize: 25, fontWeight: 500, color: '#131836', mb: 4 }}>
              Source of Investment
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
              Complete the funding source details by which the Holder(s) wish to fund this PMS
              investment.
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
              <Typography component="span" sx={{ fontWeight: 700, fontSize: 14, color: '#2057A6' }}>
                Note :{' '}
              </Typography>
              This section is required to be filled based on SEBI’s regulation pertaining to the
              prevention of money laundering act, 2002.
            </Typography>
            <UseRadioGroup
              formLabel="Do you intend to invest in the stock market with own Funds/Borrowed Funds? *"
              name="ownBorrowedFunds"
              items={Object.keys(FundInvestStockMarket).map((fundType) => ({
                label: FundInvestStockMarket[fundType],
                value: fundType,
              }))}
              disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
            />
          </Box>
          <ProceedSaveLater
            saveLater={() => setFieldValue('saveType', 'save for later')}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
