import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, StrategyDetailsType } from '../../redux-store/types/api-types';
import { currencyConversion } from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import SourceOfInvestment from './sourceOfInvestment';
import {
  strategyFeesMaster,
  strategyFeeTypeMaster,
  strategyPerformanceFeeTypeMaster,
  USER_ROLES,
} from '../../utils/constant';
import { SubHeading } from '../investors/components';
import { KeyValue } from './contributor';
import { Gridstyles } from '.';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';

export default function StrategyDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { applicationstrategydetails = [] } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const headers = [
    {
      header: 'Strategy',
      label: 'strategyName',
      valueGetter: (row: StrategyDetailsType) => row.strategyName || 'N/A',
    },
    {
      header: 'Fees',
      label: 'fees',
      valueGetter: (row: StrategyDetailsType) =>
        strategyFeesMaster[row?.strategyFees || ''] || 'N/A',
    },
  ];
  // const headers = [
  //   {
  //     header: 'Strategy',
  //     label: 'strategyName',
  //     valueGetter: (row: StrategyDetailsType) => row.strategyName || 'N/A',
  //   },
  //   {
  //     header: 'Management Fee Nature',
  //     label: 'managementFeeNature',
  //     valueGetter: (row: StrategyDetailsType) =>
  //       strategyFeeTypeMaster[row.managementFeeNature] || 'N/A',
  //   },
  //   {
  //     header: 'Management Fee',
  //     label: 'fee',
  //     valueGetter: (row: StrategyDetailsType) =>
  //       row.fee +
  //         (strategyFeeTypeMaster[row.managementFeeNature] === strategyFeeTypeMaster.flatOnAum
  //           ? ' %'
  //           : '') || 'N/A',
  //   },

  //   {
  //     header: 'Performance Fee Nature',
  //     label: 'performanceFeeNature',
  //     valueGetter: (row: StrategyDetailsType) =>
  //       strategyFeeTypeMaster[row.performanceFeeNature] || 'N/A',
  //   },
  //   {
  //     header: 'Performance Fee',
  //     label: 'performanceFee',
  //     valueGetter: (row: StrategyDetailsType) =>
  //       row.performanceFee +
  //         (strategyFeeTypeMaster[row.performanceFeeNature] === strategyFeeTypeMaster.flatOnAum
  //           ? ' %'
  //           : '') || 'N/A',
  //   },
  //   {
  //     header: 'Investment Amount',
  //     label: 'investmentAmount',
  //     renderCell: (row: StrategyDetailsType) => {
  //       return (
  //         <>
  //           <Typography
  //             sx={{
  //               fontWeight: 500,
  //               fontSize: 14,
  //             }}>
  //             {row.investmentAmount || 'N/A'}
  //           </Typography>
  //           {row.investmentAmount && (
  //             <Typography
  //               sx={{
  //                 fontWeight: 500,
  //                 fontSize: 14,
  //               }}>
  //               {currencyConversion(row.investmentAmount)}
  //             </Typography>
  //           )}
  //         </>
  //       );
  //     },
  //   },
  // ];
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Strategy Details
            </Typography>
            <React.Fragment>
              <Box sx={{ width: '100%' }}>
                {applicationstrategydetails?.length > 0 ? (
                  // <React.Fragment>
                  //   {applicationstrategydetails?.map((strategy: any, index: number) => (
                  //     <>
                  //       <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>
                  //         {strategy.strategyName}
                  //       </SubHeading>
                  //       <Gridstyles>
                  //         <Grid
                  //           item
                  //           container
                  //           pt={2}
                  //           pb={5}
                  //           rowSpacing={6}
                  //           columnSpacing={13}
                  //           direction="row"
                  //           justifyContent="space-between"
                  //           alignItems="flex-start">
                  //           <KeyValue
                  //             title="Management Fee Nature"
                  //             description={strategyFeeTypeMaster[strategy.managementFeeNature]}
                  //           />
                  //           <KeyValue
                  //             title="Management Fee"
                  //             description={
                  //               strategy.fee
                  //                 ? `${strategy.fee} ${
                  //                     strategyFeeTypeMaster[strategy.managementFeeNature] ===
                  //                     strategyFeeTypeMaster.flatOnAum
                  //                       ? '%'
                  //                       : ''
                  //                   }`
                  //                 : strategy.fee === 0
                  //                 ? '0'
                  //                 : ''
                  //             }
                  //           />
                  //           <KeyValue
                  //             title="Performance Fee Nature"
                  //             description={
                  //               strategyPerformanceFeeTypeMaster[strategy.performanceFeeNature]
                  //             }
                  //           />
                  //           <KeyValue
                  //             title="Performance Fee"
                  //             description={
                  //               strategy.performanceFee
                  //                 ? `${strategy.performanceFee} ${
                  //                     [
                  //                       strategyPerformanceFeeTypeMaster.without_catchup,
                  //                       strategyPerformanceFeeTypeMaster.with_catchup,
                  //                     ].includes(
                  //                       strategyPerformanceFeeTypeMaster[
                  //                         strategy.performanceFeeNature
                  //                       ]
                  //                     )
                  //                       ? '%'
                  //                       : ''
                  //                   }`
                  //                 : strategy.performanceFee === 0
                  //                 ? '0'
                  //                 : ''
                  //             }
                  //           />
                  //           <KeyValue
                  //             title="Exit Fee"
                  //             description={strategy.exitFee === 0 ? '0' : strategy.exitFee}
                  //           />

                  //           <KeyValue
                  //             title={' Investment Amount'}
                  //             description={strategy.investmentAmount}
                  //             amount={
                  //               strategy.investmentAmount
                  //                 ? currencyConversion(strategy.investmentAmount)
                  //                 : strategy.investmentAmount
                  //             }
                  //           />
                  //           <KeyValue title="Benchmark" description={strategy.benchmark} />
                  //         </Grid>
                  //       </Gridstyles>
                  //     </>
                  //   ))}
                  // </React.Fragment>
                  <Gridstyles>
                    <DataTable
                      tableData={applicationstrategydetails.sort((prev, curr) =>
                        Number(prev.strategyId) > Number(curr.strategyId) ? 1 : -1
                      )}
                      tableHeader={headers}
                      renderAdditionalRow={false}
                      tableHeaderCustomStyles={{
                        '.MuiTableCell-head': {
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'primary.main',
                          textAlign: 'left',
                          px: 2,
                          py: 1.5,
                          width: '30%',
                          bgcolor: '#F3F8FB',
                        },
                      }}
                      rowCustomStyles={{
                        '.MuiTableCell-root': {
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'rgba(19, 24, 54, 0.7)',
                          px: 2,
                          py: 1.5,
                          width: '30%',
                          overflowWrap: 'anywhere',
                          textAlign: 'left',
                          verticalAlign: 'top',
                        },
                        '&:nth-child(odd)': {
                          bgcolor: 'rgba(19, 24, 54, 0.02)',
                        },
                      }}
                      boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                    />
                  </Gridstyles>
                ) : (
                  <Grid
                    xs={12}
                    sm={12}
                    sx={{
                      fontSize: 18,
                      fontWeight: 600,
                      color: 'common.black',
                      textAlign: 'center',
                      my: 3,
                    }}>
                    No Strategy Details
                  </Grid>
                )}
              </Box>
            </React.Fragment>
          </Grid>

          {/* <Gridstyles> */}
          {/* <Box sx={{ ml: -2, mt: -2 }}> */}
          <SourceOfInvestment application={application} />
          {/* </Box> */}
          {/* </Gridstyles> */}
        </Box>
      </Box>
    </>
  );
}
