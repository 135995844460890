import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, Bank } from '../../redux-store/types/api-types';
import { KeyValue } from './contributor';
import { Gridstyles } from './index';
import { SubHeading } from '../investors/components';
import { FundInvestStockMarket, ModeOfTransaction } from '../../utils/constant';
import { currencyConversion } from '../../utils/utilityFunctions';

export default function SourceOfInvestment({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  return (
    <>
      {/* <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
      <Box sx={{ p: 2}}>*/}
      <Grid container sx={{ mt: 4, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
        <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
          Source of Investment
        </Typography>
        <React.Fragment>
          <Box sx={{ width: '100%' }}>
            {/* <SubHeading
              sx={{
                color: 'common.black',
                letterSpacing: 0.5 + 'px',
                padding: { xs: '10px 10px', sm: '10px 30px' },
              }}>
              Insider Information
            </SubHeading>
            <SubHeading
              sx={{
                color: '#293139',
                letterSpacing: 0.5 + 'px',
                bgcolor: 'transparent',
                fontWeight: 500,
                padding: { xs: '10px 10px', sm: '10px 30px' },
              }}>
              Fund Details
            </SubHeading> */}
            <Gridstyles>
              <Grid
                item
                container
                pt={2}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                {/* <KeyValue
                  title={'Investment Amount (in INR)'}
                  description={application.totalStrategyInvestmentAmount}
                /> */}
                {/* <KeyValue
                  title={'Total Investment Amount (in INR)'}
                  description={application.totalStrategyInvestmentAmount}
                  amount={
                    application.totalStrategyInvestmentAmount
                      ? currencyConversion(application.totalStrategyInvestmentAmount)
                      : application.totalStrategyInvestmentAmount
                  }
                /> */}
                <KeyValue
                  title={
                    'Do you intend to invest in the stock market with own Funds/Borrowed Funds?'
                  }
                  description={FundInvestStockMarket[application.ownBorrowedFunds || '']}
                />
                {/* <KeyValue
                  title={'What is the mode of transaction for this investment?'}
                  description={
                    application.modeOfPayment
                      ? application.modeOfPayment.includes(',')
                        ? application.modeOfPayment
                            ?.split(',')
                            ?.map((mode) => {
                              return ModeOfTransaction[mode];
                            })
                            ?.toString()
                        : ModeOfTransaction[application.modeOfPayment]
                      : application.modeOfPayment
                  }
                /> */}
              </Grid>
            </Gridstyles>
          </Box>
        </React.Fragment>
      </Grid>
      {/*</Box>
     </Box> */}
    </>
  );
}
