import { useEffect, useState } from 'react';
import { AppBar, Button, CardMedia, Divider, Grid, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailsWithReferenceId, getOtpForInvestor } from '../../redux-store/actions/onBoarding';
import {
  GetDetailsFromRefIDResponse,
  GetOtpForInvestorResponse,
} from '../../redux-store/types/api-types';
import { Footer, NavigationThrowErrorPopup } from '../commonComponents';
import { currencyConversion } from '../../utils/utilityFunctions';
import { newStrategy } from '../investors/strategyDetails';
import {
  updatedFeeSlabDetails,
  updatedStrategyDetails,
} from '../investors/strategyDetailsWithFeeSlabs';
import { RootStateType } from '../../redux-store/reducers';
import { OTPAlreadySent } from '../../redux-store/middleware';
import { logout } from '../../redux-store/actions/auth';

export const checkPathForAuthorizedSignatory = (path: string) => {
  return path?.split('/')[1] === 'authorized-signatories-investment-details';
};

export default function InvestmentDetails(): JSX.Element {
  const history = useHistory();
  const { params, path } = useRouteMatch();
  //   const [details, setDetails] = useState<GetDetailsFromRefIDResponse>({
  //     schemeName: '',
  //     planDescription: '',
  //     commitmentAmount: 0,
  //     contributionChequeAmount: 0,
  //     managementFeesRate: 0,
  //   });

  const [details, setDetails] = useState([newStrategy]);
  const [detail, setDetail] = useState({
    applicationfeeslab: {} as updatedFeeSlabDetails,
    applicationstrategydetail: {} as updatedStrategyDetails,
    investmentAmount: null as any,
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { referenceId } = params as unknown as { referenceId: string };
  const { userVerification } = useSelector((store: RootStateType) => store);
  const [errorOpen, setErrorOpen] = useState({ message: '', open: false });
  const handleErrorPopupClose = () => {
    setErrorOpen({ message: '', open: false });
  };
  const { investor } = useSelector((store: RootStateType) => store);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setIsLoading(true);
        if (investor.token) {
          await dispatch(logout());
        }
        const response = (await dispatch(
          getDetailsWithReferenceId(referenceId)
        )) as unknown as GetDetailsFromRefIDResponse;
        if (!isComponentAlive) return;
        setDetail({
          applicationfeeslab: response?.applicationfeeslab,
          applicationstrategydetail: response.applicationstrategydetail,
          investmentAmount: response.investmentAmount,
        });
        setDetails(response.applicationstrategydetails);
        // setDetails(data);
        setIsLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const getDetails = (
    heading: string,
    detail: string | number | null | undefined,
    key: string
  ): JSX.Element => {
    return (
      <Grid item xs={12} md={6}>
        <Typography gutterBottom>{heading}</Typography>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Typography variant="body2">{detail ? detail : '-'}</Typography>
            {['investmentAmount'].includes(key) && detail !== 0 && (
              <Typography variant="body1">{currencyConversion(detail)}</Typography>
            )}
          </>
        )}
      </Grid>
    );
  };

  const verifyUser = async () => {
    try {
      const response = (await dispatch(
        getOtpForInvestor(referenceId)
      )) as unknown as GetOtpForInvestorResponse;
      history.push(checkPathForAuthorizedSignatory(path) ? 'otp' : 'verify-otp', {
        ...(response || {}),
        mobile: (response || {}).phone,
        email: (response || {}).email,
        loginType: checkPathForAuthorizedSignatory(path) ? 'signatories' : 'investor',
        referenceId,
      });
    } catch (e) {
      if (e instanceof OTPAlreadySent) {
        setErrorOpen({
          message: (e as OTPAlreadySent).message,
          open: true,
        });
      }
      console.error((e as Error).message);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: { xs: '', sm: 'rgba(238, 244, 251, 0.5)' },
      }}>
      <AppBar position="static" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/motilal-logo.png"
            alt="Logo"
            sx={{ width: '120px', m: 3, my: 2.5 }}
          />
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
      <Box
        component="main"
        sx={{
          height: { xs: '', sm: 'calc(100vh - 131px)' },
          pt: { xs: '', sm: '30px' },
        }}>
        <Box
          sx={{
            bgcolor: 'common.white',
            height: { xs: '', sm: 'calc(100vh - 200px)' },
            overflowY: 'auto',
            width: 'clamp(300px, 90%, 1200px)',
            mx: 'auto',
            py: '40px',
            px: { xs: '30px', sm: '80px' },
            borderRadius: '10px',
            boxShadow: { xs: '', sm: '0px 4px 12px rgba(0, 0, 0, 0.05)' },
          }}>
          <Box sx={{ width: { xs: '100%', sm: '90%' }, mx: 'auto' }}>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                mb: 4,
                alignSelf: 'flex-start',
              }}>
              Strategy Details
            </Typography>
            <Box
              sx={{
                boxShadow: { xs: '', sm: '0px 0px 5px rgba(0, 0, 0, 0.2)' },
                borderRadius: '10px',
                p: '20px',
                my: 2,
              }}>
              {/* {details.map((detail) => ( */}
              <Grid
                container
                // rowSpacing={5}
                // columnSpacing={10}
                sx={{
                  '.MuiGrid-item': {
                    px: { xs: '', sm: '70px' },
                    pb: '20px',
                    pt: '10px',
                  },
                  '.MuiTypography-root': {
                    fontSize: 16,
                    letterSpacing: '0.05em',
                    '&.MuiTypography-body2 ': { fontWeight: 600 },
                  },
                }}
                // key={detail.id}
              >
                {/* <Grid sx={{ px: { xs: '', sm: '70px' }, py: '0px' }} xs={12}>
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                    {detail?.applicationstrategydetail?.strategyName}
                  </Typography>
                </Grid>
                <Grid sx={{ px: { xs: '', sm: '70px' }, py: '0px' }} xs={12}>
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                    {detail?.applicationfeeslab?.feeName}
                  </Typography>
                </Grid> */}
                {[{ heading: 'Strategy Name', key: 'strategyName' }].map(({ heading, key }) =>
                  getDetails(heading, detail?.applicationstrategydetail?.strategyName, key)
                )}
                {/* {[{ heading: 'Fee Slab Name', key: 'feeName' }].map(({ heading, key }) =>
                  getDetails(heading, detail?.applicationfeeslab?.feeName, key)
                )} */}

                {[{ heading: 'Total Investment Amount (in INR)', key: 'investmentAmount' }].map(
                  ({ heading, key }) => getDetails(heading, detail?.investmentAmount, key)
                )}
              </Grid>
              {/* ))} */}
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{
                  mt: 5,
                  fontSize: 14,
                  lineHeight: 1.5,
                  maxWidth: '300px',
                }}
                disabled={isLoading}
                onClick={verifyUser}>
                Verify
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
      <NavigationThrowErrorPopup
        handleClose={handleErrorPopupClose}
        errorOpen={errorOpen}
        onSave={() => {
          history.push(checkPathForAuthorizedSignatory(path) ? 'otp' : 'verify-otp', {
            ...(userVerification || {}),
            mobile: (userVerification || {}).phone,
            email: (userVerification || {}).email,
            loginType: checkPathForAuthorizedSignatory(path) ? 'signatories' : 'investor',
            referenceId,
          });
        }}
      />
    </Box>
  );
}
