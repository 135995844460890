import { CALL_API } from '../middleware';
import { FileType } from '../types/api-types';

const IMG_UPLOADED_SUCCESS = 'IMG_UPLOADED_SUCCESS';
const IMG_UPLOADED = 'IMG_UPLOADED';

const IMG_UPLOADED_GROUP_SIGNATORY_SUCCESS = 'IMG_UPLOADED_GROUP_SIGNATORY_SUCCESS';
const IMG_UPLOADED_GROUP_SIGNATORY = 'IMG_UPLOADED_GROUP_SIGNATORY';

export const uploadCapturedImg =
  (body: { applicantId: string; file: string }) =>
  async (dispatch: any): Promise<FileType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/upload-photo`,
        method: 'POST',
        types: [IMG_UPLOADED_SUCCESS, IMG_UPLOADED],
        body,
      },
    });
  };

export const uploadCapturedImgForAuthorizedSignatory =
  (body: { groupSignatoryId: string; file: string }) =>
  async (dispatch: any): Promise<FileType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/upload-group-signatory-photo`,
        method: 'POST',
        types: [IMG_UPLOADED_GROUP_SIGNATORY_SUCCESS, IMG_UPLOADED_GROUP_SIGNATORY],
        body,
      },
    });
  };
