import { AnyAction } from 'redux';
import { VERIFY_PHOTO_CAPTURE_OTP_INVESTOR_SUCCESS } from '../actions/photoLocationCapture';
import { LOGOUT_SUCCESS } from '../types/auth';
import { VERIFY_OTP_INVESTOR_SUCCESS } from '../types/onBoarding';

const initState: Partial<{ token: string | null }> = {
  token: null,
};
function investorReducer(state = initState, action: AnyAction): Partial<{ token: string | null }> {
  switch (action.type) {
    case VERIFY_OTP_INVESTOR_SUCCESS:
    case VERIFY_PHOTO_CAPTURE_OTP_INVESTOR_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case LOGOUT_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default investorReducer;
