import { string } from 'yup';
import { encodeBase64 } from '../../utils/utilityFunctions';
import { CALL_API } from '../middleware';
import { FileType, GetOtpForInvestorRequestBody, verifyInvestorResponse } from '../types/api-types';

const CAPTURE_LINK = 'CAPTURE_LINK';
const CAPTURE_LINK_SUCCESS = 'CAPTURE_LINK_SUCCESS';

const GET_ALL_PHOTOCAPTURE_APPLICATION_SUCCESS = 'GET_ALL_PHOTOCAPTURE_APPLICATION_SUCCESS';
const GET_ALL_PHOTOCAPTURE_APPLICATION = 'GET_ALL_PHOTOCAPTURE_APPLICATION';

const INVESTOR_LOGIN_PHOTO_CAPTURE_SUCCESS = 'INVESTOR_LOGIN_PHOTO_CAPTURE_SUCCESS';
const INVESTOR_LOGIN_PHOTO_CAPTURE = 'INVESTOR_LOGIN_PHOTO_CAPTURE';

const GET_PHOTO_CAPTURE_OTP_SUCCESS = 'GET_PHOTO_CAPTURE_OTP_SUCCESS';
const GET_PHOTO_CAPTURE_OTP = 'GET_PHOTO_CAPTURE_OTP';

export const VERIFY_PHOTO_CAPTURE_OTP_INVESTOR_SUCCESS =
  'VERIFY_PHOTO_CAPTURE_OTP_INVESTOR_SUCCESS';
const VERIFY_PHOTO_CAPTURE_OTP_INVESTOR = 'VERIFY_PHOTO_CAPTURE_OTP_INVESTOR';

const SEND_PHOTO_CAPTURE_LOCATION_SUCCESS = 'SEND_PHOTO_CAPTURE_LOCATION_SUCCESS';
const SEND_PHOTO_CAPTURE_LOCATION = 'SEND_PHOTO_CAPTURE_LOCATION';

const PHOTO_CAPTURE_HISTORY_SUCCESS = 'PHOTO_CAPTURE_HISTORY_SUCCESS';
const PHOTO_CAPTURE_HISTORY = 'PHOTO_CAPTURE_HISTORY';

const CAPTURE_GROUP_SIGNATORY_LINK_SUCCESS = 'CAPTURE_GROUP_SIGNATORY_LINK_SUCCESS';
const CAPTURE_GROUP_SIGNATORY_LINK = 'CAPTURE_GROUP_SIGNATORY_LINK';

const PHOTO_CAPTURE_GROUP_SIGNATORY_HISTORY_SUCCESS =
  'PHOTO_CAPTURE_GROUP_SIGNATORY_HISTORY_SUCCESS';
const PHOTO_CAPTURE_GROUP_SIGNATORY_HISTORY = 'PHOTO_CAPTURE_GROUP_SIGNATORY_HISTORY';

const SEND_GROUP_SIGNATORY_PHOTO_CAPTURE_LOCATION_SUCCESS =
  'SEND_GROUP_SIGNATORY_PHOTO_CAPTURE_LOCATION_SUCCESS';
const SEND_GROUP_SIGNATORY_PHOTO_CAPTURE_LOCATION = 'SEND_GROUP_SIGNATORY_PHOTO_CAPTURE_LOCATION';

export type PhotoCaptureDetails = {
  applicationNumber: string;
  name: string;
  email: string;
  photocaptureStatus: string;
  photocaptureLinkSentAt: string;
  referenceId: string;
  applicantId: string;
  status: string;
};

export type GetAllPhotoCaptureApplicantionsResponseBody = {
  photocaptures: PhotoCaptureDetails[];
  page: number;
  limit: number;
  pages: number;
  total: number;
};

export type GetAllPhotoCaptureApplicantsRequestBody = {
  search: string;
  photocaptureStatus: string;
  limit: number;
  page: number;
  orderBy: string;
  orderDirection: string;
};

export const getAllPhotoCaptureApplications =
  (params: Partial<GetAllPhotoCaptureApplicantsRequestBody>) =>
  async (dispatch: any): Promise<GetAllPhotoCaptureApplicantionsResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture`,
        method: 'GET',
        types: [GET_ALL_PHOTOCAPTURE_APPLICATION_SUCCESS, GET_ALL_PHOTOCAPTURE_APPLICATION],
        params: {
          limit: 10,
          ...params,
        },
      },
    });
  };

export const sendPhotoCaptureLink =
  (applicantId: string | number) =>
  async (
    dispatch: any
  ): Promise<{
    message: string;
  }> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/send-link/${applicantId}`,
        method: 'GET',
        types: [CAPTURE_LINK_SUCCESS, CAPTURE_LINK],
      },
    });
  };

export type InvestorLoginPhotoCaptureResponseBody = {
  countryCode: string;
  countryNameAndCode: string;
  phone: string;
  email: string;
};
export const InvestorLoginForPhotoCapture =
  (referenceId: string) =>
  async (dispatch: any): Promise<InvestorLoginPhotoCaptureResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/details/${referenceId}`,
        method: 'GET',
        types: [INVESTOR_LOGIN_PHOTO_CAPTURE_SUCCESS, INVESTOR_LOGIN_PHOTO_CAPTURE],
      },
    });
  };

export type GetPhotoCaptureOtpForInvestorResponse = {
  verificationToken: string;
  phone: string;
  countryCode: string;
  email: string;
};

export const getPhotoCaptureOtpForInvestor =
  (referenceId: string) =>
  async (dispatch: any): Promise<GetPhotoCaptureOtpForInvestorResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/send-otp/${referenceId}`,
        method: 'GET',
        types: [GET_PHOTO_CAPTURE_OTP_SUCCESS, GET_PHOTO_CAPTURE_OTP],
      },
    });
  };
export type VerifyPhotoCaptureOtpForInvestorResponse = {
  token: string;
  applicantId: string;
  applicationId: string;
};
export const verifyPhotoCaptureOtpForInvestor =
  (referenceId: string, body: GetOtpForInvestorRequestBody) =>
  async (dispatch: any): Promise<VerifyPhotoCaptureOtpForInvestorResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/verify-otp/${referenceId}`,
        method: 'POST',
        types: [VERIFY_PHOTO_CAPTURE_OTP_INVESTOR_SUCCESS, VERIFY_PHOTO_CAPTURE_OTP_INVESTOR],
        body: { payload: encodeBase64(JSON.stringify(body)) },
      },
    });
  };

export type LocationAndPhotoCaptureDataPayload = {
  applicantId?: string;
  referenceId: string;
  imageFileId: string;
  geolocation: string;
  ipAddress?: string;
  groupSignatoryId?: string;
};

export const sendLocationAndPhotoCaptureData =
  (body: LocationAndPhotoCaptureDataPayload) =>
  async (dispatch: any): Promise<{ message: string }> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture`,
        method: 'POST',
        types: [SEND_PHOTO_CAPTURE_LOCATION_SUCCESS, SEND_PHOTO_CAPTURE_LOCATION],
        body,
      },
    });
  };
export type photoCaptureHistoryResponse = {
  id: string;
  referenceId: string;
  imageFileId: string;
  realScore: string;
  spoofScore: string;
  geolocation: string;
  ipAddress: string;
  photocaptureStatus: string;
  photocaptureError: string;
  photocaptureLinkSentAt: string;
  photocaptureLinkSentBy: string;
  photocaptureLinkSentByRole: string;
  photocaptureLinkSentById: string;
  photocaptureFile: FileType;
  createdBy: string;
  updatedBy: string;
};

export const PhotoCaptureHistory =
  (referenceId: string) =>
  async (dispatch: any): Promise<InvestorLoginPhotoCaptureResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/${referenceId}/history`,
        method: 'GET',
        types: [PHOTO_CAPTURE_HISTORY_SUCCESS, PHOTO_CAPTURE_HISTORY],
      },
    });
  };

export const nonIndividualSendPhotoCaptureLink =
  (groupSignatoryId: string | number) =>
  async (
    dispatch: any
  ): Promise<{
    message: string;
  }> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/group-signatory/send-link/${groupSignatoryId}`,
        method: 'GET',
        types: [CAPTURE_GROUP_SIGNATORY_LINK_SUCCESS, CAPTURE_GROUP_SIGNATORY_LINK],
      },
    });
  };

export const SignatoriesPhotoCaptureHistory =
  (referenceId: string) =>
  async (dispatch: any): Promise<InvestorLoginPhotoCaptureResponseBody[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/group-signatory/${referenceId}/history`,
        method: 'GET',
        types: [
          PHOTO_CAPTURE_GROUP_SIGNATORY_HISTORY_SUCCESS,
          PHOTO_CAPTURE_GROUP_SIGNATORY_HISTORY,
        ],
      },
    });
  };

export const sendAuthorizedSignatoriesLocationAndPhotoCaptureData =
  (body: LocationAndPhotoCaptureDataPayload) =>
  async (dispatch: any): Promise<{ message: string }> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/photocapture/group-signatory`,
        method: 'POST',
        types: [
          SEND_GROUP_SIGNATORY_PHOTO_CAPTURE_LOCATION_SUCCESS,
          SEND_GROUP_SIGNATORY_PHOTO_CAPTURE_LOCATION,
        ],
        body,
      },
    });
  };
