import { AnyAction } from 'redux';
import { getAuthorizedSignatoriesDetailsByRefrenceType } from '../types/api-types';
import { LOGOUT_SUCCESS } from '../types/auth';
import {
  GET_AUTHORISEDSIGNATORYDETAILS_BY_REFRENCE_SUCCESS,
  GET_OTP_SUCCESS,
} from '../types/onBoarding';

const initState: Partial<getAuthorizedSignatoriesDetailsByRefrenceType> = {
  referenceId: '',
  esignReferenceId: '',
  id: '',
};

function authorizedSignatoriesRefrenceIdReducer(
  state = initState,
  action: AnyAction
): Partial<getAuthorizedSignatoriesDetailsByRefrenceType> {
  switch (action.type) {
    case GET_AUTHORISEDSIGNATORYDETAILS_BY_REFRENCE_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case LOGOUT_SUCCESS:
    case GET_OTP_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default authorizedSignatoriesRefrenceIdReducer;
