import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Grid,
  Hidden,
  LinearProgress,
  Button,
  CardMedia,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../../investors/components';
import { DistributorListType, FeeSlabsType, FeeType } from '../../../redux-store/types/api-types';
import { Location } from 'history';
import UseRadioGroup from '../../../lib/formik/Radio';
import AddIcon from '@mui/icons-material/Add';
import { getDistributorsListForFilters } from '../../../redux-store/actions';
import { MultipleSelect } from '../../../lib/formik/MultipleSelectField';
import { debitingFreqMaster, FeeTypeMaster, YearEndMaster } from '../../../utils/constant';
import MFCheckbox from '../../../lib/formik/Checkbox';
import {
  addFeeSlab,
  getFeeSlabDetailById,
  updateFeeSlabById,
} from '../../../redux-store/actions/strategies';
import { addFeeSlabValidation } from '../../../utils/schema';
import { allowOnlyNumbers, checkFeeTypeForHuddleRate } from '../../../utils/utilityFunctions';
import MFSelectField from '../../../lib/formik/SelectField';
import { showError } from '../../../redux-store/actions/auth';

export type updatedhurdleratesType = {
  sno: number;
  isActive: boolean;
  from?: string | null | undefined;
  to?: string | null | undefined;
  profit: string | null;
};

export type updatedFeeSlabsType = {
  hurdlerates: updatedhurdleratesType[];
} & FeeSlabsType;

const hurdlerates: updatedhurdleratesType = {
  profit: '',
  to: '',
  from: '',
  sno: 0,
  isActive: true,
};

const exitLoads = [
  {
    from: '0',
    to: '365',
    frequency: '1 Year',
    exitload: '',
  },
  {
    from: '366',
    to: '730',
    frequency: '2 Years',
    exitload: '',
  },
  {
    from: '731',
    to: '1095',
    frequency: '3 Years',
    exitload: '',
  },
  {
    from: '1095',
    to: 'more',
    frequency: '> 3 Years',
    exitload: '',
  },
];

const initialValues: updatedFeeSlabsType = {
  id: '',
  exitload: exitLoads,
  debitingFrequency: '',
  feeName: '',
  hurdlerates: [],
  isActive: true,
  mgmtFee: '',
  optFee: '',
  yearEnd: '',
  feeType: '',
  distributorDetails: {
    distributorIds: [],
    distributors: [],
  },
  distributorIds: [],
  isApplicableForDirect: null,
  isApplicableForDistributor: null,
  feeSlabId: '',
  feeSlabCode: '',
};

export default function AddFeeSlab({
  location,
}: {
  location: Location<{ feeSlabsId: number }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [editField, setEditField] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { feeSlabsId } = location.state || {};
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const res = (await dispatch(
          getDistributorsListForFilters({ isActive: true })
        )) as unknown as DistributorListType[];
        setDistributorList(res);
        if (feeSlabsId) {
          const GetFeeSlabDetail = (await dispatch(
            getFeeSlabDetailById(feeSlabsId.toString())
          )) as unknown as updatedFeeSlabsType;
          setEditField({
            ...editField,
            ...GetFeeSlabDetail,
            distributorIds: GetFeeSlabDetail?.distributorDetails?.distributorIds?.length
              ? GetFeeSlabDetail?.distributorDetails?.distributorIds?.map((dis) => Number(dis))
              : [],
            exitload: GetFeeSlabDetail.exitload?.length
              ? GetFeeSlabDetail.exitload.map((_exit) => {
                  const getExitLoad = exitLoads.find(
                    (_load) => _load.from === _exit.from && _load.to === _exit.to
                  );
                  if (getExitLoad) {
                    return {
                      ..._exit,
                      frequency: getExitLoad.frequency,
                    };
                  }
                  return _exit;
                })
              : exitLoads,
            isApplicableForDistributor:
              GetFeeSlabDetail?.distributorDetails &&
              GetFeeSlabDetail?.distributorDetails?.distributorIds?.length > 0
                ? true
                : false,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: FeeSlabsType) => {
    try {
      if (!values.isApplicableForDirect && !values.isApplicableForDistributor) {
        throw 'Please select Direct or Distributor';
      }
      // eslint-disable-next-line
      const { distributorDetails, ...rest } = values;
      if (feeSlabsId) {
        await dispatch(
          updateFeeSlabById(feeSlabsId, {
            ...rest,
            id: Number(feeSlabsId),
            hurdlerates: checkFeeTypeForHuddleRate(rest?.feeType) ? rest.hurdlerates : null,
            distributorIds: rest.isApplicableForDistributor ? rest.distributorIds : [],
          })
        );
      } else {
        (await dispatch(
          addFeeSlab({
            ...rest,
            id: null,
            distributorIds: rest.isApplicableForDistributor ? rest.distributorIds : [],
            hurdlerates: checkFeeTypeForHuddleRate(rest?.feeType) ? rest.hurdlerates : null,
          })
        )) as unknown as FeeSlabsType[];
      }
      history.push(feeSlabsId ? 'fee-slab-details' : 'fee-slab-list', { feeSlabsId: feeSlabsId });
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={editField}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addFeeSlabValidation(
          distributorList.map((distributor) => Number(distributor.id))
        )}>
        {({ handleSubmit, values, setValues }) => (
          <Box
            sx={{
              bgcolor: 'white',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              py: { xs: 2, sm: 5 },
              pl: { xs: 0, sm: 5 },
              mt: { xs: 2, sm: 5 },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(feeSlabsId ? 'fee-slab-details' : 'fee-slab-list', {
                      feeSlabsId: feeSlabsId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fee Slabs
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(feeSlabsId ? 'fee-slab-details' : 'fee-slab-list', {
                      feeSlabsId: feeSlabsId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {feeSlabsId ? 'Edit Fee Slab' : 'Add Fee Slab'}
              </Typography>
            </Box>
            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField name="feeName" label="Fee Name *" placeholder="Enter Fee Name" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="optFee"
                      label="Operating Expenses *"
                      placeholder="Enter Operating Expenses"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="debitingFrequency"
                      label="Fee debiting Frequency *"
                      items={Object.keys(debitingFreqMaster).map((status) => ({
                        key: debitingFreqMaster[status],
                        value: status,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFSelectField
                      name="yearEnd"
                      label="Year End *"
                      items={Object.keys(YearEndMaster).map((status) => ({
                        key: YearEndMaster[status],
                        value: status,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="mgmtFee"
                      label="Management Fee *"
                      placeholder="Enter Management Fee"
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="feeSlabId"
                      label="Fee Slab Id *"
                      placeholder="Enter Fee Slab Id"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="feeSlabCode"
                      label="Fee Slab Code *"
                      placeholder="Enter Fee Slab Code"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} sx={{ mt: 2 }}>
                    <UseRadioGroup
                      formLabel="Fee Type *"
                      name={`feeType`}
                      labelColor="rgba(0,0,0,0.7)"
                      items={FeeTypeMaster}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          feeType: e.target.value as FeeType,
                          hurdlerates: checkFeeTypeForHuddleRate(values?.feeType)
                            ? values.hurdlerates
                            : [hurdlerates],
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 6 }}>
                    <Box sx={{ display: 'flex' }}>
                      <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
                      <MFCheckbox
                        name={`isApplicableForDistributor`}
                        label="Distributor"
                        onChange={({ target: { checked } }) => {
                          setValues({
                            ...values,
                            isApplicableForDistributor: checked,
                            distributorIds: [],
                          });
                        }}
                        // checked={values.isApplicableForDistributor || false}
                      />
                    </Box>
                  </Grid>
                  {values.isApplicableForDistributor && (
                    <Grid item xs={12} sm={12} md={12}>
                      <MultipleSelect
                        name="distributorIds"
                        label="Applicable Distributor(s) *"
                        items={distributorList.map((item) => ({
                          key: item.id,
                          value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
                        }))}
                        renderText="Selected Distributor(s)"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                      // backgroundColor: 'red',
                    }}>
                    {'Exit Load'}
                  </SubHeading>
                  {exitLoads.map((exit, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <MFTextField
                          name={`exitload.${index}.exitload`}
                          label={`${exit.frequency} *`}
                          placeholder="Enter Exit Load"
                          type="number"
                          onKeyDown={(e) => {
                            allowOnlyNumbers(e);
                          }}
                          trimOnBlur={false}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
                {/* {console.log(errors)} */}
                {checkFeeTypeForHuddleRate(values?.feeType) && (
                  <>
                    {values.hurdlerates &&
                      values.hurdlerates
                        .filter((rate) => rate.isActive)
                        .map((rate, index) => (
                          <>
                            <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                              <SubHeading
                                sx={{
                                  color: 'common.blue',
                                  letterSpacing: 0.5 + 'px',
                                  padding: { xs: '10px 10px', sm: '10px 30px' },
                                  ml: { xs: 3.5 },
                                  // backgroundColor: 'red',
                                }}>
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                  }}>
                                  <Typography>{'Hurdle Rate ' + (index + 1)}</Typography>
                                  {index !== 0 &&
                                    values.hurdlerates?.filter((rate) => rate.isActive).length >
                                      1 && (
                                      <Box
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        onClick={() => {
                                          setValues({
                                            ...values,
                                            hurdlerates: values.hurdlerates?.map((_rate) => {
                                              return {
                                                ..._rate,
                                                isActive:
                                                  rate.sno === _rate.sno ? false : _rate.isActive,
                                              };
                                            }),
                                          });
                                        }}>
                                        <Typography
                                          sx={{
                                            color: 'rgba(196, 42, 51, 0.8)',
                                            // fontSize: 12,
                                            ml: 'auto',
                                            letterSpacing: '-0.2px',
                                            cursor: 'pointer',
                                          }}>
                                          Remove{' '}
                                        </Typography>
                                        <IconButton sx={{ p: '2px' }}>
                                          <CardMedia
                                            component="img"
                                            src="/images/delete-red.svg"
                                            alt="Delete Icon"
                                            sx={{ width: 'unset' }}
                                          />
                                        </IconButton>
                                      </Box>
                                    )}
                                </Typography>
                              </SubHeading>
                              <Grid item xs={12} sm={6} md={4}>
                                <MFTextField
                                  name={`hurdlerates.${index}.from`}
                                  label="From *"
                                  placeholder="Enter From"
                                  type="number"
                                  onKeyDown={(e) => {
                                    allowOnlyNumbers(e);
                                  }}
                                  trimOnBlur={false}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <MFTextField
                                  name={`hurdlerates.${index}.to`}
                                  label="To *"
                                  placeholder="Enter To"
                                />
                              </Grid>
                              {/* <Grid item xs={12} sm={6} md={4}>
                              <MFTextField
                                name="hurdlerates.fromAndTo"
                                label="From and To"
                                placeholder="Enter From and To"
                              />
                            </Grid> */}
                              <Grid item xs={12} sm={6} md={4}>
                                <MFTextField
                                  name={`hurdlerates.${index}.profit`}
                                  label="Profit *"
                                  placeholder="Enter Profit"
                                  type="number"
                                  onKeyDown={(e) => {
                                    allowOnlyNumbers(e);
                                  }}
                                  trimOnBlur={false}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ))}
                    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          sx={{
                            color: 'primary.main',
                            fontWeight: 500,
                            fontSize: 14,
                            mt: 2.5,
                            py: 0.7,
                            px: 3,
                            whiteSpace: 'nowrap',
                          }}
                          onClick={() => {
                            setValues({
                              ...values,
                              hurdlerates: [
                                ...values.hurdlerates,
                                { ...hurdlerates, sno: values.hurdlerates.length },
                              ],
                            });
                          }}>
                          Add Hurdle Rate
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                  <MFSubmitButton label="Save" />
                </Box>
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
