import { AnyAction } from 'redux';
import { LOGOUT_SUCCESS } from '../types/auth';
import { GET_APPLICATION_WITH_REF_ID_SUCCESS } from '../types/onBoarding';

export type InvestorEditErrorObj = {
  tabText: string;
  header: string;
  endPoint: string;
  errorMessage: string;
};

export type InvestorEditSectionProps = {
  investorEditObj: InvestorEditErrorObj[] | null;
};

export const initialState: InvestorEditSectionProps = {
  investorEditObj: null,
};

function investorEditSectionsReducer(
  state = initialState,
  action: AnyAction
): Partial<InvestorEditSectionProps> {
  switch (action.type) {
    case 'GET_INVESTOR_EDIT_SECTION': {
      return {
        ...state,
        investorEditObj: action.investorEditObj,
      };
    }
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export default investorEditSectionsReducer;
