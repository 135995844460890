import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ApplicationProps, IntrestedCompanytype } from '../../redux-store/types/api-types';
import { Gridstyles, Item, ItemDetails } from './index';
import { SubHeading } from '../investors/components';
import { DataTable } from '../DataTable';
import { YesNoMaster } from '../../utils/constant';
import { sectionFilledByInvestor } from '../../utils/declaration';
import { fieldValidateForInvestor } from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { companyType, mdmsCompaniesList } from '../../redux-store/types/mdms';
import { getCompanies } from '../../redux-store/actions/application';

export default function DisclosureOfInterest({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { interestedcompanies = [], isDisclosureOfInterestToBeFilledByInvestor } =
    application || {};
  const interestedCompanies = interestedcompanies?.map((companies, index) => ({
    ...companies,
    sno: index + 1,
  }));
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const dispatch = useDispatch();
  const [mdmsCompanyList, setMdmsCompanyList] = useState<mdmsCompaniesList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const companyList = (await dispatch(getCompanies())) as unknown as companyType;
        setMdmsCompanyList(companyList.companies);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const headers = [
    {
      header: 'No',
      label: 'no',
      valueGetter: (row: any) => row.sno,
    },
    {
      header:
        'Name of companies in which I am/we are interested directly (or) through my relatives',
      label: 'companyName',
      valueGetter: (row: IntrestedCompanytype) =>
        mdmsCompanyList.find((list) => list.symbol === row.companyName)?.companyName || 'N/A',
    },
    // {
    //   header: 'Nature of Interest',
    //   label: 'natureOfInterest',
    //   valueGetter: (row: IntrestedCompanytype) => row.natureOfInterest || 'N/A',
    // },
    // {
    //   header: 'No. of Shares Held',
    //   label: 'numberOfShares',
    //   valueGetter: (row: IntrestedCompanytype) =>
    //     row.numberOfShares === 0 ? 0 : row.numberOfShares || 'N/A',
    // },
    // {
    //   header: '%of Paid Up Capital',
    //   label: 'percentageofPaidUpCapital',
    //   valueGetter: (row: IntrestedCompanytype) =>
    //     row.percentageofPaidUpCapital === 0 ? 0 : row.percentageofPaidUpCapital || 'N/A',
    // },
  ];

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Disclosure of Interest
            </Typography>
            {isDisclosureOfInterestToBeFilledByInvestor && !referenceDetail.applicant_type && (
              <Box sx={{ width: '100%', pb: 3 }}>
                <Gridstyles>
                  <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                    {/* <Checkbox defaultChecked disabled={true} /> */}
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: 700,
                        color: 'rgba(0, 0, 0, 0.6)',
                        letterSpacing: '0.5px',
                      }}>
                      {sectionFilledByInvestor}
                    </Typography>
                  </Grid>
                </Gridstyles>
              </Box>
            )}
            {(application.interestedCompaniesCheck ||
              !isDisclosureOfInterestToBeFilledByInvestor ||
              (isDisclosureOfInterestToBeFilledByInvestor && referenceDetail.applicant_type)) && (
              <React.Fragment>
                <Box sx={{ width: '100%', pb: 3 }}>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    Insider Information
                  </SubHeading>

                  <Gridstyles>
                    <SubHeading
                      sx={{
                        color: '#293139',
                        letterSpacing: 0.5 + 'px',
                        bgcolor: 'transparent',
                        fontWeight: 500,
                        padding: { xs: '10px 10px', sm: '10px 30px' },
                      }}>
                      Interested Companies
                    </SubHeading>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      sx={{ pl: { xs: 0, sm: 3 } }}>
                      <Grid item xs={12}>
                        <Item>
                          Do you wish to disclose any investments where you may potentially be
                          identified as an insider?
                        </Item>
                        <ItemDetails sx={{ textTransform: 'capitalize' }}>
                          {application.interestedCompaniesCheck || '-'}
                        </ItemDetails>
                      </Grid>
                    </Grid>
                  </Gridstyles>
                  {YesNoMaster[application.interestedCompaniesCheck || ''] === YesNoMaster.yes ? (
                    interestedCompanies.length > 0 ? (
                      <Box sx={{ px: { xs: 0, sm: 3 } }}>
                        <DataTable
                          tableData={interestedCompanies}
                          tableHeader={headers}
                          renderAdditionalRow={false}
                          tableHeaderCustomStyles={{
                            '.MuiTableCell-head': {
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'primary.main',
                              textAlign: 'left',
                              px: 2,
                              py: 1.5,
                              bgcolor: '#F3F8FB',
                            },
                          }}
                          rowCustomStyles={{
                            '.MuiTableCell-root': {
                              fontFamily: 'Poppins, sans-serif',
                              fontSize: 14,
                              fontWeight: 500,
                              color: 'rgba(19, 24, 54, 0.7)',
                              px: 2,
                              py: 1.5,
                              overflowWrap: 'anywhere',
                              textAlign: 'left',
                              verticalAlign: 'top',
                              minWidth: 100,
                            },
                            '&:nth-child(odd)': {
                              bgcolor: 'rgba(19, 24, 54, 0.02)',
                            },
                          }}
                          boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                        />
                      </Box>
                    ) : (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Interested Companies
                      </Grid>
                    )
                  ) : (
                    <></>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
