import { Box, Button, FormHelperText, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { maskMobileNumber } from '../../utils/utilityFunctions';
import { useDispatch } from 'react-redux';
import { getUserInfo, verifyLoginOtp } from '../../redux-store/actions/auth';
import { useHistory } from 'react-router';
import { LoginLayout, OtpTimer } from '../commonComponents';
import {
  getAuthorizedSignatoriesDetailByReferenceId,
  getDetailByReferenceId,
  getOtpForInvestor,
  verifyOtpForInvestor,
} from '../../redux-store/actions/onBoarding';
import { Location } from 'history';
import { loginWithOtp } from '../../redux-store/actions/auth';
import {
  getAuthorizedSignatoriesDetailsByRefrenceType,
  getDetailsByRefrenceType,
  GetOtpForInvestorResponse,
  LoginWithOtpResponseBody,
} from '../../redux-store/types/api-types';
import { Formik } from 'formik';
import { MFSubmitButton } from '../../lib/formik';
import {
  getPhotoCaptureOtpForInvestor,
  GetPhotoCaptureOtpForInvestorResponse,
  PhotoCaptureHistory,
  photoCaptureHistoryResponse,
  SignatoriesPhotoCaptureHistory,
  verifyPhotoCaptureOtpForInvestor,
  VerifyPhotoCaptureOtpForInvestorResponse,
} from '../../redux-store/actions/photoLocationCapture';
import CustumOtpInput from '../../lib/otp';

const intialValues: { OTP: string } = {
  OTP: '',
};

export default function LoginOtp({
  location,
}: {
  location: Location<{
    mobile: string;
    phone: string;
    email: string;
    verificationToken: string;
    loginType: string;
    referenceId: string;
    countryCode: string;
  }>;
}): JSX.Element {
  const {
    mobile = '',
    loginType = '',
    referenceId,
    countryCode = '+91',
    phone = '',
    email = '',
  } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState<string>('');
  const [{ verificationToken }, setVerifyOtpInfo] = useState({ verificationToken: '' });
  const [viewOtp, setViewOtp] = useState(false);

  const handleSubmit = async (values: { OTP: string }) => {
    const { OTP } = values;
    try {
      if (!OTP || OTP.length !== 6) {
        setError('Enter OTP');
        return;
      }
      if (loginType === 'investor') {
        await dispatch(verifyOtpForInvestor(referenceId, { otp: OTP, verificationToken }));
        const details = (await dispatch(
          getDetailByReferenceId(referenceId)
        )) as unknown as getDetailsByRefrenceType;
        const response = (await dispatch(
          PhotoCaptureHistory(details.referenceId)
        )) as unknown as photoCaptureHistoryResponse[];
        await dispatch(getUserInfo());
        if (response[response.length - 1].photocaptureStatus === 'pending') {
          history.push(`location-capture`, { applicantId: details.id });
        } else {
          history.push(`application-details`);
        }
        return;
      }
      if (loginType === 'signatories') {
        await dispatch(verifyOtpForInvestor(referenceId, { otp: OTP, verificationToken }));
        const details = (await dispatch(
          getAuthorizedSignatoriesDetailByReferenceId(referenceId)
        )) as unknown as getAuthorizedSignatoriesDetailsByRefrenceType;
        const response = (await dispatch(
          SignatoriesPhotoCaptureHistory(details.referenceId)
        )) as unknown as photoCaptureHistoryResponse[];
        await dispatch(getUserInfo());
        if (response[response.length - 1].photocaptureStatus === 'pending') {
          history.push(`location-capture`);
        } else {
          history.push(`nonIndividual-application-details`);
        }
        return;
      }
      if (loginType === 'investor_photoCapture') {
        const loginResponse = (await dispatch(
          verifyPhotoCaptureOtpForInvestor(referenceId, { otp: OTP, verificationToken })
        )) as unknown as VerifyPhotoCaptureOtpForInvestorResponse;
        await dispatch(getUserInfo());
        history.push(`application-details`, {
          applicationId: loginResponse.applicationId,
          applicantId: loginResponse.applicantId,
        });
        return;
      }
      await dispatch(verifyLoginOtp({ phone: mobile, otp: OTP, verificationToken, countryCode }));
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    const { verificationToken } = location.state || {};
    if (!location.state) {
      history.push('/login');
    }
    setVerifyOtpInfo({ verificationToken });
  }, []);

  const onResend = async () => {
    try {
      if (['investor', 'signatories'].includes(loginType)) {
        const response = (await dispatch(
          getOtpForInvestor(referenceId)
        )) as unknown as GetOtpForInvestorResponse;
        setVerifyOtpInfo({ ...response });
        return;
      }
      if (loginType === 'investor_photoCapture') {
        const response = (await dispatch(
          getPhotoCaptureOtpForInvestor(referenceId)
        )) as unknown as GetPhotoCaptureOtpForInvestorResponse;
        setVerifyOtpInfo({ ...response });
        return;
      }
      const res = (await dispatch(
        loginWithOtp({ phone: mobile, countryCode })
      )) as unknown as LoginWithOtpResponseBody;
      setVerifyOtpInfo({ ...res });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <LoginLayout LoginText="Login with OTP">
      <Formik initialValues={intialValues} onSubmit={handleSubmit}>
        {({ values, setValues, handleSubmit }) => (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Typography sx={{ color: 'primary.main', textAlign: 'center', mb: 3.5 }}>
              An OTP has been sent to your mobile number
              <Typography component="span">{` ${phone} `}</Typography>
              {email && (
                <Typography component="span">
                  and email Id{' '}
                  <Typography component="span" sx={{ wordBreak: 'break-all' }}>
                    {' '}
                    {email}
                  </Typography>
                </Typography>
              )}
            </Typography>
            <Box
              sx={{
                mb: 0,
                '.otp-input': {
                  input: {
                    width: { xs: '37px !important', md: '45px !important' },
                    fontSize: { xs: '20px !important', md: '30px !important' },
                    margin: { xs: '2px !important', md: '4px !important' },
                  },
                },
              }}>
              <CustumOtpInput
                value={values.OTP}
                onChange={(OTP: string) => setValues({ OTP })}
                numInputs={6}
                hasErrored={!!error}
                isInputNum={true}
                isInputSecure={!viewOtp}
                shouldAutoFocus={true}
                containerStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
                inputStyle={{
                  padding: '5px 10px',
                  // width: '45px',
                  borderRadius: '5px',
                  fontSize: '30px',
                }}
                errorStyle={{ border: '2px solid red', color: 'red' }}
                focusStyle={{ outline: 'none' }}
                className="otp-input"
              />
              {!!error && (
                <FormHelperText error sx={{ mt: 2, textAlign: 'center', fontSize: '14px' }}>
                  {error}
                </FormHelperText>
              )}
            </Box>
            {values.OTP && (
              <Box
                sx={{
                  '.MuiTypography-root, .MuiButton-root': {
                    textAlign: 'center',
                  },
                }}>
                <Typography>
                  <Button
                    sx={{
                      color: 'primary.main',
                      fontSize: 16,
                      fontWeight: 500,
                      p: 0,
                      mt: 2,
                      ':hover': {
                        background: 'none',
                      },
                    }}
                    onClick={() => {
                      setViewOtp(!viewOtp);
                    }}>
                    {!viewOtp ? `View OTP` : `Hide OTP`}
                  </Button>
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                '.MuiTypography-root, .MuiButton-root': {
                  textAlign: 'center',
                },
              }}>
              <OtpTimer onResend={onResend} />
            </Box>
        
            <Box
              sx={{
                color: 'red',
                textAlign: 'center',
              }}>
              Note: User Account will be locked after 3 unsuccessful attempts, Resend otp can be
              requested 2 times for every hour
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <MFSubmitButton label="Submit" />
            </Box>
          </Box>
        )}
      </Formik>
    </LoginLayout>
  );
}
