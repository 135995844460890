/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Grid, Typography } from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../DataTable';
import { ProceedSaveLater, SubHeading } from './components';
import { MFTextField } from '../../lib/formik';
import UseRadioGroup from '../../lib/formik/Radio';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ConfirmationDialog } from '../commonComponents';
import { disclosureOfInterestSchema } from '../../utils/schema';
import { useDispatch, useSelector } from 'react-redux';
import { showError } from '../../redux-store/actions/auth';
import { useHistory, useParams } from 'react-router';
import { IntrestedCompanytype, InvestorEditRouteLinkObj } from '../../redux-store/types/api-types';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  checkInitialApplicationStatusIsChecker,
  checkIfApplicationIsNonIndividual,
  fieldValidateForInvestor,
  getDisableStatusForjointHolder,
  getInvestorRouteLinks,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { getCompanies, updateApplication } from '../../redux-store/actions/application';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  CompanyNameMasters,
  SHOW_SECTION_FILLED_BY_INVESTOR_CHECKBOX,
  USER_ROLES,
  YesNoMaster,
} from '../../utils/constant';
import { RootStateType } from '../../redux-store/reducers';
import { useSnackbar } from 'notistack';
import MFSelectField from '../../lib/formik/SelectField';
import MFCheckbox from '../../lib/formik/Checkbox';
import { sectionFilledByInvestor } from '../../utils/declaration';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { companyType, mdmsCompaniesList } from '../../redux-store/types/mdms';

type updatedCompanyType = IntrestedCompanytype & {
  sno?: number;
};

type Values = {
  isDisclosureOfInterestToBeFilledByInvestor: boolean;
  interestedCompaniesCheck: string;
  interestedcompanies: updatedCompanyType[];
  saveType: string;
};

const initialValues: Values = {
  isDisclosureOfInterestToBeFilledByInvestor: false,
  interestedCompaniesCheck: '',
  interestedcompanies: [],
  saveType: 'save and proceed',
};

const newInterestedCompany: updatedCompanyType = {
  isActive: true,
  companyName: '',
  natureOfInterest: '',
  numberOfShares: 0,
  percentageofPaidUpCapital: 0,
  sno: 0,
};

function DisclosureOfInterestTable({
  values,
  setDialogBox,
  mdmsCompanyList,
}: // setValues,
{
  values: Values;
  setDialogBox: any;
  mdmsCompanyList: mdmsCompaniesList[];
  // setValues: FormikHelpers<Values>['setValues'];
}): JSX.Element {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  const headers = [
    {
      header: 'No.',
      label: 'no',
      renderEditDeleteCell: (row: updatedCompanyType, index: number): JSX.Element => {
        return (
          <>
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{`${index + 1}`}</Typography>
          </>
        );
      },
    },
    {
      header:
        'Name of companies in which I am/we are interested directly (or) through my relatives',
      label:
        'Name of companies in which I am/we are interested directly (or) through my relatives                 ',
      renderEditDeleteCell: (row: updatedCompanyType, index: number): JSX.Element => {
        // return <MFTextField name={`interestedcompanies.${row.sno}.companyName`} placeholder="" />;
        return (
          // <MFSelectField
          //   name={`interestedcompanies.${row.sno}.companyName`}
          //   items={CompanyNameMasters}
          //   applyLabelStyles={true}
          //   sx={{ my: -1 }}
          //   disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
          // />
          <SearchableSelect
            name={`interestedcompanies.${row.sno}.companyName`}
            items={mdmsCompanyList.map((list) => ({
              key: list.companyName,
              value: list.symbol,
            }))}
            disabled={getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
            searchFieldPlaceholder={'Search Companies'}
            sx={{ my: -1 }}
            MenuProps={{
              sx: {
                '.MuiPaper-root ': {
                  maxWidth: { xs: '86%', sm: '22%', md: '18%' },
                  maxHeight: '20%',
                  overflowX: 'auto',
                },
                '@media only screen and (min-width: 401px) and (max-width: 600px)': {
                  '.MuiPaper-root': {
                    maxWidth: '40%',
                  },
                },
              },
              disableAutoFocusItem: true,
              MenuListProps: {
                disableListWrap: true,
              },
            }}
          />
        );
      },
    },
    // {
    //   header: 'Nature Of Interest',
    //   renderEditDeleteCell: (row: updatedCompanyType, index: number): JSX.Element => {
    //     return (
    //       <MFTextField name={`interestedcompanies.${row.sno}.natureOfInterest`} placeholder="" />
    //     );
    //   },
    // },
    // {
    //   header: `No. of Shares Helds`,
    //   renderEditDeleteCell: (row: updatedCompanyType, index: number): JSX.Element => {
    //     return (
    //       <MFTextField
    //         name={`interestedcompanies.${row.sno}.numberOfShares`}
    //         placeholder=""
    //         // type="number"
    //         // onKeyDown={(e) => {
    //         //   allowOnlyNumbers(e);
    //         // }}
    //         // trimOnBlur={false}
    //       />
    //     );
    //   },
    // },
    // {
    //   header: `% of Paid Up Capital`,
    //   renderEditDeleteCell: (row: updatedCompanyType, index: number): JSX.Element => {
    //     return (
    //       <MFTextField
    //         name={`interestedcompanies.${row.sno}.percentageofPaidUpCapital`}
    //         placeholder=""
    //         // type="number"
    //         // onKeyDown={(e) => {
    //         //   allowOnlyNumbers(e);
    //         // }}
    //         // trimOnBlur={false}
    //       />
    //     );
    //   },
    // },
    {
      header: '',
      renderEditDeleteCell: (row: updatedCompanyType, index: number): JSX.Element => {
        return (
          <>
            <DeleteOutlineIcon
              sx={{ cursor: 'pointer' }}
              color={
                values.interestedcompanies?.filter((company) => company.isActive).length > 1 ||
                !getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                  ? 'primary'
                  : 'disabled'
              }
              onClick={() => {
                if (
                  values.interestedcompanies?.filter((company) => company.isActive).length > 1 ||
                  !getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                ) {
                  setDialogBox({ Index: row.sno });
                }
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <DataTable
      tableData={values.interestedcompanies?.filter((data) => data.isActive)}
      tableHeader={headers}
      renderAdditionalRow={false}
      tableHeaderCustomStyles={{
        '.MuiTableCell-head': {
          fontFamily: 'Poppins, sans-serif',
          fontSize: 14,
          fontWeight: 500,
          color: 'primary.main',
          textAlign: 'left',
          backgroundColor: '#F3F8FB',
          // pl: 6,
          '&:nth-of-type(2)': {
            pl: 2,
            width: '100%',
            // width: 300,
          },
          // '&:nth-of-type(3)': {
          //   pl: 3,
          //   width: 150,
          // },
          // '&:nth-of-type(4)': {
          //   pl: 3,
          //   width: 150,
          // },
          // '&:nth-of-type(5)': {
          //   pl: 3,
          //   width: 150,
          // },
        },
      }}
      rowCustomStyles={{
        '.MuiTableCell-root': {
          overflowWrap: 'anywhere',
          textAlign: 'left',
          verticalAlign: 'top',
          py: 2,
          '& .MuiFormControl-root': {
            m: 0,
          },
          '&:nth-of-type(2)': {
            pl: 0,
            width: 300,
          },
        },
      }}
      boxStyles={{ minHeight: '0', minWidth: 450 }}
    />
  );
}

export default function DisclosureOfInterest(): JSX.Element {
  const [disclosureData, setDisclosureData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [dialogbox, setDialogBox] = useState<{
    Index?: number;
  } | null>(null);
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { esignReferenceId } = referenceDetail;
  const { enqueueSnackbar } = useSnackbar();
  const [openPopup, setOpenPopup] = useState<{ disclosureOfInterestsCheck: boolean } | null>(null);
  const { investorEditObj } = useSelector((store: RootStateType) => store.investorEditObj);
  const [investorRouteObj, setInvestorRouteObj] = useState<InvestorEditRouteLinkObj | null>(null);
  const [openPreviewConfirmationPopup, setOpenPreviewConfirmationPopup] = useState(false);
  const [mdmsCompanyList, setMdmsCompanyList] = useState<mdmsCompaniesList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        const companyList = (await dispatch(getCompanies())) as unknown as companyType;
        setMdmsCompanyList(companyList.companies);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);
  useEffect(() => {
    setDisclosureData({
      ...disclosureData,
      isDisclosureOfInterestToBeFilledByInvestor:
        application?.isDisclosureOfInterestToBeFilledByInvestor || false,
      interestedCompaniesCheck: application?.interestedCompaniesCheck || '',
      interestedcompanies: application?.interestedcompanies.length
        ? application.interestedcompanies?.map((interestedCompanies, index) => {
            return { ...interestedCompanies, sno: index };
          })
        : [],
    });
  }, [application]);

  useEffect(() => {
    const routeLinkObj =
      investorEditObj && investorEditObj?.length > 0
        ? getInvestorRouteLinks(
            investorEditObj,
            history?.location?.pathname?.split('/')?.pop() || ''
          )
        : null;
    setInvestorRouteObj(routeLinkObj);
  }, [investorEditObj]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { interestedcompanies, saveType, ...rest } = values;

      const isSaveLater = saveType !== 'save and proceed';

      const updatedPayload = {
        ...application,
        ...rest,
        interestedcompanies:
          YesNoMaster[rest.interestedCompaniesCheck] === YesNoMaster.yes
            ? interestedcompanies
                ?.filter((company) => company?.id || company.isActive)
                ?.map((companiesData) => {
                  const { sno, ...rest } = companiesData;
                  return {
                    ...rest,
                    numberOfShares: rest.numberOfShares
                      ? Number(rest.numberOfShares)
                      : rest.numberOfShares?.toString() === '0'
                      ? 0
                      : null,
                    percentageofPaidUpCapital: rest.percentageofPaidUpCapital
                      ? Number(rest.percentageofPaidUpCapital)
                      : rest.percentageofPaidUpCapital?.toString() === '0'
                      ? 0
                      : null,
                  };
                })
            : (interestedcompanies
                ?.map((interestedCompany) => {
                  if (interestedCompany.id) {
                    const { sno, ...rest } = interestedCompany;
                    return { ...rest, isActive: false };
                  }
                  return;
                })
                ?.filter((ele) => ele) as IntrestedCompanytype[]),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          interestedcompanies: application?.interestedcompanies?.sort(
            (company1, company2) => Number(company1.id) - Number(company2.id)
          ),
        },
        {
          ...updatedPayload,
          interestedcompanies: updatedPayload?.interestedcompanies?.sort(
            (company1, company2) => Number(company1.id) - Number(company2.id)
          ),
          currentStep: !!currentStep && currentStep > 9 ? currentStep : Number(currentStep) + 1,
        }
      );

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: investorRouteObj ? currentStep : 10,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        setLoading(false);
        !isSaveLater
          ? investorRouteObj
            ? investorRouteObj.saveAndProceedSectionLink
              ? history.push(investorRouteObj.saveAndProceedSectionLink, {
                  id,
                  applicant1ReferenceId: referenceIdForSaveProceedRoute,
                })
              : setOpenPreviewConfirmationPopup(true)
            : history.push('disclosure-of-exclusions', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : investorRouteObj
          ? investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
            : history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/application-details`
              )
          : history.push(saveForLater(role, id, esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? investorRouteObj
            ? investorRouteObj.saveAndProceedSectionLink
              ? history.push(investorRouteObj.saveAndProceedSectionLink, {
                  id,
                  applicant1ReferenceId: referenceIdForSaveProceedRoute,
                })
              : setOpenPreviewConfirmationPopup(true)
            : history.push('disclosure-of-exclusions', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : investorRouteObj
          ? investorRouteObj.backSectionLink
            ? history.push(investorRouteObj.backSectionLink, {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
            : history.push(
                `/investment-details/${referenceDetail.esignReferenceId}/application-details`
              )
          : history.push(saveForLater(role, id, esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={disclosureData}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validate={(values: Values) => {
          try {
            validateYupSchema(
              values,
              disclosureOfInterestSchema(referenceDetail.applicant_type, mdmsCompanyList),
              true,
              values
            );
          } catch (e) {
            return yupToFormErrors(e);
          }
        }}>
        {({ handleSubmit, values, setValues, errors }) => (
          <>
            <Grid
              container
              rowSpacing={1}
              //columnSpacing={5}
              sx={{
                width: '100%',
                ml: 0,
                '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
              }}
              component="form"
              noValidate
              onSubmit={handleSubmit}>
              <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#2057A6', mb: 1 }}>
                Complete the following section by disclosing any investments where the Applicant may
                have an insider view of specific companies or identifying any corporates/sectors
                that need to be excluded from the portfolio.
              </Typography>
              <Typography
                component="h2"
                sx={{
                  fontWeight: 500,
                  fontSize: 25,
                  lineHeight: '38px',
                  color: '#0D2762',
                  mb: 2.5,
                  mt: 2.5,
                  fontFamily: 'Poppins,sans-serif',
                }}>
                Insider Information
              </Typography>
              {SHOW_SECTION_FILLED_BY_INVESTOR_CHECKBOX &&
                !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false) &&
                application &&
                !checkIfApplicationIsNonIndividual(application) && (
                  <Grid xs={12} sx={{ mb: 2 }}>
                    <MFCheckbox
                      name="isDisclosureOfInterestToBeFilledByInvestor"
                      label={sectionFilledByInvestor}
                      sx={{ display: 'flex' }}
                      onChange={({ target: { checked } }) => {
                        !checked &&
                          setValues({
                            ...values,
                            isDisclosureOfInterestToBeFilledByInvestor: checked,
                          });
                        checked && setOpenPopup({ disclosureOfInterestsCheck: checked });
                      }}
                      checked={values.isDisclosureOfInterestToBeFilledByInvestor}
                    />
                  </Grid>
                )}
              <SubHeading
                sx={{
                  color: '#131836',
                  fontSize: 18,
                  fontWeight: 500,
                  mt: 0,
                  mb: 5,
                }}>{`Interested Companies`}</SubHeading>
              <Grid xs={12} sm={12} lg={12} sx={{ mb: 2 }}>
                <UseRadioGroup
                  formLabel={`Do you wish to disclose any investments where you may potentially be identified as an insider? ${
                    values.isDisclosureOfInterestToBeFilledByInvestor &&
                    !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false)
                      ? ''
                      : '*'
                  }`}
                  name="interestedCompaniesCheck"
                  items={Object.keys(YesNoMaster).map((fundType) => ({
                    label: YesNoMaster[fundType],
                    value: fundType,
                  }))}
                  disabled={
                    getDisableStatusForjointHolder(role, referenceDetail.applicant_type) ||
                    (values.isDisclosureOfInterestToBeFilledByInvestor &&
                      !fieldValidateForInvestor(referenceDetail.applicant_type, 1, false))
                  }
                  onChange={(e) => {
                    setValues({
                      ...values,
                      interestedCompaniesCheck: e.target.value,
                      interestedcompanies:
                        values.interestedcompanies?.filter((data) => data.isActive).length === 0 &&
                        YesNoMaster[e.target.value] === YesNoMaster.yes
                          ? [
                              ...values.interestedcompanies,
                              { ...newInterestedCompany, sno: values.interestedcompanies?.length },
                            ]
                          : values.interestedcompanies,
                    });
                  }}
                />
              </Grid>
              {YesNoMaster[values.interestedCompaniesCheck] === YesNoMaster.yes && (
                <Grid xs={12} sm={12} lg={12}>
                  <Typography sx={{ fontWeight: 500, fontSize: 16, mt: 3 }}>
                    I, user name having entered into portfolio Management Services Agreement with
                    AMC Company (Intermediary), hereby give notice to the Portfolio Manager that:
                  </Typography>
                  <Typography sx={{ fontWeight: 500, fontSize: 16, my: 3 }}>
                    I am interested directly/through relative(s), which enables me to obtain
                    unpublished price sensitive information in the following bodies corporate:
                  </Typography>
                  <DisclosureOfInterestTable
                    values={values}
                    setDialogBox={setDialogBox}
                    mdmsCompanyList={mdmsCompanyList}
                  />
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', my: 3 }}>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                        fontSize: 14,
                        mb: 2,
                        px: 4,
                      }}
                      onClick={() => {
                        setValues({
                          ...values,
                          interestedcompanies: [
                            ...values.interestedcompanies,
                            { ...newInterestedCompany, sno: values.interestedcompanies?.length },
                          ],
                        });
                      }}
                      disabled={getDisableStatusForjointHolder(
                        role,
                        referenceDetail.applicant_type
                      )}>
                      Add Company
                    </Button>
                  </Grid>
                </Grid>
              )}
              <ConfirmationDialog
                message={`Are you sure you want to delete the company ?`}
                open={dialogbox !== null}
                setOpen={() => setDialogBox(null)}
                onSave={() => {
                  setValues({
                    ...values,
                    interestedcompanies: values.interestedcompanies?.map((data, ind) => {
                      if (data.sno === dialogbox?.Index) {
                        return { ...data, isActive: false };
                      }
                      return data;
                    }),
                  });
                  setDialogBox(null);
                }}
                onCancel={() => setDialogBox(null)}
              />
              <ProceedSaveLater
                saveLater={() => {
                  setValues({
                    ...values,
                    saveType: 'save for later',
                  });
                }}
                saveAndProceed={() => {
                  setValues({
                    ...values,
                    saveType: 'save and proceed',
                  });
                }}
                loader={loading}
                clickedButton={values.saveType}
                saveForLaterButtonText={
                  investorRouteObj
                    ? investorRouteObj.backSectionLink
                      ? `Save and Back To ${investorRouteObj.backSectionName}`
                      : 'Save For Later'
                    : 'Save For Later'
                }
                saveButtonText={
                  investorRouteObj
                    ? investorRouteObj.saveAndProceedSectionLink
                      ? 'Save and Proceed'
                      : 'Save Application'
                    : 'Save and Proceed'
                }
                showEndIcon={
                  investorRouteObj
                    ? investorRouteObj.saveAndProceedSectionLink
                      ? true
                      : false
                    : true
                }
                showSaveForLater={
                  investorRouteObj ? (investorRouteObj.backSectionLink ? true : false) : true
                }
              />
              <ConfirmationDialog
                message={`Are you sure you do not wish to fill this step ?`}
                open={openPopup !== null}
                setOpen={() => {
                  setOpenPopup(null);
                }}
                onSave={() => {
                  setValues({
                    ...values,
                    isDisclosureOfInterestToBeFilledByInvestor:
                      openPopup?.disclosureOfInterestsCheck as boolean,
                    interestedCompaniesCheck: '',
                    interestedcompanies: values.interestedcompanies?.map((data) => {
                      return { ...data, isActive: false };
                    }),
                  });
                  setOpenPopup(null);
                }}
                onCancel={() => {
                  setOpenPopup(null);
                }}
              />
              <ConfirmationDialog
                message={`Application Saved successfully and no further edits provided, Are you sure you want to go back to preview?`}
                open={openPreviewConfirmationPopup}
                setOpen={() => {
                  setOpenPreviewConfirmationPopup(false);
                }}
                onSave={() => {
                  setOpenPreviewConfirmationPopup(false);
                  history.push(`/investment-details/${esignReferenceId}/application-details`);
                }}
                onCancel={() => {
                  setOpenPreviewConfirmationPopup(false);
                }}
              />
            </Grid>
          </>
        )}
      </Formik>
    </>
  );
}
