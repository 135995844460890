/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import { FeeSlabsType, StrategyType } from '../types/api-types';
import {
  ADD_FEE_SLAB,
  ADD_FEE_SLAB_SUCCESS,
  ADD_STARATEGY,
  ADD_STARATEGY_SUCCESS,
  GetStrategiesResponseBody,
  GET_FEE_SLAB,
  GET_FEE_SLAB_DETAILS,
  GET_FEE_SLAB_DETAILS_SUCCESS,
  GET_FEE_SLAB_SUCCESS,
  GET_STARATEGY,
  GET_STARATEGY_DETAILS,
  GET_STARATEGY_DETAILS_SUCCESS,
  GET_STARATEGY_SUCCESS,
  GET_STRATEGIES,
  GET_STRATEGIES_SUCCESS,
  UPDATE_FEE_SLAB,
  UPDATE_FEE_SLAB_SUCCESS,
  UPDATE_STARATEGY,
  UPDATE_STARATEGY_SUCCESS,
} from '../types/strategies';

export type strategiesParams = {
  isActive?: boolean;
};

export const getStrategies =
  (params?: strategiesParams) =>
  async (dispatch: any): Promise<GetStrategiesResponseBody> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'GET',
        types: [GET_STRATEGIES_SUCCESS, GET_STRATEGIES],
        params,
      },
    });
  };

export const getStarategyList =
  () =>
  async (dispatch: any): Promise<StrategyType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'GET',
        types: [GET_STARATEGY_SUCCESS, GET_STARATEGY],
      },
    });
  };

export const getStarategyDetailById =
  (Id: string) =>
  async (dispatch: any): Promise<StrategyType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/${Id}`,
        method: 'GET',
        types: [GET_STARATEGY_DETAILS_SUCCESS, GET_STARATEGY_DETAILS],
      },
    });
  };

export const updateStarategyById =
  (Id: number, body: any) =>
  async (dispatch: any): Promise<StrategyType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/${Id}/update`,
        method: 'POST',
        types: [UPDATE_STARATEGY_SUCCESS, UPDATE_STARATEGY],
        body,
      },
    });
  };

export const addStarategy =
  (body: StrategyType) =>
  async (dispatch: any): Promise<StrategyType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies`,
        method: 'POST',
        types: [ADD_STARATEGY_SUCCESS, ADD_STARATEGY],
        body,
      },
    });
  };

export const getFeeSlabList =
  () =>
  async (dispatch: any): Promise<FeeSlabsType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/feeSlabs`,
        method: 'GET',
        types: [GET_FEE_SLAB_SUCCESS, GET_FEE_SLAB],
      },
    });
  };

export const getFeeSlabDetailById =
  (Id: string) =>
  async (dispatch: any): Promise<FeeSlabsType> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/FeeSlabs/${Id}`,
        method: 'GET',
        types: [GET_FEE_SLAB_DETAILS_SUCCESS, GET_FEE_SLAB_DETAILS],
      },
    });
  };

export const updateFeeSlabById =
  (Id: number, body: any) =>
  async (dispatch: any): Promise<FeeSlabsType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/FeeSlabs/${Id}/update`,
        method: 'POST',
        types: [UPDATE_FEE_SLAB_SUCCESS, UPDATE_FEE_SLAB],
        body,
      },
    });
  };

export const addFeeSlab =
  (body: FeeSlabsType) =>
  async (dispatch: any): Promise<FeeSlabsType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/feeSlabs`,
        method: 'POST',
        types: [ADD_FEE_SLAB_SUCCESS, ADD_FEE_SLAB],
        body,
      },
    });
  };

export const getStarategyListOfDirect =
  () =>
  async (dispatch: any): Promise<StrategyType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/direct`,
        method: 'GET',
        types: [GET_STARATEGY_SUCCESS, GET_STARATEGY],
      },
    });
  };
export const getFeeSlabListOfDirect =
  () =>
  async (dispatch: any): Promise<FeeSlabsType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/strategies/feeSlabs/direct`,
        method: 'GET',
        types: [GET_FEE_SLAB_SUCCESS, GET_FEE_SLAB],
      },
    });
  };
