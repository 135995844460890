import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  CardMedia,
  Stack,
  Typography,
  Theme,
  Grid,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Pagination,
  Dialog,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { SxProps } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { approveConsentEmail, rejectConsentEmail } from '../redux-store/actions/onBoarding';
import { CommonLayout, LoadingDialog } from './commonComponents';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import {
  ConsentEmailDetails,
  GetAllConsentEmailApplicantionsResponseBody,
  getAllConsentEmailApplications,
  ReSendConsentEmail,
  RevokeConsentEmail,
} from '../redux-store/actions/consentEmail';
import { DataTable } from './DataTable';
import { EmailConsentStatusMaster, USER_ROLES } from '../utils/constant';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useSnackbar } from 'notistack';
import { useDebounce } from '../utils/useDebounce';
import { formatShortDateAndTime } from '../utils/date';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { RootStateType } from '../redux-store/reducers';

export const ConsentEmailLayout = ({
  children,
  sx = {},
}: {
  children: JSX.Element;
  sx?: SxProps<Theme>;
}): JSX.Element => {
  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: '#f2f6f8 url("/images/login-bg.svg")',
        backgroundSize: 'cover',
      }}>
      <AppBar elevation={0} position="static" sx={{ bgcolor: 'transparent' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/motilal-logo.png"
            alt="Logo"
            sx={{ width: '120px', m: 3, my: 2.5 }}
          />
        </Box>
      </AppBar>
      <Box
        component="main"
        sx={{
          //height: 'calc(100vh - 43px)',
          flex: 1,
          display: 'grid',
          placeItems: 'center',
          // mt: 13,
          // justifyContent: 'center',
        }}>
        <Box sx={sx}>{children}</Box>
      </Box>
    </Stack>
  );
};

//access request page
const ConsentEmailApproveReject = (): JSX.Element => {
  const { verificationToken } = useParams<{ verificationToken: string }>();
  const [loadingApprove, setLoadingApprove] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingReject, setLoadingReject] = useState(false);

  return (
    <ConsentEmailLayout
      sx={{
        bgcolor: 'grey.50',
        height: { xs: 'unset', sm: '470px' },
        width: 'clamp(300px, 70%, 500px)',
        px: { xs: '25px', sm: '60px' },
        py: '50px',
        mb: 2,
        borderRadius: '10px',
        boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
      }}>
      <>
        <Box sx={{ mb: 2 }}>
          <CardMedia
            component="img"
            src="/images/access-request-icon.svg"
            sx={{ width: 'unset', mx: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            '.MuiInputLabel-root': {
              fontFamily: 'Poppins, sans-serif',
              fontSize: 16,
              fontWeight: 500,
            },
          }}>
          <Typography sx={{ color: 'secondary.main', fontWeight: 600, fontSize: 17, mt: 4 }}>
            AMC is fetching your data from KRA and AML sources
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 10 }}>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              loading={loadingApprove}
              variant="contained"
              sx={{
                color: 'common.white',
                lineHeight: 1.5,
                fontSize: 14,
              }}
              fullWidth={true}
              onClick={async () => {
                try {
                  setLoadingApprove(true);
                  await dispatch(approveConsentEmail({ verificationToken: verificationToken }));
                  history.push('/investor-consent-approved');
                } catch (e) {
                  setLoadingApprove(false);
                  console.error((e as Error).message);
                }
              }}
              disabled={loadingReject}>
              Approve
            </LoadingButton>
            <LoadingButton
              loadingPosition="start"
              type="submit"
              loading={loadingReject}
              variant="outlined"
              sx={{
                mt: 2,
                color: 'primary.main',
                lineHeight: 1.5,
                fontSize: 14,
              }}
              fullWidth={true}
              onClick={async () => {
                try {
                  setLoadingReject(true);
                  await dispatch(rejectConsentEmail({ verificationToken: verificationToken }));
                  history.push('/investor-consent-reject');
                } catch (e) {
                  setLoadingReject(false);
                  console.error((e as Error).message);
                }
              }}
              disabled={loadingApprove}>
              Reject
            </LoadingButton>
          </Box>
        </Box>
      </>
    </ConsentEmailLayout>
  );
};

export default ConsentEmailApproveReject;

//access approved page
export const ConsentEmailApprove = (): JSX.Element => {
  return (
    <ConsentEmailLayout
      sx={{
        bgcolor: 'grey.50',
        height: { xs: 'unset', sm: '370px' },
        width: 'clamp(300px, 70%, 470px)',
        px: { xs: '25px', sm: '50px' },
        py: '50px',
        mb: 2,
        borderRadius: '10px',
        boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
      }}>
      <>
        <Box sx={{ mb: 2 }}>
          <CardMedia
            component="img"
            src="/images/access-approved-icon.svg"
            sx={{ width: 'unset', mx: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            '.MuiInputLabel-root': {
              fontFamily: 'Poppins, sans-serif',
              fontSize: 16,
              fontWeight: 500,
            },
          }}
          mt={5}>
          <Typography
            sx={{ color: 'secondary.main', fontWeight: 600, fontSize: 18, textAlign: 'center' }}>
            Thanks for your approval.
          </Typography>
          <Typography sx={{ color: 'secondary.main', fontSize: 15, mt: 2, textAlign: 'center' }}>
            You can close this page.
          </Typography>
        </Box>
      </>
    </ConsentEmailLayout>
  );
};

//access rejected page
export const ConsentEmailReject = (): JSX.Element => {
  return (
    <ConsentEmailLayout
      sx={{
        bgcolor: 'grey.50',
        height: { xs: 'unset', sm: '370px' },
        width: 'clamp(300px, 70%, 470px)',
        px: { xs: '25px', sm: '50px' },
        py: '50px',
        mb: 2,
        borderRadius: '10px',
        boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
      }}>
      <>
        <Box sx={{ mb: 2 }}>
          <CardMedia
            component="img"
            src="/images/access-rejected-icon.svg"
            sx={{ width: 'unset', mx: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            '.MuiInputLabel-root': {
              fontFamily: 'Poppins, sans-serif',
              fontSize: 16,
              fontWeight: 500,
            },
          }}
          mt={5}>
          <Typography sx={{ color: 'secondary.main', fontWeight: 600, fontSize: 18 }}>
            Thank you. Your consent was rejected.
          </Typography>
          <Typography sx={{ color: 'secondary.main', fontSize: 15, mt: 2, textAlign: 'center' }}>
            You can close this page.
          </Typography>
        </Box>
      </>
    </ConsentEmailLayout>
  );
};

const headers = [
  {
    header: 'Investor PAN',
    valueGetter: (row: ConsentEmailDetails) => row.pan || 'N/A',
  },
  {
    header: 'Email Id',
    valueGetter: (row: ConsentEmailDetails) => row.emailId || 'N/A',
  },
  {
    header: 'Status',
    renderCell: (row: ConsentEmailDetails) => {
      const ConsentEmailApplicationStatus = EmailConsentStatusMaster[row.consentStatus];
      return (
        <Typography
          className={ConsentEmailApplicationStatus}
          sx={{
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 14,
            color: '#B78813',
            '&.Pending': {
              color: 'rgba(51, 127, 201, 0.7)',
            },
            '&.Approved': {
              fontWeight: 600,
              color: 'rgba(35, 133, 63, 0.7)',
            },
            '&.Rejected': {
              color: '#BD2932 !important',
              fontWeight: 500,
            },
            '&.Revoked': {
              color: '#B78813',
            },
          }}>
          {ConsentEmailApplicationStatus}
        </Typography>
      );
    },
  },
  {
    header: 'Updated At',
    valueGetter: (row: ConsentEmailDetails) => formatShortDateAndTime(row.updatedAt) || 'N/A',
  },
];

export const ConsentEmailApplicationsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [consentEmailApplicationsList, setConsentEmailApplicationsList] = useState<
    ConsentEmailDetails[]
  >([]);
  const [noOfPages, setNoPages] = useState(1);
  const [currentPage, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const debounceSearchString = useDebounce(search, 500);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [reSendRevokeLoading, setReSendRevokeLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setConsentEmailApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllConsentEmailApplications({ pan: search, page: currentPage })
        )) as unknown as GetAllConsentEmailApplicantionsResponseBody;
        const { consents, pages } = response || {};
        if (!componentIsAlive) {
          return;
        }
        setConsentEmailApplicationsList(consents);
        setNoPages(pages);
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
        setLoading(false);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, [currentPage, debounceSearchString]);

  const additionalCell = {
    header: '',
    renderEditDeleteCell: (row: ConsentEmailDetails): JSX.Element => {
      return (
        <Box display="flex">
          {EmailConsentStatusMaster[row.consentStatus] === EmailConsentStatusMaster.pending && (
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
              }}
              onClick={() => {
                setId(row.id as string), setOpen(true);
              }}>
              <InfoOutlinedIcon color="info" />
            </IconButton>
          )}
        </Box>
      );
    },
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleClose = () => {
    setOpen(false), setId('');
  };
  const handleLoadingPopupClose = () => {
    setReSendRevokeLoading(false);
  };

  return (
    <CommonLayout>
      <Box>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 500,
            mb: { xs: 2, md: 4 },
            p: { xs: '5px 15px', md: '' },
            bgcolor: '#e5f1f8',
            borderRadius: '10px',
          }}>
          Investors Email Consent Status
        </Typography>
        <Grid
          item
          container
          sx={{
            width: 'unset',
            alignItems: 'flex-end',
            gap: 2,
            p: '20px 30px',
            bgcolor: 'common.white',
            mb: 1.5,
          }}>
          <Grid item>
            <FormControl variant="standard" sx={{ display: 'block' }}>
              <InputLabel
                htmlFor="input-with-icon-adornment"
                sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
                Search PAN
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                value={search}
                onChange={({ target: { value } }) => {
                  setPage(1);
                  setSearch(value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchSharpIcon sx={{ color: 'primary.main' }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <DataTable
            isLoading={isLoading}
            tableHeader={USER_ROLES.AMC_ADMIN === role ? headers : [...headers, additionalCell]}
            tableData={consentEmailApplicationsList}
            rowCustomStyles={{
              '&:last-child td, &:last-child th': { border: 0 },
              '.MuiTableCell-body ': { color: '#686e72' },
              background: 'transparent',
            }}
            boxStyles={{ minWidth: 650 }}
          />
        </Box>
        {!!consentEmailApplicationsList.length && (
          <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
            <Pagination
              count={noOfPages}
              page={currentPage}
              color="primary"
              onChange={handlePageChange}
            />
          </Stack>
        )}
        <Dialog
          onClose={handleClose}
          open={open}
          sx={{
            '.MuiPaper-root ': {
              width: '100%',
              mx: { xs: 2, sm: 4 },
            },
          }}>
          <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end', mt: 2, mr: 2, mb: 2 }}>
            <CloseIcon fontSize="medium" />
          </IconButton>

          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            sx={{
              px: { xs: 1, sm: 4 },
              ':hover': {
                bgcolor: '#F4FCFC',
              },
              ':last-of-type': {
                mb: 5,
              },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              Re-Send Email Consent
            </Typography>
            <IconButton
              onClick={async () => {
                try {
                  handleClose();
                  setReSendRevokeLoading(true);
                  await dispatch(ReSendConsentEmail(id));
                  const response = (await dispatch(
                    getAllConsentEmailApplications({ pan: search, page: currentPage })
                  )) as unknown as GetAllConsentEmailApplicantionsResponseBody;
                  setConsentEmailApplicationsList(response?.consents);
                  setNoPages(response?.pages);
                  handleLoadingPopupClose();
                  enqueueSnackbar('Email sent successfully', {
                    variant: 'success',
                    autoHideDuration: 3000,
                  });
                } catch (e) {
                  handleLoadingPopupClose();
                  console.error((e as Error).message);
                }
              }}>
              <ForwardToInboxIcon color="info" />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            py={1}
            sx={{
              px: { xs: 1, sm: 4 },
              ':hover': {
                bgcolor: '#F4FCFC',
              },
              ':last-of-type': {
                mb: 5,
              },
            }}>
            <Typography
              sx={{
                color: 'rgba(41, 49, 57, 0.7)',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              Revoke Email Consent
            </Typography>
            <IconButton
              onClick={async () => {
                try {
                  handleClose();
                  setReSendRevokeLoading(true);
                  await dispatch(RevokeConsentEmail(id));
                  const response = (await dispatch(
                    getAllConsentEmailApplications({ pan: search, page: currentPage })
                  )) as unknown as GetAllConsentEmailApplicantionsResponseBody;
                  setConsentEmailApplicationsList(response?.consents);
                  setNoPages(response?.pages);
                  handleLoadingPopupClose();
                  enqueueSnackbar('Email Revoked successfully', {
                    variant: 'success',
                    autoHideDuration: 3000,
                  });
                } catch (e) {
                  handleLoadingPopupClose();
                  console.error((e as Error).message);
                }
              }}
              sx={{ width: '40px' }}>
              <CardMedia component="img" src="/images/revoke-icon.svg" />
            </IconButton>
          </Grid>
        </Dialog>
        <LoadingDialog
          loadingPopup={reSendRevokeLoading}
          onLoadingPopupClose={handleLoadingPopupClose}
        />
      </Box>
    </CommonLayout>
  );
};
