import { IconButton, Typography, Grid, Hidden, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AuthorisedSignatory from './authorisedSignatory';
import AddAuthorisedSignatory from './addAuthorisedSignatory';
import DistributorRm from './distributorRm';
import AddDistributorRm from './addDistributorRm';
import PoaApprovers from './poaApprovers';
import AddPOAApprover from './addPoa';
import { Location } from 'history';
import DistributorRmDetails from './viewDistributorRm';
import AuthorisedSignatoryDetails from './viewAuthorisedSignatory';
import PoaApproverDetails from './viewPoaApprover';
import { USER_ROLES } from '../../utils/constant';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';

function Routes({ distributorId }: { distributorId: string }): JSX.Element {
  const history = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!history.location.state) {
      history.replace('/user-management');
    }
  }, []);
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={path}
          render={() => (
            <Redirect
              to={{
                pathname: `${path}/distributors-rm`,
                state: { distributorId },
              }}
              from={location.pathname}
            />
          )}
        />
        <Route path={`${path}/distributors-rm`} component={DistributorRm} />
        <Route path={`${path}/add-distributors-rm`} component={AddDistributorRm} />
        <Route path={`${path}/authorised-signatory`} component={AuthorisedSignatory} />
        <Route path={`${path}/add-authorised-signatory`} component={AddAuthorisedSignatory} />
        <Route path={`${path}/edit-distributors-rm`} component={AddDistributorRm} />
        <Route path={`${path}/edit-authorised-signatory`} component={AddAuthorisedSignatory} />
        <Route path={`${path}/poa-approver`} component={PoaApprovers} />
        <Route path={`${path}/edit-poaApprover`} component={AddPOAApprover} />
        <Route path={`${path}/add-poaApprover`} component={AddPOAApprover} />
        <Route path={`${path}/distributors-rm-details`} component={DistributorRmDetails} />
        <Route
          path={`${path}/authorised-signatory-details`}
          component={AuthorisedSignatoryDetails}
        />
        <Route path={`${path}/poa-approver-details`} component={PoaApproverDetails} />
      </Switch>
    </Router>
  );
}

function UserManagementLayOutItems({
  item,
  link,
  distributorId,
}: {
  item: string;
  link: string;
  distributorId: string;
}) {
  const history = useHistory();
  const pathLink = history.location.pathname.includes(link);
  return (
    <Grid
      item
      sx={{
        py: 2,
        px: 4,
        borderBottom: pathLink ? '3px solid #57B6BA' : 'none',
      }}>
      <Typography
        sx={{
          color: 'primary.main',
          textDecoration: 'none',
          fontWeight: 600,
        }}
        to={{ pathname: `${link}`, state: { distributorId } }}
        component={RouterLink}>
        {item}
      </Typography>
    </Grid>
  );
}

export default function ListCompanyDistributors({
  location,
}: {
  location: Location<{ distributorId: string }>;
}): JSX.Element {
  const { distributorId = '' } = location.state || {};
  const history = useHistory();
  const pathName = history.location.pathname;
  const { path } = useRouteMatch();

  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const distributorMenuLinks = [
    `${path}/distributors-rm`,
    `${path}/authorised-signatory`,
    `${path}/poa-approver`,
  ];
  const MenuItems = [
    { title: "Distributor's RM", routerLink: `distributors-rm` },
    { title: 'Authorized Signatory', routerLink: `authorised-signatory` },
    { title: 'POA Approver', routerLink: `poa-approver` },
  ];

  return (
    <>
      {distributorMenuLinks.includes(pathName) && (
        <>
          <Hidden only="xs">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: role === USER_ROLES.AMC_ADMIN ? 0 : 3,
                mt: { sm: 3, md: 0 },
                pl: { sm: 2, md: 0 },
              }}>
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() =>
                  history.push('/user-management/distributors', { distributorType: 'company' })
                }>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                List of Distributors
              </Typography>
            </Box>
            {role === USER_ROLES.AMC_ADMIN && (
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                flexWrap="nowrap"
                alignItems="center"
                sx={{
                  backgroundColor: 'white',
                  marginTop: 3,
                  borderRadius: '5px',
                  mb: 0.5,
                }}>
                {MenuItems.map((itemList, index) => {
                  return (
                    <React.Fragment key={index}>
                      <UserManagementLayOutItems
                        item={itemList.title}
                        link={itemList.routerLink}
                        distributorId={distributorId}
                      />
                    </React.Fragment>
                  );
                })}
              </Grid>
            )}
          </Hidden>
          <Hidden smUp={true}>
            <Box
              sx={{
                bgcolor: 'white',
                pb: 4,
                borderRadius: '5px',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1,
                  mb: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.8)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="medium" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  User Management
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', pl: { xs: 3, sm: 0 } }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push('/user-management/distributors', { distributorType: 'company' })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                    pl: { xs: 1, sm: 0 },
                  }}>
                  List of Distributors
                </Typography>
              </Box>
              {role === USER_ROLES.AMC_ADMIN && (
                <Box sx={{ px: 4, pt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="user-items"></InputLabel>
                    <Select
                      labelId="user-items"
                      value={MenuItems[distributorMenuLinks.indexOf(pathName)].routerLink}
                      onChange={(e) => {
                        history.push(`${e.target.value}`, { distributorId });
                      }}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                      }}
                      IconComponent={(props) => (
                        <ArrowDropDownIcon
                          {...props}
                          sx={{
                            paddingRight: '13px',
                            color: '#00D9DA !important',
                            cursor: 'pointer',
                          }}
                          fontSize="large"
                        />
                      )}>
                      {MenuItems.map((itemlist, ind) => (
                        <MenuItem
                          sx={{ borderBottom: '1px solid #ccc' }}
                          value={itemlist.routerLink}
                          key={ind}>
                          {itemlist.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Box>
          </Hidden>
        </>
      )}
      <Routes distributorId={distributorId} />
    </>
  );
}
