import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Chip,
} from '@mui/material';
import { SubHeading } from '../../investors/components';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { Location } from 'history';
import { KeyValue } from '../../ApplicationDetails/contributor';
import { FeeSlabsType, FeeType } from '../../../redux-store/types/api-types';
import { getFeeSlabDetailById } from '../../../redux-store/actions/strategies';
import { debitingFreqMaster, YearEndMaster } from '../../../utils/constant';

export default function FeeSlabDetails({
  location,
}: {
  location: Location<{ feeSlabsId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [feeSlabs, setFeeSlabs] = useState<FeeSlabsType>();
  const { feeSlabsId } = location.state || {};
  const history = useHistory();

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (feeSlabsId) {
          const res = (await dispatch(
            getFeeSlabDetailById(feeSlabsId.toString())
          )) as unknown as FeeSlabsType;
          setFeeSlabs(res);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: { lg: 2, xs: 2 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { lg: 'center', xs: 'flex-start' },
              justifyContent: 'space-between',
              flexDirection: { lg: 'row', xs: 'column' },
              ml: { lg: 7, xs: 2 },
              pt: { xs: 2, sm: 4 },
              mr: { lg: 12, xs: 2 },
              pb: 1,
            }}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('fee-slab-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  width: '100%',
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                    pr: 5,
                  }}>
                  Fee Slab
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() => history.push('fee-slab-list')}>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {'Fee Slab Details'}
              </Typography>
              <Hidden smUp={true}>
                <IconButton sx={{ p: 0, ml: 12 }}>
                  <CardMedia
                    component="img"
                    src="/images/edit-icon-outlined.svg"
                    alt="Edit Icon"
                    sx={{ width: 'unset' }}
                    onClick={() => history.push(`edit-fee-slab`, { feeSlabsId: feeSlabsId })}
                  />
                </IconButton>
              </Hidden>
            </Box>
            <Hidden only="xs">
              <IconButton sx={{ p: 0, m: 0 }}>
                <CardMedia
                  component="img"
                  src="/images/edit-icon-outlined.svg"
                  alt="Edit Icon"
                  sx={{ width: 'unset' }}
                  onClick={() => {
                    history.push(`edit-fee-slab`, { feeSlabsId: feeSlabsId });
                  }}
                />
              </IconButton>
            </Hidden>
          </Box>

          {isLoading && <LinearProgress sx={{ ml: 5, mr: 5 }} />}
          {feeSlabs && (
            <>
              <Box sx={{ mr: { lg: 20, xs: 4 } }}>
                <SubHeading
                  sx={{
                    color: 'common.blue',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                    ml: { lg: 8, xs: 2 },
                  }}>
                  {'Fee slab Details'}
                </SubHeading>
              </Box>
              <Grid
                container
                sx={{
                  mt: 1,
                  pl: { xs: 2, sm: 10 },
                  pr: { xs: 2, sm: 10 },
                }}>
                <React.Fragment>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Fee Name'} description={feeSlabs?.feeName} />
                      <KeyValue title={'Management Fee'} description={feeSlabs?.mgmtFee} />
                      <KeyValue title={'Operating Expenses'} description={feeSlabs?.optFee} />
                      <KeyValue title={'Fee Slab Id'} description={feeSlabs?.feeSlabId} />
                      <KeyValue title={'Fee Slab Code'} description={feeSlabs?.feeSlabCode} />
                      <KeyValue title={'Type'} description={feeSlabs?.feeType} />
                      <KeyValue
                        title={'Year End'}
                        description={
                          feeSlabs?.yearEnd ? YearEndMaster[feeSlabs?.yearEnd] : feeSlabs?.yearEnd
                        }
                      />
                      <KeyValue
                        title={'Direct'}
                        description={feeSlabs?.isApplicableForDirect ? 'Yes' : 'No'}
                      />
                      <KeyValue
                        title={'Fee debiting Frequency'}
                        description={
                          feeSlabs?.debitingFrequency
                            ? debitingFreqMaster[feeSlabs?.debitingFrequency]
                            : feeSlabs?.debitingFrequency
                        }
                      />
                      <Grid item xs={12}>
                        <Item>{`Applicable Distributor`}</Item>
                        {feeSlabs?.distributorDetails &&
                        feeSlabs?.distributorDetails?.distributors.length > 0 ? (
                          <Box
                            sx={{
                              border: 0.5,
                              // borderColor: 'rgba(41, 49, 57, 0.7)',
                              borderColor: 'primary.main',
                              p: 3,
                              mb: 3,
                              mt: 2,
                              borderRadius: 0.5,
                            }}>
                            {feeSlabs?.distributorDetails.distributors.map((distributor) => {
                              return (
                                <React.Fragment key={distributor.id}>
                                  <Chip
                                    label={`${distributor.arnCode || 'N/A'} - ${
                                      distributor.name
                                    } (${distributor.type})`}
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      color: 'rgba(41, 49, 57, 0.7)',
                                      // p: 2,
                                      m: 0.8,
                                    }}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        ) : (
                          <ItemDetails>-</ItemDetails>
                        )}
                      </Grid>
                    </Grid>
                  </Gridstyles>

                  <SubHeading
                    sx={{
                      color: 'common.blue',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                      ml: { xs: 3.5 },
                      // backgroundColor: 'red',
                    }}>
                    {'Exit Load'}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={21}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {feeSlabs.exitload?.map((exit, i) => {
                        return (
                          <KeyValue
                            key={i}
                            title={`${exit.frequency}`}
                            description={exit.exitload}
                          />
                        );
                      })}
                    </Grid>
                  </Gridstyles>
                  {feeSlabs?.hurdlerates?.map((rate, index) => {
                    return (
                      <>
                        <SubHeading
                          sx={{
                            color: 'common.blue',
                            letterSpacing: 0.5 + 'px',
                            padding: { xs: '10px 10px', sm: '10px 30px' },
                            ml: { xs: 3.5 },
                            // backgroundColor: 'red',
                          }}>
                          {'Hurdle Rate ' + (index + 1)}
                        </SubHeading>
                        <Gridstyles>
                          <Grid
                            item
                            container
                            pb={5}
                            rowSpacing={6}
                            columnSpacing={38}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start">
                            <KeyValue title={'From'} description={`${rate?.from}%`} />
                            <KeyValue title={'To'} description={rate?.to} />
                            <KeyValue title={'Profit'} description={rate?.profit} />
                          </Grid>
                        </Gridstyles>
                      </>
                    );
                  })}
                </React.Fragment>
              </Grid>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
