export function formatShortDate(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-In', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export function formatShortDateAndTime(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  let day = '' + d.getDate();
  const month = d.toLocaleString('en-US', {
    month: 'short',
  });
  const year = d.getFullYear().toString().substr(-2);
  if (day.length < 2) day = '0' + day;
  return (
    [day, month + "'" + year].join(' ') +
    ' ' +
    d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  );
}
