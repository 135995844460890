import { DisplayImgFields } from '../ApplicationDetails/displayImg';
import { Box, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { Applicant, Groups } from '../../redux-store/types/api-types';
import { PHOTOCAPTURE_STATUS, USER_ROLES } from '../../utils/constant';
import { Gridstyles, ItemDetails } from '../Investments/investor-application';
import { SubHeading } from '../investors/components';

export default function DisplayImgForAuthorizedSignatories({
  authorisedSignatores = [],
  editable,
}: {
  authorisedSignatores: Partial<Groups>[];
  editable: boolean;
}): JSX.Element {
  const history = useHistory();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const AthorisedSignatoriesReferenceDetail = useSelector(
    (store: RootStateType) => store.authorizedSignatoriesRefrenceIdReducer
  );

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mb={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Authorized signatories Photograph
              </Typography>
            </Grid>
            {authorisedSignatores?.map((group, index: number) => {
              return (
                <React.Fragment key={group.id}>
                  {group?.groupsignatories?.map((signatory, index) => {
                    return (
                      <React.Fragment key={signatory.id}>
                        <Box sx={{ width: '100%' }}>
                          <SubHeading
                            sx={{
                              color: 'common.black',
                              letterSpacing: 0.5 + 'px',
                              padding: { xs: '10px 10px', sm: '10px 30px' },
                            }}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              {`Photo Capture Details For Authorized Signatory ${index + 1}`}
                              {AthorisedSignatoriesReferenceDetail.referenceId &&
                                editable &&
                                AthorisedSignatoriesReferenceDetail.referenceId ===
                                  signatory.referenceId && (
                                  <CardMedia
                                    component="img"
                                    src="/images/edit-icon-outlined.svg"
                                    alt="Edit Icon"
                                    // sizes="small"
                                    sx={{
                                      width: '30px',
                                      ml: 0,
                                      cursor: 'pointer',
                                      pointerEvents: 'all',
                                    }}
                                    onClick={() => history.push(`location-capture`)}
                                  />
                                )}
                            </Box>
                          </SubHeading>
                          <DisplayImgFields
                            data={signatory}
                            displayScore={AthorisedSignatoriesReferenceDetail.referenceId}
                          />
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
