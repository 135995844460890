/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CALL_API } from '../middleware';
import {
  FeeSlabsType,
  FileType,
  JointholderRelationMaster,
  RiskProfileMaster,
  RMType,
  StrategyType,
} from '../types/api-types';
import {
  GET_DISTRIBUTOR_CODES,
  GET_DISTRIBUTOR_CODES_SUCCESS,
  GET_IFSC_DETAILS_SUCCESS,
  GET_IFSC_DETAILS,
  GET_DISTRIBUTORS_SUCCESS,
  GET_DISTRIBUTORS,
  IfscType,
  GET_RMS,
  GET_RMS_SUCCESS,
  GET_Countries_SUCCESS,
  GET_Countries,
  nationaliyType,
  GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS,
  GET_DISTRIBUTORS_FOR_FILTERS,
  GET_JOINTHOLDERLIST_SUCCESS,
  GET_JOINTHOLDERLIST,
  GET_RISKPROFILEMDMS_SUCCESS,
  GET_RISKPROFILEMDMS,
  GET_BANK_DETAILS_FROM_CHEQUE_SUCCESS,
  GET_BANK_DETAILS_FROM_CHEQUE,
  GET_MASKED_AADHAR_DETAILS_SUCCESS,
  GET_MASKED_AADHAR_DETAILS,
} from '../types/mdms';

export type Distributor = {
  id: number;
  userId: number;
  name: string;
  firstName?: string;
  type: string;
  buildingNo: string;
  streetName: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  arnCode: string;
  panNumber: string;
  isActive: boolean | null;
  amcId: number[];
  rmId: number;
  createdAt: string;
  updatedAt: string;
  distributors_RM_Code?: string | null;
  user: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    countryCode: string;
    phone: string;
    email: string;
    role: string;
    isActive: boolean;
    password: string | null;
  };
  subdistributors?: SubDistributor[];
  distributorEmpCode: string | null;
  euin: string | null;
  strategies: StrategyType[];
  feeSlabs: FeeSlabsType[];
};

export type SubDistributor = Distributor & {
  distributors_RM_Code: string;
  subDistributorCode: string | null;
  euin: string | null;
};

export type getDistributorsResponse = {
  userId: number;
  empCode: string;
  amcId: number;
  createdAt: string;
  updatedAt: string;
  address1: string;
  address2: string;
  address3: string;
  arnCode: string;
  id: number;
  panNumber: string;
  phone: string;
  role: string;
  firstName: string;
  distributors: Distributor[];
};

//To get distributors under RM
export const getDistributors =
  (params?: { isActive: boolean }) =>
  async (dispatch: any): Promise<getDistributorsResponse> => {
    const distributorsResponse = await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors-of-rm`,
        method: 'GET',
        types: [GET_DISTRIBUTOR_CODES_SUCCESS, GET_DISTRIBUTOR_CODES],
        params,
      },
    });
    return distributorsResponse as getDistributorsResponse;
  };

export const getIFSCDetails =
  (ifscCode: string) =>
  async (dispatch: any): Promise<IfscType> => {
    const ifscResponse = await dispatch({
      [CALL_API]: {
        url: `/onboarding/ifsc/${ifscCode}`,
        method: 'GET',
        types: [GET_IFSC_DETAILS_SUCCESS, GET_IFSC_DETAILS],
        slient: true,
      },
    });
    return ifscResponse as IfscType;
  };

//To get all the distributors list who's status is active
export const getDistributorsList =
  () =>
  async (dispatch: any): Promise<Distributor[]> => {
    const distributorsResponse = (await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors`,
        method: 'GET',
        types: [GET_DISTRIBUTORS_SUCCESS, GET_DISTRIBUTORS],
      },
    })) as Distributor[];
    return distributorsResponse as Distributor[];
  };

// Get the list of RMs under and return all the active rms

export type RMListFilter = {
  name?: string;
  limit?: number;
  page?: number;
  orderBy?: string;
  orderDirection?: string;
  roleType?: string;
  isActive?: boolean;
};
export const getRMsList =
  (params?: RMListFilter) =>
  async (dispatch: any): Promise<RMType[]> => {
    const rmListRes = (await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/rm-list`,
        method: 'GET',
        types: [GET_RMS, GET_RMS_SUCCESS],
        params,
      },
    })) as { rms: RMType[]; limit: 10; page: 1; pages: 1; total: 1 };
    return rmListRes.rms as RMType[];
  };

export const getNationalityList =
  () =>
  async (dispatch: any): Promise<nationaliyType> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/default-countries.json',
        method: 'GET',
        types: [GET_Countries_SUCCESS, GET_Countries],
      },
    });
  };

export const getDistributorsListForFilters =
  (params?: { type?: string; isActive?: boolean }) =>
  async (dispatch: any): Promise<Distributor[]> => {
    const distributorsResponse = (await dispatch({
      [CALL_API]: {
        url: `/onboarding/amc/distributors/list`,
        method: 'GET',
        types: [GET_DISTRIBUTORS_FOR_FILTERS_SUCCESS, GET_DISTRIBUTORS_FOR_FILTERS],
        params,
      },
    })) as Distributor[];
    return distributorsResponse as Distributor[];
  };

export const jointHolderRelationsList =
  () =>
  async (dispatch: any): Promise<JointholderRelationMaster> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/jointholder_relations_master.json',
        method: 'GET',
        types: [GET_JOINTHOLDERLIST_SUCCESS, GET_JOINTHOLDERLIST],
      },
    });
  };

export const riskProfileMasterData =
  () =>
  async (dispatch: any): Promise<RiskProfileMaster> => {
    return await dispatch({
      [CALL_API]: {
        url: '/mdms/riskprofile_master.json',
        method: 'GET',
        types: [GET_RISKPROFILEMDMS_SUCCESS, GET_RISKPROFILEMDMS],
      },
    });
  };
export type BankDetailsFromChequeBody = {
  fileId: string;
  applicationId: string;
};

export type BankDetailsFromChequeResponse = {
  MicrNo: string;
  BankName: string;
  BankAdd1: string;
  BankAdd2: string;
  BankAdd3: string;
  BankCity: string;
  BankPin: number;
  IFSCCode: string;
  IFSCOwnedBank: string;
  AccountNumber: string;
};
export const getBankDetailsFromCheque =
  (body: BankDetailsFromChequeBody) =>
  async (dispatch: any): Promise<BankDetailsFromChequeResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/fetch-ifsc-details`,
        method: 'POST',
        types: [GET_BANK_DETAILS_FROM_CHEQUE_SUCCESS, GET_BANK_DETAILS_FROM_CHEQUE],
        body,
      },
    });
  };
export type MaskedAadharBody = {
  fileId: string;
  applicationId: string;
  applicantId?: string;
  nomineeId?: string;
};
export type MaskedAadharResponse = FileType;
export const getMaskedAadhar =
  (body: MaskedAadharBody) =>
  async (dispatch: any): Promise<MaskedAadharResponse> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/applications/get-masked-aadhar`,
        method: 'POST',
        types: [GET_MASKED_AADHAR_DETAILS_SUCCESS, GET_MASKED_AADHAR_DETAILS],
        body,
      },
    });
  };
