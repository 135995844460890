import { Box, Button, CircularProgress, Grid, Skeleton } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getDistributorsListForFilters,
  getRMsList,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory, useParams } from 'react-router';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  DistributorChannelMaster,
  DistributorTypesForUserManageMent,
  CustodianMaster,
  USER_ROLES,
} from '../../utils/constant';
import { InfoDialog, Notes, ProceedSaveLater, SubHeading } from './components';
import UseRadioGroup from '../../lib/formik/Radio';
import { distributorsValidationSchema } from '../../utils/schema';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkInitialApplicationStatusIsChecker,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import {
  DistributorListType,
  DistributorNewList,
  FeeSlabsType,
  RMType,
  StrategyType,
} from '../../redux-store/types/api-types';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { ConfirmationDialog } from '../commonComponents';
import { branchMaster } from '../../utils/constant';
import MFSelectField from '../../lib/formik/SelectField';
import { useSnackbar } from 'notistack';
import {
  getFeeSlabListOfDirect,
  getStarategyListOfDirect,
} from '../../redux-store/actions/strategies';

type DistributorDetailsProps = {
  distributorName: string | null;
  distributorId: number | null;
  loginType: string;
  firstName: string;
  empCode: string;
  phone: string;
  email: string;
  subdistributorId: number | null;
  subdistributorName: string | null;
  subdistributorEmail: string | null;
  // filledBy: string | null;
  subdistributorPhone: string | null;
  subdistributorCode: string | null;
  subdistributorEuin: string | null;
  distributorEmail: string | null;
  distributorPhone: string | null;
  rm_branch: string | null;
  distributor_branch: string | null;
  distributorChannel: string | null;
  rm_id: number | undefined;
  custodian: string | null;
  saveType: string;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const RMDetail = ({ values }: { values: any }) => {
  return (
    <>
      <GridItem>
        <MFTextField name="firstName" label="RM Name" placeholder="Enter RM name" disabled={true} />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label="RM's Email ID"
          placeholder="Enter RM's Email ID"
          disabled={true}
        />
      </GridItem>
      {/* <GridItem>
        <MFTextField
          name="phone"
          label="RM's Mobile Number"
          placeholder="Enter RM's Mobile Number"
          disabled={true}
        />
      </GridItem> */}
      <GridItem>
        <SearchableSelect
          items={branchMaster}
          name="rm_branch"
          label="AMC Branch *"
          placeholder="Enter AMC Branch"
        />
      </GridItem>
      {/* <GridItem>
        <MFTextField
          name="empCode"
          label="Employee Code"
          placeholder="Enter Employee Code"
          disabled={true}
        />
      </GridItem> */}
    </>
  );
};

const DistributorDetail = ({
  distributors,
  values,
  selectedDistributor,
  setSelectedDistributor,
  loader,
}: {
  distributors: Distributor[];
  values: DistributorDetailsProps;
  selectedDistributor: SubDistributor[];
  setSelectedDistributor: (distributor: SubDistributor[]) => void;
  loader: boolean;
}): JSX.Element => {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { setValues } = useFormikContext();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [srmsLoading, setSrmsLoading] = useState(false);

  const [openDialog, setDialog] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setDialog(true);
  };
  const handleDialogClose = () => {
    setDialog(false);
  };

  return (
    <>
      {distributors.length > 0 ? (
        <>
          {[USER_ROLES.RM].includes(role) && values.distributorId && (
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 14,
                  py: 0.5,
                  px: 3,
                }}
                onClick={handleDialogOpen}>
                Clear Distributor/ Distributor&apos;s RM details
              </Button>
            </Grid>
          )}
          <GridItem>
            <SearchableSelect
              name="distributorId"
              label="Distributor Name *"
              items={distributors
                .sort((distributor1, distributor2) =>
                  (distributor1['name'] || '')
                    .toString()
                    .localeCompare((distributor2['name'] || '').toString())
                )
                .map((item) => ({
                  key: item.name,
                  value: item.id,
                }))}
              onChange={async ({ target: { value } }) => {
                try {
                  const distributorCode = distributors.find((item) => item.id === value);
                  const {
                    id = null,
                    name,
                    distributorEmpCode = '',
                    euin = '',
                  } = distributorCode || {};
                  if (id) {
                    setSrmsLoading(true);
                    const res = (await dispatch(
                      getDistributorById(id)
                    )) as unknown as DistributorNewList;
                    const { user } = res || {};
                    const { email, phone } = user || {};
                    setValues({
                      ...values,
                      distributorName: name,
                      distributorId: id,
                      distributorEmail: email,
                      distributorPhone: phone,
                      subdistributorId: null,
                      subdistributorName: null,
                      subdistributorEmail: '',
                      subdistributorPhone: '',
                      subdistributorCode: distributorEmpCode || '',
                      subdistributorEuin: euin || '',
                    });
                    setSelectedDistributor(
                      (res?.subdistributors as unknown as SubDistributor[]) || []
                    );
                    setSrmsLoading(false);
                  }
                  // else {
                  //   setValues({
                  //     ...values,
                  //     distributorName: name,
                  //     distributorId: id,
                  //     subdistributorId: null,
                  //     subdistributorName: null,
                  //     subdistributorEmail: null,
                  //     subdistributorPhone: null,
                  //     subdistributorCode: null,
                  //     subdistributorEuin: null,
                  //   });
                  //   setSelectedDistributor(
                  //     distributors.find((distributor) => distributor.id === value)
                  //       ?.subdistributors || []
                  //   );
                  // }
                } catch (e: unknown) {
                  setSrmsLoading(false);
                  console.error((e as Error).message);
                }
              }}
              disabled={[
                USER_ROLES.DISTRIBUTOR,
                USER_ROLES.INVESTOR,
                USER_ROLES.POAAPPROVER,
                USER_ROLES.SUBDISTRIBUTOR,
              ].includes(role)}
              // applyRenderValue={true}
              searchFieldPlaceholder={'Search Distributor'}
            />
            {srmsLoading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={30} />
              </Box>
            )}
          </GridItem>
          {/* <GridItem>
            <MFTextField
              name="distributorName"
              label="Distributor Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
          {values.distributorPhone && values.distributorEmail && (
            <>
              <GridItem>
                <MFTextField
                  name="distributorEmail"
                  label="Distributor Email"
                  placeholder="Enter Distributor Email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="distributorPhone"
                  label="Distributor Phone Number"
                  placeholder="Enter Distributor Phone Number"
                  disabled={true}
                />
              </GridItem>
            </>
          )}
          {!!selectedDistributor?.length && (
            <>
              <GridItem>
                <SearchableSelect
                  name="subdistributorId"
                  label="Distributor's RM Name *"
                  items={selectedDistributor
                    .sort((subDistributor1, subDistributor2) =>
                      (subDistributor1.user['firstName'] || '')
                        .toString()
                        .localeCompare((subDistributor2.user['firstName'] || '').toString())
                    )
                    .map((item) => ({
                      key: item.user.firstName || 'NA',
                      value: item.id,
                    }))}
                  onChange={({ target: { value } }) => {
                    const distributorCode = selectedDistributor.find((item) => item.id === value);
                    const {
                      id = null,
                      user,
                      subDistributorCode = '',
                      euin = '',
                    } = distributorCode || {};
                    const { email, phone } = user || {};

                    setValues({
                      ...values,
                      subdistributorId: id,
                      subdistributorEmail: email,
                      subdistributorPhone: phone,
                      subdistributorCode: subDistributorCode,
                      subdistributorEuin: euin,
                    });
                  }}
                  disabled={[
                    USER_ROLES.DISTRIBUTOR,
                    USER_ROLES.INVESTOR,
                    USER_ROLES.POAAPPROVER,
                    USER_ROLES.SUBDISTRIBUTOR,
                  ].includes(role)}
                  searchFieldPlaceholder={"Search Distributor's RM"}
                />
              </GridItem>
              {/* <GridItem>
            <MFTextField
              name="subdistributorName"
              label="Distributor's RM Name *"
              placeholder="Enter Distributor name"
              disabled={true}
            />
          </GridItem> */}
              <GridItem>
                <MFTextField
                  name="subdistributorEmail"
                  label="Distributor's RM Email *"
                  placeholder="Enter Distributor email"
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="subdistributorPhone"
                  label="Distributor's RM Phone Number"
                  placeholder="Enter Distributor's RM Phone Number"
                  disabled={true}
                />
              </GridItem>
            </>
          )}
          <GridItem>
            <MFTextField
              name="subdistributorCode"
              label="Sub Distributor Code"
              placeholder="Enter Sub Distributor Code"
              disabled={true}
            />
          </GridItem>
          <GridItem>
            <MFTextField
              name="subdistributorEuin"
              label="EUIN"
              placeholder="Enter EUIN"
              disabled={true}
            />
          </GridItem>
          {/* <GridItem>
            <MFTextField name="filledBy" label="Filled by" placeholder="Enter filled by" />
            <Typography
              component="span"
              sx={{ position: 'relative', left: 64, bottom: { xs: 85, md: 91 } }}>
              <InfoOutlinedIcon color="info" fontSize="small" onClick={handleOpen} />
            </Typography>
          </GridItem> */}
          <GridItem>
            <SearchableSelect
              items={DistributorChannelMaster}
              name="distributorChannel"
              label="Distributor Channel"
              placeholder="Enter Distributor Channel"
            />
          </GridItem>
          <GridItem>
            <MFTextField
              name="distributor_branch"
              label="Distributor Location *"
              placeholder="Enter Distributor Location"
            />
          </GridItem>
          <GridItem>
            <SearchableSelect
              items={branchMaster}
              name="rm_branch"
              label="AMC Branch *"
              placeholder="Enter AMC Branch"
            />
          </GridItem>

          <InfoDialog
            onClose={handleClose}
            open={open}
            content={'Please add the Name of the Subdistributor RM.'}
          />
        </>
      ) : loader ? (
        <>
          <GridItem>
            <Skeleton />
          </GridItem>
          <GridItem>
            <Skeleton />
          </GridItem>
        </>
      ) : (
        <>
          <Grid
            xs={12}
            sm={12}
            sx={{
              fontSize: 18,
              fontWeight: 500,
              color: 'rgb(97, 209, 214)',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'center',
              mt: { lg: 9, xs: 9 },
              mb: 2.9,
              ml: { xs: 3 },
            }}>
            No distributor mapped under this RM
          </Grid>
        </>
      )}
      <ConfirmationDialog
        message={`Are you sure you want to clear Distributor details ?`}
        open={openDialog}
        setOpen={handleDialogClose}
        onSave={() => {
          handleDialogClose();
          setValues({
            ...values,
            distributorName: '',
            distributorId: null,
            subdistributorId: null,
            subdistributorName: '',
            subdistributorEmail: '',
            subdistributorPhone: '',
            subdistributorCode: '',
            subdistributorEuin: '',
            distributorEmail: '',
            distributor_branch: '',
            distributorPhone: '',
            distributorChannel: '',
          });
          setSelectedDistributor([]);
        }}
        onCancel={handleDialogClose}
      />
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [distributors, setDistributors] = useState<Distributor[]>([]);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [selectedDistributor, setSelectedDistributor] = useState<SubDistributor[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { auth } = useSelector((store: RootStateType) => store);
  const isSupportRM = auth.id === application?.supportRm?.userId;
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);

  const {
    distributor,
    rm,
    subdistributorId = null,
    subDistributor,
    // filledBy = '',
    rm_id,
    distributor_id,
    rm_branch = '',
    distributor_branch = '',
    distributorChannel = '',
    custodian = '',
    supportRm,
    support_rm_id,
  } = application || {};

  const {
    name,
    id,
    user: { email: distributorEmail, phone: distributorPhone },
    distributorEmpCode: distributorEMPCode,
    euin: distributorEuin,
  } = distributor || {
    arnCode: '',
    name: '',
    id: null,
    user: { email: '', phone: '' },
    distributorEmpCode: '',
    euin: '',
  };

  const {
    empCode,
    user: { email: RMEmail = '', firstName: RMFirstName = '', phone: RMPhone = '' },
  } = (isSupportRM ? supportRm : rm) || { empCode: '', user: { email: '', phone: '' } };

  const {
    subDistributorCode: subdistributorCode,
    euin: subdistributorEuin,
    user: { firstName: subdistributorName, email: subdistributorEmail, phone: subdistributorPhone },
  } = subDistributor || {
    subDistributorCode: '',
    euin: '',
    user: { firstName: '', email: '', phone: '' },
  };

  const initialValues: DistributorDetailsProps = {
    distributorId: id,
    distributorName: name,
    loginType: rm_id !== null || support_rm_id !== null ? 'rm' : 'distributor',
    empCode,
    phone: RMPhone,
    email: RMEmail,
    firstName: RMFirstName,
    subdistributorId: subdistributorId === null ? subdistributorId : Number(subdistributorId),
    subdistributorName,
    subdistributorEmail,
    // filledBy,
    subdistributorPhone,
    subdistributorCode: distributorEMPCode || subdistributorCode,
    subdistributorEuin: distributorEuin || subdistributorEuin,
    distributorEmail,
    distributorPhone,
    rm_branch,
    distributor_branch,
    distributorChannel,
    rm_id,
    custodian: custodian ? custodian : CustodianMaster.length === 1 ? CustodianMaster[0] : '',
    saveType: 'save and proceed',
  };

  const [distributorsLoader, setDistributorsLoader] = useState(false);
  const [rmsList, setRmList] = useState<RMType[]>([]);
  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        if (Object.keys(application || {}).length && role === USER_ROLES.RM) {
          setDistributorsLoader(true);
          const res = (await dispatch(
            getDistributorsListForFilters({ isActive: true })
          )) as unknown as Distributor[];
          const distributors = res || [];
          const updatedDistributor =
            application?.distributor_id && !application.distributor.isActive
              ? [
                  ...distributors,
                  {
                    ...application.distributor,
                    subdistributors: [application.subDistributor],
                  } as Distributor,
                ]
              : distributors;
          let updateSubdistributor: SubDistributor[] = [];
          if (id && distributor?.type === DistributorTypesForUserManageMent.Company) {
            const getSubdistributors = (await dispatch(
              getDistributorById(id)
            )) as unknown as DistributorNewList;
            updateSubdistributor =
              getSubdistributors.subdistributors as unknown as SubDistributor[];
          }
          let updateRMList: RMType[] = [];
          if (isSupportRM) {
            const rms = (await dispatch(
              getRMsList({ roleType: 'amc_rm', isActive: true })
            )) as unknown as RMType[];
            updateRMList =
              application?.rm_id && !application.rm.isActive ? [...rms, application.rm] : rms;
          }
          const updatedDistributorWithSubDistributor = updatedDistributor
            .map((_distributor) => {
              if (_distributor.type === DistributorTypesForUserManageMent.Individual) {
                return _distributor;
              } else if (
                _distributor.type === DistributorTypesForUserManageMent.Company &&
                _distributor.subdistributors &&
                _distributor.subdistributors.length > 0
              ) {
                return _distributor;
              }
              return;
            })
            .filter((ele) => ele);
          if (isComponentActive) {
            setSelectedDistributor(updateSubdistributor);
            setDistributors(updatedDistributorWithSubDistributor as unknown as Distributor[]);
            setRmList(updateRMList);
            setDistributorsLoader(false);
          }
        } else {
          const { distributor, subDistributor } = application || {};
          setDistributors(distributor ? [distributor as Distributor] : []);
          setSelectedDistributor(
            subDistributor
              ? [{ ...subDistributor, id: Number(subDistributor.id) } as SubDistributor]
              : []
          );
        }
      } catch (e: unknown) {
        setDistributorsLoader(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      const {
        distributorId,
        subdistributorId,
        // filledBy,
        rm_branch,
        distributor_branch,
        distributorChannel,
        custodian,
        saveType,
      } = values;
      const {
        id,
        applicant1ReferenceId,
        currentStep = null,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const isSaveLater = saveType !== 'save and proceed';
      let updatedFeeSlabOrStrategy = {};
      let response = [] as StrategyType[];
      let responseFeeSlab = [] as FeeSlabsType[];
      const isDistributorPresent =
        !distributorId &&
        application?.distributor_id &&
        (application?.applicationstrategydetail?.strategyId ||
          application?.applicationfeeslab?.feeSlabId);
      if (distributorId || isDistributorPresent) {
        try {
          if (distributorId && Number(distributorId) !== Number(application?.distributor_id)) {
            const distributor = (await dispatch(
              getDistributorById(distributorId)
            )) as unknown as Distributor;
            response = distributor?.strategies as StrategyType[];
            responseFeeSlab = distributor?.feeSlabs as FeeSlabsType[];
          } else if (isDistributorPresent) {
            response = (await dispatch(getStarategyListOfDirect())) as unknown as StrategyType[];
            responseFeeSlab = (await dispatch(
              getFeeSlabListOfDirect()
            )) as unknown as FeeSlabsType[];
          }
          if (
            (application?.applicationfeeslab?.feeSlabId &&
              responseFeeSlab?.length > 0 &&
              !responseFeeSlab
                .map((ele) => ele?.id?.toString())
                .includes(application?.applicationfeeslab?.feeSlabId?.toString())) ||
            (application?.applicationstrategydetail?.strategyId &&
              response?.length > 0 &&
              !response
                .map((ele) => ele?.id?.toString())
                .includes(application?.applicationstrategydetail?.strategyId?.toString()))
          ) {
            updatedFeeSlabOrStrategy = {
              applicationstrategydetail: {
                ...application?.applicationstrategydetail,
                isActive: false,
              },
              applicationfeeslab: {
                ...application?.applicationfeeslab,
                isActive: false,
              },
            };
          }
        } catch (e) {
          console.error((e as Error).message);
        }
      }

      const checkApplication = applicationComparison(application, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        rm_branch,
        distributor_branch,
        distributor_id: distributorId,
        subdistributorId: subdistributorId ? subdistributorId.toString() : subdistributorId,
        distributorChannel,
        // filledBy: filledBy,
        custodian: custodian,
        currentStep: currentStep === null ? 1 : currentStep,
        rm_id: values.rm_id,
        ...updatedFeeSlabOrStrategy,
      });

      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              ...updatedFeeSlabOrStrategy,
              rm_branch,
              distributor_branch,
              distributor_id: distributorId,
              subdistributorId,
              distributorChannel,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 1,
              //currentStep === null ? 1 : currentStep
              // filledBy,
              custodian,
              rm_id: values.rm_id,
            },
            applicationId: id,
          })
        );
        !isSaveLater
          ? history.push('contributor-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('contributor-details', {
              id,
              applicant1ReferenceId: referenceIdForSaveProceedRoute,
            })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };
  const rmsListSorting = [...rmsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  const isFieldDisabled = [USER_ROLES.INVESTOR].includes(role);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(
        selectedDistributor,
        distributors,
        isSupportRM,
        rmsList
      )}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'This section specifies whether the application is from an RM/Distributor’s RM.'
            }
          />
          <Grid container rowSpacing={2} columnSpacing={5} sx={{ pt: '25px !important' }}>
            {[USER_ROLES.RM, rm_id && distributor_id && USER_ROLES.INVESTOR].includes(role) && (
              <Grid item xs={12}>
                <UseRadioGroup
                  formLabel=""
                  name={`loginType`}
                  items={[
                    { label: 'Direct', value: USER_ROLES.RM },
                    { label: 'Distributor', value: USER_ROLES.DISTRIBUTOR },
                  ]}
                />
              </Grid>
            )}
            {![USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR, USER_ROLES.POAAPPROVER].includes(
              role
            ) && values.loginType === USER_ROLES.RM ? (
              <RMDetail values={values} />
            ) : (
              <DistributorDetail
                distributors={distributors}
                values={values}
                selectedDistributor={selectedDistributor}
                setSelectedDistributor={(subdistributors) =>
                  setSelectedDistributor(subdistributors)
                }
                loader={distributorsLoader}
              />
            )}
            {isSupportRM && rmsList?.length > 0 && (
              <>
                <GridItem>
                  <SearchableSelect
                    name="rm_id"
                    label="AMC's RM"
                    items={rmsListSorting.sort().map((item) => ({
                      key: item.name,
                      value: item.id,
                    }))}
                    searchFieldPlaceholder={'Search RM'}
                  />
                </GridItem>
                {values.rm_id && (
                  <>
                    <GridItem>
                      <MFTextField
                        name="AMCemail"
                        label="AMC's RM Email ID"
                        placeholder="Enter AMC's RM Email ID"
                        value={rmsList.find((item) => item.id === values.rm_id)?.user?.email}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem>
                      <MFTextField
                        name="AMCphone"
                        label="AMC's RM Mobile Number"
                        placeholder="Enter AMC's RM Mobile Number"
                        value={rmsList.find((item) => item.id === values.rm_id)?.user?.phone}
                        disabled={true}
                      />
                    </GridItem>
                  </>
                )}
              </>
            )}

            <Grid item xs={12}>
              <SubHeading sx={{ color: 'common.primary', letterSpacing: 0.5 + 'px' }}>
                {'Custodian Details'}
              </SubHeading>
            </Grid>
            <GridItem>
              <MFSelectField
                name={`custodian`}
                label={'Custodian Name'}
                items={CustodianMaster.map((item) => ({
                  key: item,
                  value: item,
                }))}
                applyLabelStyles={true}
                disabled={
                  (values.loginType === USER_ROLES.DISTRIBUTOR && distributors.length === 0) ||
                  isFieldDisabled ||
                  CustodianMaster.length === 1
                }
              />
            </GridItem>
          </Grid>
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Box>
      )}
    </Formik>
  );
}
