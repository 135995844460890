import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps, RMType } from '../../redux-store/types/api-types';
import { Gridstyles } from '../Investments/investor-application';
import { KeyValue } from './contributor';
import { SubHeading } from '../investors/components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { getRMsList } from '../../redux-store/actions';
import { async } from 'q';
import { AmcBranchMaster } from '../../utils/constant';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { auth } = useSelector((store: RootStateType) => store);
  const isSupportRM = auth.id === application?.supportRm?.userId;
  const [rmsList, setRmList] = useState<RMType[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    (async function () {
      let updateRMList: RMType[] = [];
      if (isSupportRM) {
        const rms = (await dispatch(
          getRMsList({ roleType: 'amc_rm', isActive: true })
        )) as unknown as RMType[];
        updateRMList =
          application?.rm_id && !application.rm.isActive ? [...rms, application.rm] : rms;
      }
      setRmList(updateRMList);
    })();
  }, []);
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM/Distributor Details
              </Typography>
            </Grid>
            {/* <Box sx={{ width: '100%' }}>
              <SubHeading
                sx={{
                  color: 'common.black',
                  letterSpacing: 0.5 + 'px',
                  padding: { xs: '10px 10px', sm: '10px 30px' },
                }}>
                RM Details
              </SubHeading>
              <Gridstyles>
                <Grid
                  item
                  container
                  pt={1}
                  pb={5}
                  rowSpacing={6}
                  columnSpacing={13}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start">
                  <KeyValue
                    title={'RM Name'}
                    description={isSupportRM ? application?.supportRm?.name : application?.rm?.name}
                  />
                  <KeyValue
                    title={"RM's Email Address"}
                    description={
                      isSupportRM
                        ? application?.supportRm?.user?.email
                        : application?.rm?.user?.email
                    }
                  />
                  <KeyValue
                    title={"RM's Mobile Number"}
                    description={
                      isSupportRM
                        ? application?.supportRm?.user?.phone
                        : application?.rm?.user?.phone
                    }
                  />
                  <KeyValue title={'AMC Branch'} description={application?.rm_branch} />
                  {isSupportRM && rmsList?.length > 0 && (
                    <>
                      {console.log(rmsList)}
                      <KeyValue title={"AMC's RM"} description={application?.rm?.name} />

                      {application.support_rm_id && (
                        <>
                          <KeyValue
                            title={"AMC's RM Email ID"}
                            description={application?.rm?.user?.email}
                          />
                          <KeyValue
                            title={"AMC's RM Mobile Number"}
                            description={application?.rm?.user?.phone}
                          />
                        </>
                      )}
                    </>
                  )}
                  <KeyValue title={'RM Custodian Name'} description={application?.custodian} />
                </Grid>
              </Gridstyles>
            </Box>
            {application?.distributor && (
              <Box sx={{ width: '100%' }}>
                <SubHeading
                  sx={{
                    color: 'common.black',
                    letterSpacing: 0.5 + 'px',
                    padding: { xs: '10px 10px', sm: '10px 30px' },
                  }}>
                  Distributor Details
                </SubHeading> 
                <Gridstyles>
                  <Grid
                    item
                    container
                    pt={1}
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue
                      title={'Distributor Code'}
                      description={application?.distributor?.arnCode}
                    />
                    <KeyValue
                      title={'Distributor/IFA Name'}
                      description={application?.distributor?.name}
                    />
                    <KeyValue
                      title={"Distributor's RM Name"}
                      description={application?.subDistributor?.user?.firstName}
                    />
                    <KeyValue
                      title={'Distributor RM/IFA Email'}
                      description={
                        application?.subDistributor?.user?.email ||
                        application?.distributor?.user?.email
                      }
                    />
                    <KeyValue title={'AMCs RM/Advisor Name - Location'} description={null} />
                    <KeyValue title={'AMC Branch'} description={application?.rm_branch} />
                    <KeyValue
                      title={'Distributor Location'}
                      description={application?.distributor_branch}
                    />
                    <KeyValue title={'Filled By'} description={application?.filledBy} />
                    <KeyValue
                      title={'Distributor Custodian Name'}
                      description={application?.custodian}
                    />
                  </Grid>
                </Gridstyles>
              </Box> */}

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                {/* <KeyValue
                  title={'Distributor ARN Code'}
                  description={application?.distributor?.arnCode}
                /> */}
                <KeyValue title={'Distributor Name'} description={application?.distributor?.name} />
                <KeyValue
                  title={'Distributor Email Id'}
                  description={application?.distributor?.user?.email}
                />
                <KeyValue
                  title={'Distributor Phone Number'}
                  description={application?.distributor?.user?.phone}
                />
                <KeyValue
                  title={'Distributor RM Name'}
                  description={application?.subDistributor?.user?.firstName}
                />
                <KeyValue
                  title={'Distributor RM Email Id'}
                  description={application?.subDistributor?.user?.email}
                />
                <KeyValue
                  title={'Distributor RM Phone Number'}
                  description={application?.subDistributor?.user?.phone}
                />
                <KeyValue
                  title={'Sub Distributor Code'}
                  description={
                    application?.distributor?.distributorEmpCode ||
                    application?.subDistributor?.subDistributorCode
                  }
                />
                <KeyValue
                  title={'EUIN'}
                  description={application?.distributor?.euin || application?.subDistributor?.euin}
                />
                <KeyValue
                  title={application.distributor_id ? 'AMCs RM Email Id' : 'ARN Code'}
                  description={
                    application.distributor_id
                      ? application?.rm?.user?.email
                      : application?.rm?.empCode
                  }
                />
                <KeyValue
                  title={'Distributor Channel'}
                  description={application.distributorChannel}
                />
                <KeyValue
                  title={'AMC Branch'}
                  description={AmcBranchMaster[application?.rm_branch || '']}
                />
                <KeyValue
                  title={'Distributor Location'}
                  description={application?.distributor_branch}
                />
                <KeyValue
                  title={'Distributor Custodian Name'}
                  description={application?.custodian}
                />
              </Grid>
            </Gridstyles>
            {/* </Box>
            )} */}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
