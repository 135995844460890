import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { SubHeading } from '../investors/components';
import { DataTable } from '../DataTable';
import { Gridstyles, Item, ItemDetails } from '.';

export default function RelatedPartyConsent({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const headers = [
    {
      header: 'Security',
      label: 'security',
      valueGetter: (row: any) => row.securityType || 'N/A',
    },
    {
      header: 'Limit  for  investment in single associate / related party (as % of client’s AUM)',
      label: 'singleLimit',
      valueGetter: (row: any) => row.singleAssociateLimit + ' %' || 'N/A',
    },
    {
      header:
        'Limit  for  investment  across multiple associates/related parties (as % of client’s AUM)',
      label: 'multipleLimit',
      isVisibleInMobileView: (row: any) => row.securityType !== 'Equity + Debt + Hybrid Securities',
      valueGetter: (row: any) =>
        (row.multipleAssociateLimit && row.multipleAssociateLimit + ' %') || '',
    },
  ];

  const headersDissent: any = [
    {
      header: 'Security',
      label: 'security',
      valueGetter: (row: any) => row.securityType || 'N/A',
    },
    {
      header: 'Limit  for  investment in single associate / related party (as % of client’s AUM)',
      label: 'singleLimit',
      valueGetter: (row: any) => 0 + ' %' || 'N/A',
    },
    {
      header:
        'Limit  for  investment  across multiple associates/related parties (as % of client’s AUM)',
      label: 'multipleLimit',
      isVisibleInMobileView: (row: any) => row.securityType !== 'Equity + Debt + Hybrid Securities',
      valueGetter: (row: any) => 0 + ' %' || '',
    },
  ];
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Related Party Consent
            </Typography>
            <React.Fragment>
              <SubHeading
                sx={{
                  color: 'common.black',
                  letterSpacing: 0.5 + 'px',
                  padding: { xs: '10px 10px', sm: '10px 30px' },
                  mb: 4,
                }}>
                Limits on investment
              </SubHeading>
              <Gridstyles>
                <Box sx={{ mb: 4 }}>
                  <Item
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: 16,
                      fontWeight: 400,
                    }}>
                    {application?.relatedPartyLimit}
                  </Item>
                  <ItemDetails>
                    {application?.relatedPartyLimit === 'consent'
                      ? 'Portfolio Manager can invest in the securities of its associates/related parties within the limits agreed upon at para 5 above, or as per following limits, subject to regulatory limits'
                      : 'Portfolio Manager cannot invest in the securities of its associates/related parties.'}
                  </ItemDetails>
                  <br />
                  <ItemDetails>
                    {application?.relatedPartyLimit === 'consent' &&
                      'The maximum allowed limits as per SEBI regulations have been provided below for each security. Investor can modify the limits in accordance with the prescribed regulations.'}
                  </ItemDetails>
                </Box>
                <Box sx={{ width: '100%' }}>
                  <DataTable
                    tableData={application.relatedpartyconsents?.sort(
                      (consent1, consent2) => Number(consent1.id) - Number(consent2.id)
                    )}
                    tableHeader={
                      application?.relatedPartyLimit === 'dissent' ? headersDissent : headers
                    }
                    renderAdditionalRow={false}
                    tableHeaderCustomStyles={{
                      '.MuiTableCell-head': {
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'primary.main',
                        textAlign: 'left',
                        px: 2,
                        py: 1.5,
                        width: '30%',
                        bgcolor: '#F3F8FB',
                      },
                    }}
                    rowCustomStyles={{
                      '.MuiTableCell-root': {
                        fontFamily: 'Poppins, sans-serif',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(19, 24, 54, 0.7)',
                        px: 2,
                        py: 1.5,
                        width: '30%',
                        overflowWrap: 'anywhere',
                        textAlign: 'left',
                        verticalAlign: 'top',
                      },
                      '&:nth-child(odd)': {
                        bgcolor: 'rgba(19, 24, 54, 0.02)',
                      },
                    }}
                    boxStyles={{
                      minHeight: '0px',
                      minWidth: 450,
                      maxHeight: 'unset',
                      mb: 4,
                    }}
                  />
                </Box>
              </Gridstyles>
            </React.Fragment>
            <React.Fragment>
              <SubHeading
                sx={{
                  color: 'common.black',
                  letterSpacing: 0.5 + 'px',
                  padding: { xs: '10px 10px', sm: '10px 30px' },
                  mb: 4,
                }}>
                Waiver from rebalancing of portfolio on passive breach of investment limits
              </SubHeading>
              <Gridstyles>
                <Box sx={{ mb: 4 }}>
                  <Item
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: 16,
                      fontWeight: 400,
                    }}>
                    {application?.relatedPartyWaiver}
                  </Item>
                  <ItemDetails>
                    {application?.relatedPartyWaiver === 'consent'
                      ? 'Portfolio Manager need not rebalance the portfolio on passive breach of investment limits.'
                      : 'Portfolio Manager should rebalance the portfolio on passive breach of investment limits within prescribed regulatory time lines as amended from time to time.'}
                  </ItemDetails>
                </Box>
              </Gridstyles>
            </React.Fragment>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
