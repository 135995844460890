import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, useField, useFormikContext, validateYupSchema, yupToFormErrors } from 'formik';
import UseRadioGroup from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import AddIcon from '@mui/icons-material/Add';
import { Applicant, Bank, DocumentType } from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { updateApplication, pennyDropVerification } from '../../redux-store/actions/application';
import MFCheckbox from '../../lib/formik/Checkbox';
import {
  applicationComparison,
  applyRoleBasedStatus,
  checkInitialApplicationStatusIsChecker,
  getApplicantName,
  getBankAddress,
  getDisableStatusForjointHolder,
  saveForLater,
  saveForLaterInvestorPhotoCapture,
} from '../../utils/utilityFunctions';
import { Box } from '@mui/system';
import { ConfirmationDialog } from '../commonComponents';
import {
  BankDetailsFromChequeResponse,
  getBankDetailsFromCheque,
  getIFSCDetails,
} from '../../redux-store/actions';
import { IfscType } from '../../redux-store/types/mdms';
import { bankDetailsSchema } from '../../utils/schema';
import {
  applicantStatusMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  IS_PENNYDROP_APPLICABLE_FOR_AMC,
  NRIBankAccountTypeMaster,
  USER_ROLES,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
} from '../../utils/constant';
import { Notes } from './components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { logout, showError } from '../../redux-store/actions/auth';
import { useSnackbar } from 'notistack';
import { accountNumberRegex, ifscRegex } from '../../utils/regex';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { FilePond } from 'react-filepond';
import { values } from 'lodash';

type updatedBank = Bank & {
  loading: boolean;
  bankAddress: string;
  documentUploadLoader: boolean;
};

export const bankObject: updatedBank = {
  ifscCode: '',
  bankAccountNumber: '',
  bankAccountType: '',
  bankName: '',
  bankBranch: '',
  defaultBankAccount: true,
  isActive: true,
  pennydropCheck: false,
  pennydropVerified: false,
  address1: '',
  address2: '',
  address3: '',
  micrCode: '',
  bankAddress: '',
  loading: false,
  documentId: '',
  documentUploadLoader: false,
};

type Values = {
  status?: string;
  applicationType?: string;
  banks: updatedBank[];
  saveType: string;
};

const initialValues: Values = {
  status: '',
  applicationType: '',
  banks: [bankObject],
  saveType: 'save and proceed',
};

function BankTextField({
  name,
  bankFieldName,
  branchFieldName,
  disabled = false,
  pennydropFieldCheck,
  address1FieldName,
  address2FieldName,
  address3FieldName,
  micrFieldName,
  addressFieldName,
}: {
  name: string;
  bankFieldName: string;
  branchFieldName: string;
  disabled?: boolean;
  pennydropFieldCheck: string;
  address1FieldName: string;
  address2FieldName: string;
  address3FieldName: string;
  micrFieldName: string;
  addressFieldName: string;
}): JSX.Element {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [_field, _meta, helpers] = useField(name);
  const [code, setCode] = useState<string>(_field.value);
  const { setFieldValue } = useFormikContext();
  const { setValue, setError } = helpers;
  const inputRef = useRef<HTMLInputElement>();

  const getIFSCResponse = (value: string) => {
    let isComponentAlive = true;
    if (!value || value.length < 12) {
      setValue((value || '').toUpperCase());
      setFieldValue(bankFieldName, '');
      setFieldValue(branchFieldName, '');
      setFieldValue(address1FieldName, '');
      setFieldValue(address2FieldName, '');
      setFieldValue(address3FieldName, '');
      setFieldValue(micrFieldName, '');
      setFieldValue(addressFieldName, '');
    }
    if (value?.length !== 11) {
      return;
    }
    inputRef.current && inputRef.current.blur();
    (async function () {
      try {
        const { BankName, BankCity, BankAdd1, BankAdd2, BankAdd3, MicrNo } = (await dispatch(
          getIFSCDetails(value)
        )) as unknown as IfscType;
        if (!isComponentAlive) {
          return;
        }
        setFieldValue(bankFieldName, BankName);
        setFieldValue(branchFieldName, BankCity);
        setFieldValue(address1FieldName, BankAdd1);
        setFieldValue(address2FieldName, BankAdd2);
        setFieldValue(address3FieldName, BankAdd3);
        setFieldValue(micrFieldName, MicrNo);
        setFieldValue(
          addressFieldName,
          (BankAdd2 ? BankAdd1 + ',' : BankAdd1) + (BankAdd3 ? BankAdd2 + ',' : BankAdd2) + BankAdd3
        );
      } catch (error) {
        setError('IFSC Code is invalid');
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  };
  function onChange({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
    setFieldValue(pennydropFieldCheck, false);
    setCode(value);
    getIFSCResponse(value);
  }
  return (
    <MFTextField
      name={name}
      label="IFSC Code *"
      placeholder="Enter IFSC Code"
      onChange={onChange}
      inputRef={inputRef}
      disabled={disabled || getDisableStatusForjointHolder(role, referenceDetail.applicant_type)}
      trimOnBlur={false}
    />
  );
}

const ChequeUpload = ({
  bankIndex,
  setValues,
  values,
  getFirstApplicantDocuments,
  setGetFirstApplicantDocuments,
  disabled,
}: {
  bankIndex: number;
  setValues: (values: React.SetStateAction<Values>, shouldValidate?: boolean) => void;
  values: Values;
  getFirstApplicantDocuments: DocumentType;
  setGetFirstApplicantDocuments: React.Dispatch<React.SetStateAction<DocumentType>>;
  disabled: boolean;
}): JSX.Element => {
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const { token: investorAuthToken } = useSelector((store: RootStateType) => store.investor);
  const { application } = useSelector((store: RootStateType) => store.application);
  const dispatch = useDispatch();

  const handleFileChange = async (documentId: string) => {
    try {
      const bankResponse = (await dispatch(
        getBankDetailsFromCheque({ fileId: documentId, applicationId: application?.id || '' })
      )) as unknown as BankDetailsFromChequeResponse;
      setValues({
        ...values,
        banks: values.banks.map((bank, index) => {
          if (index === bankIndex) {
            return {
              ...bank,
              ifscCode: bankResponse.IFSCCode,
              bankAccountNumber: bankResponse.AccountNumber,
              micrCode: bankResponse.MicrNo,
              address1: bankResponse.BankAdd1,
              address2: bankResponse.BankAdd2,
              address3: bankResponse.BankAdd3,
              bankName: bankResponse.BankName,
              bankBranch: bankResponse.BankCity,
              bankAddress:
                (bankResponse.BankAdd2 ? bankResponse.BankAdd1 + ',' : bankResponse.BankAdd1) +
                (bankResponse.BankAdd3 ? bankResponse.BankAdd2 + ',' : bankResponse.BankAdd2) +
                bankResponse.BankAdd3,
              pennydropCheck: false,
              pennydropVerified: false,
              documentId,
              documentUploadLoader: false,
            };
          }
          return bank;
        }),
      });
    } catch (e) {
      setValues({
        ...values,
        banks: values.banks.map((bank, index) =>
          index === bankIndex ? { ...bank, documentUploadLoader: false } : bank
        ),
      });
      console.error((e as Error).message);
    }
  };
  return (
    <Grid container sx={{ alignItems: 'center', mb: 1 }}>
      <Grid item xs={12} sm={6}>
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 500,
          }}>
          Cancelled Cheque
          {/* <Typography
            sx={{
              color: 'primary.main',
              fontWeight: 400,
              fontSize: 14,
            }}>
            (Please upload same document In Document Details, if this bank is your default bank
            account)
          </Typography> */}
        </Typography>
        <Typography sx={{ fontSize: 12 }}>Min Size: 2KB, Max Size: 2MB</Typography>
        <Typography sx={{ fontSize: 12 }}>Supports: png, jpg, jpeg</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
              cursor: disabled ? 'not-allowed' : 'pointer',
            },
            '.filepond--drop-label': {
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
            // pt: 4,
          }}>
          {values.banks[bankIndex].defaultBankAccount &&
          getFirstApplicantDocuments &&
          Object.keys(getFirstApplicantDocuments).length > 0 &&
          getFirstApplicantDocuments.file &&
          values.banks[bankIndex].documentId === getFirstApplicantDocuments.documentId ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px dashed #AAB2BA',
                borderRadius: '6px',
                mt: '30px',
                minHeight: '65px',
              }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CardMedia
                  component="img"
                  src={
                    getFirstApplicantDocuments.file.thumbnailUrl || '/images/photo-placeholder.jpg'
                  }
                  alt="aadhaar"
                  width="50"
                  height="50"
                  sx={{ display: 'flex', width: 'unset', mx: 1 }}
                />
              </Box>
              <Typography
                component="span"
                sx={{
                  whiteSpace: 'pre-wrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: 1,
                }}>
                {getFirstApplicantDocuments.file.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 1 },
                  flexWrap: 'wrap',
                }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    const { file, ...rest } = getFirstApplicantDocuments;
                    setGetFirstApplicantDocuments({ ...rest });
                    setValues({
                      ...values,
                      banks: values.banks.map((bank, index) => {
                        if (index === bankIndex) {
                          return {
                            ...bank,
                            documentId: '',
                          };
                        }
                        return bank;
                      }),
                    });
                  }}
                  sx={{ mb: 1 }}
                  disabled={disabled}>
                  Replace
                </Button>
              </Box>
            </Box>
          ) : (
            <FilePond
              files={files}
              server={{
                process: {
                  url: '/api/files/files',
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer ${authToken || investorAuthToken}`,
                  },
                  onload: (response): string => {
                    const result = JSON.parse(response);
                    result?.name && result?.id && handleFileChange(result.id);
                    return response;
                  },
                },
              }}
              onaddfilestart={() => {
                setValues({
                  ...values,
                  banks: values.banks.map((bank, index) =>
                    index === bankIndex ? { ...bank, documentUploadLoader: true } : bank
                  ),
                });
              }}
              onaddfile={(error, file) => {
                if (error) {
                  setValues({
                    ...values,
                    banks: values.banks.map((bank, index) =>
                      index === bankIndex ? { ...bank, documentUploadLoader: false } : bank
                    ),
                  });
                }
              }}
              onprocessfile={(error, file) => {
                if (error) {
                  if (error.code === 401) {
                    dispatch(logout());
                  } else {
                    setValues({
                      ...values,
                      banks: values.banks.map((bank, index) =>
                        index === bankIndex ? { ...bank, documentUploadLoader: false } : bank
                      ),
                    });
                  }
                }
              }}
              onupdatefiles={(fileItems) => {
                setFiles(fileItems);
              }}
              onprocessfilerevert={() => {
                if (
                  getFirstApplicantDocuments &&
                  Object.keys(getFirstApplicantDocuments).length > 0
                ) {
                  const { file, ...rest } = getFirstApplicantDocuments;
                  setGetFirstApplicantDocuments({ ...rest });
                }

                setValues({
                  ...values,
                  banks: values.banks.map((bank, index) => {
                    if (index === bankIndex) {
                      return {
                        ...bank,
                        documentId: '',
                      };
                    }
                    return bank;
                  }),
                });
              }}
              name="file"
              maxFileSize={'2MB'}
              allowMultiple={false}
              maxFiles={1}
              acceptedFileTypes={INVESTOR_IMAGE_UPLOAD_FORMATS}
              labelIdle={`<span class="filepond--label-action">Upload</span>`}
              labelFileTypeNotAllowed={'Invalid file type'}
              labelMaxFileSize={'Max file size is 2 MB'}
              fileValidateTypeLabelExpectedTypesMap={{
                'image/jpeg': '.jpeg',
                'image/jpg': '.jpg',
                'image/png': '.png',
                // 'application/pdf': '.pdf',
              }}
              imagePreviewHeight={100}
              disabled={disabled}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

export default function BankDetails(): JSX.Element {
  const [banksData, setBanksData] = useState(initialValues);
  const [bankToDelete, setBankToDelete] = useState<{ bank: Bank; index: number } | null>(null);
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { id = '', applicants = [] } = application || {};
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [pennyDropStatusPopUp, setPennyDropStatusPopUp] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [addBankAfterPennyDrop, setAddBankAfterPennyDrop] = useState(bankObject);
  const [addBankAfterPennyDropCheck, setAddBankAfterPennyDropCheck] = useState(false);
  const referenceDetail = useSelector((store: RootStateType) => store.refrenceIdReducer);
  const [getFirstApplicantDocuments, setGetFirstApplicantDocuments] = useState<DocumentType>();
  const { esignReferenceId } = referenceDetail;

  const handleClose = () => {
    setOpen(false);
  };

  const handlePopUpClose = () => {
    setPennyDropStatusPopUp(false);
  };

  useEffect(() => {
    const { banks = [], applicationType, applicants = [] } = application || {};

    const getApplicantDocuments = applicants
      .find((applicant) => applicant.applicant_type === '1')
      ?.documents?.find((document) => document.documentType === 'bankAccountProof') as DocumentType;
    setGetFirstApplicantDocuments(getApplicantDocuments),
      setBanksData({
        ...banksData,
        applicationType: applicationType,
        status: applicants.length ? applicants[0].status : '',
        banks: banks.length
          ? addBankAfterPennyDropCheck && banks.length < 2
            ? [
                ...banks.map((bank) => ({
                  ...bank,
                  defaultBankAccount: !!bank.defaultBankAccount || true,
                  pennydropCheck: !!bank.pennydropCheck,
                  pennydropVerified: !!bank.pennydropVerified,
                  loading: false,
                  bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
                  documentUploadLoader: false,
                })),
                { ...addBankAfterPennyDrop },
              ]
            : banks.map((bank) => ({
                ...bank,
                defaultBankAccount: !!bank.defaultBankAccount || true,
                pennydropCheck: !!bank.pennydropCheck,
                pennydropVerified: !!bank.pennydropVerified,
                loading: false,
                bankAddress: getBankAddress(bank.address1, bank.address2, bank.address3),
                documentUploadLoader: false,
              }))
          : [{ ...bankObject, applicationId: id }],
      });
    setAddBankAfterPennyDrop(bankObject);
    setAddBankAfterPennyDropCheck(false);
  }, [application]);

  const { referenceId: referenceIdForInvestorPhotoCapture } = useParams<{ referenceId: string }>();

  const updateApplicantsWithDefaultBankAccountProof = (
    applicants: Partial<Applicant>[],
    updatedBanks: Bank[],
    checkDocumentIdForOtherBank: Bank | undefined
  ): Partial<Applicant>[] => {
    const getDefaultBank = updatedBanks.find((_bank) => _bank.defaultBankAccount);
    return applicants?.map((applicant) => {
      const { documents = [] } = applicant;
      const documentTypeCheck = documents
        ?.map((doc) => doc.documentType)
        ?.includes('bankAccountProof');
      if (Number(applicant.applicant_type) === 1) {
        return {
          ...applicant,
          documents: documents.length
            ? documentTypeCheck
              ? (documents
                  .map((document) => {
                    if (document.documentType === 'bankAccountProof') {
                      if (getDefaultBank?.documentId) {
                        return {
                          ...document,
                          documentId: getDefaultBank?.documentId,
                          documentName: 'Cancelled cheque',
                        };
                      } else if (
                        !getDefaultBank?.documentId &&
                        checkDocumentIdForOtherBank &&
                        Object.keys(checkDocumentIdForOtherBank)?.length > 0
                      ) {
                        return;
                      } else {
                        return document;
                      }
                    }
                    return document;
                  })
                  .filter((ele) => ele) as DocumentType[])
              : getDefaultBank?.documentId
              ? [
                  ...documents,
                  {
                    documentId: getDefaultBank?.documentId,
                    documentType: 'bankAccountProof',
                    documentName: 'Cancelled cheque',
                    isActive: true,
                  },
                ]
              : documents
            : getDefaultBank?.documentId
            ? [
                {
                  documentId: getDefaultBank?.documentId,
                  documentType: 'bankAccountProof',
                  documentName: 'Cancelled cheque',
                  isActive: true,
                },
              ]
            : [],
        };
      }
      return applicant;
    });
  };

  const handleSubmit = async (values: Values) => {
    try {
      const { banks, saveType } = values;
      if (IS_PENNYDROP_APPLICABLE_FOR_AMC) {
        const isAllBanksPennyChecked = banks.every((bank) => bank.pennydropCheck);
        if (!isAllBanksPennyChecked) {
          throw 'Please make sure that all the banks are verified';
        }
      }
      const {
        banks: existingBanks = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationType,
        status,
        hasPOA,
        applicationNumber,
        applicants = [],
      } = application || {};
      const updatedBanks = banks.map((bank, ind) => ({
        ...(existingBanks[ind] || {}),
        ...bank,
        bankAccountType:
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
          !['huf'].includes(application?.applicants[0]?.investorType || '')
            ? BankAccountTypeMaster.Current
            : bank.bankAccountType,
        documentId: bank.defaultBankAccount ? bank.documentId : '',
      }));
      const checkDocumentIdForOtherBank = banks.find(
        (_bank) => !_bank.defaultBankAccount && _bank.documentId
      );
      const UpdatedApplicants = updateApplicantsWithDefaultBankAccountProof(
        applicants,
        updatedBanks,
        checkDocumentIdForOtherBank
      );
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents?.sort(
                  (doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)
                ),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          banks: application?.banks?.sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
        },
        {
          ...application,
          applicants: UpdatedApplicants.map((applicant) => {
            return {
              ...applicant,
              documents: applicant.documents?.sort(
                (doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)
              ),
            };
          }).sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          banks: updatedBanks
            .map((bank) => {
              const { loading, bankAddress, documentUploadLoader, ...rest } = bank;
              return { ...rest };
            })
            .sort((bank1, bank2) => Number(bank1.id) - Number(bank2.id)),
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > 3
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > 6
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';

      //save for later route for investor at liveliness
      const investorEditSaveelaterRoute =
        referenceIdForInvestorPhotoCapture &&
        saveForLaterInvestorPhotoCapture(referenceIdForInvestorPhotoCapture, application);
      const referenceIdForSaveProceedRoute = referenceIdForInvestorPhotoCapture
        ? referenceIdForInvestorPhotoCapture
        : referenceDetail.esignReferenceId;

      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: UpdatedApplicants,
              banks: updatedBanks,
              status:
                !hasPOA &&
                checkInitialApplicationStatusIsChecker(application) &&
                status !== 'draft' &&
                applyRoleBasedStatus(role) &&
                applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 4 : 7,
              // applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              //   ? !!currentStep && currentStep > 4
              //     ? currentStep
              //     : Number(currentStep) + 1
              //   : !!currentStep && currentStep > 6
              //   ? currentStep
              //   : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('document-details', { id, esignReferenceId })
            : history.push('risk-profiles', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
            ? history.push('document-details', { id, esignReferenceId })
            : history.push('risk-profiles', {
                id,
                applicant1ReferenceId: referenceIdForSaveProceedRoute,
              })
          : referenceIdForInvestorPhotoCapture
          ? history.push(
              investorEditSaveelaterRoute.routePath,
              investorEditSaveelaterRoute.routeState
            )
          : history.push(saveForLater(role, id, referenceDetail.esignReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.POAAPPROVER].includes(role);

  const handleBankVerification = async (values: Values, ind: number) => {
    const {
      banks: existingBanks = [],
      currentStep,
      applicationType,
      hasPOA,
      status,
    } = application || {};
    const { banks, saveType } = values;
    setBanksData({
      ...banksData,
      banks: banks.map((bank, index) => (index === ind ? { ...bank, loading: true } : bank)),
    });
    try {
      if (
        !banks[ind].ifscCode ||
        !banks[ind].bankAccountNumber ||
        (!banks[ind].bankAccountType && applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL) ||
        (!banks[ind].bankAccountType &&
          applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
          ['huf'].includes(application?.applicants[0].investorType || ''))
      ) {
        throw `Please fill all the required(*) fields in  ${getApplicantName(
          ind + 1,
          true
        )} Bank Account Details`;
      }
      if (!ifscRegex.test(banks[ind].ifscCode)) {
        throw `Invalid IFSC Code in  ${getApplicantName(ind + 1, true)} Bank Account Details`;
      }
      if (!accountNumberRegex.test(banks[ind].bankAccountNumber)) {
        throw `Please enter valid Account Number in  ${getApplicantName(
          ind + 1,
          true
        )} Bank Account Details`;
      }
      banks.map((bank, index) => {
        if (index !== ind && bank.bankAccountNumber === banks[ind].bankAccountNumber) {
          throw `Account number already used`;
        }
      });
      const getDefaultBankType = values.banks.map((_bank) => _bank.defaultBankAccount);
      if (!getDefaultBankType.includes(true)) {
        throw `Please check the default bank account`;
      }
      const checkforDefaultBankIsPennydropChecked = values.banks.map((bank) => {
        if (bank.defaultBankAccount && bank.pennydropCheck) {
          return true;
        }
        return false;
      });
      if (
        values.banks.map((bank) => bank.id).filter((ele) => ele).length &&
        !banks[ind].defaultBankAccount &&
        !checkforDefaultBankIsPennydropChecked.includes(true)
      ) {
        throw `Please verify default bank account`;
      }
      const pennyDropResponse = (await dispatch(
        pennyDropVerification({
          ...banks[ind],
          bankAccountType:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
            !['huf'].includes(application?.applicants[0].investorType || '')
              ? BankAccountTypeMaster.Current
              : banks[ind].bankAccountType,
          documentId: banks[ind].defaultBankAccount ? banks[ind].documentId : '',
        })
      )) as unknown as Bank;
      const isSaveLater = saveType !== 'save and proceed';
      const updatedBanks = banks
        .map((bank, index) => {
          if (ind === index) {
            return {
              ...bank,
              ...pennyDropResponse,
            };
          }
          return bank.id && bank.pennydropCheck && bank;
        })
        .filter((ele) => ele) as Bank[];
      const checkDocumentIdForOtherBank = updatedBanks.find(
        (_bank) => !_bank.defaultBankAccount && _bank.documentId
      );
      await dispatch(
        updateApplication({
          body: {
            ...application,
            applicants: updateApplicantsWithDefaultBankAccountProof(
              applicants,
              updatedBanks,
              checkDocumentIdForOtherBank
            ),
            banks: updatedBanks,
            status:
              !hasPOA &&
              checkInitialApplicationStatusIsChecker(application) &&
              status !== 'draft' &&
              applyRoleBasedStatus(role) &&
              applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                ? 'sent_to_amc_approver'
                : status,
            currentStep: applicationType === APPLICATION_TYPE.NON_INDIVIDUAL ? 3 : 6,
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      setBanksData({
        ...banksData,
        banks: banks.map((bank, index) => {
          if (index === ind) {
            return { ...bank, ...pennyDropResponse, loading: false };
          }
          if (!bank.id) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          } else if (
            bank.id &&
            !(JSON.stringify(existingBanks[index]) === JSON.stringify(banks[index]))
          ) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          }
          return bank;
        }),
      });
      if (pennyDropResponse.pennydropVerified) {
        enqueueSnackbar('Verified Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      if (pennyDropResponse.defaultBankAccount && !pennyDropResponse.pennydropVerified) {
        setPennyDropStatusPopUp(true);
      }
    } catch (e) {
      setBanksData({
        ...banksData,
        banks: banks.map((bank, index) => {
          if (index === ind) {
            return { ...bank, loading: false };
          }
          if (!bank.id) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          } else if (
            bank.id &&
            !(JSON.stringify(existingBanks[index]) === JSON.stringify(banks[index]))
          ) {
            setAddBankAfterPennyDropCheck(true);
            setAddBankAfterPennyDrop(bank);
            return bank;
          }
          return bank;
        }),
      });
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const pennyDropCheck = (values: Values): boolean => {
    return values.banks
      .map((bank) => {
        if (bank.loading || bank.documentUploadLoader) {
          return true;
        }
        return false;
      })
      .includes(true);
  };

  return (
    <Formik
      initialValues={banksData}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, bankDetailsSchema(applicants), true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setFieldValue, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Notes
            displayContent={
              'The below mentioned bank details will be used for principal payouts, Distribution payouts, and Redemption payouts'
            }
          />
          {values.banks.map((bank, index) => (
            <React.Fragment key={index}>
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                {/* {getApplicantName(index + 1, true)}  */}
                Bank Account Details
                {index !== 0 && ![USER_ROLES.POAAPPROVER].includes(role) && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                      cursor: pennyDropCheck(values) ? 'not-allowed' : 'pointer',
                      pointerEvents: pennyDropCheck(values) ? 'none' : 'all',
                    }}
                    onClick={() => setBankToDelete({ bank, index })}>
                    <Typography
                      sx={{
                        // color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                        fontSize: 13,
                        ml: 'auto',
                        letterSpacing: '-0.2px',
                        // cursor: pennyDropCheck(values) ? 'not-allowed' : 'pointer',

                        //disabled={values.banks.every((bank) => bank.loading)}
                      }}>
                      Remove Bank{' '}
                    </Typography>
                    <IconButton sx={{ p: '2px' }}>
                      <DeleteOutlineIcon
                        color={pennyDropCheck(values) ? 'disabled' : 'error'}
                        fontSize="small"
                      />
                      {/* <CardMedia
                        component="img"
                        src="/images/delete-red.svg"
                        alt="Delete Icon"
                        sx={{
                          width: 'unset',
                          color: pennyDropCheck(values) ? '#ccc' : 'rgba(196, 42, 51, 0.8)',
                        }}
                      /> */}
                    </IconButton>
                  </Box>
                )}
              </SubHeading>
              <ChequeUpload
                bankIndex={index}
                setValues={setValues}
                values={values}
                getFirstApplicantDocuments={getFirstApplicantDocuments as DocumentType}
                setGetFirstApplicantDocuments={
                  setGetFirstApplicantDocuments as React.Dispatch<
                    React.SetStateAction<DocumentType>
                  >
                }
                disabled={
                  pennyDropCheck(values) ||
                  getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                }
              />
              {bank.documentUploadLoader ? (
                <>
                  <GridItem>
                    <Skeleton />
                  </GridItem>
                  <GridItem>
                    <Skeleton />
                  </GridItem>
                  <GridItem>
                    <Skeleton />
                  </GridItem>
                  <GridItem>
                    <Skeleton />
                  </GridItem>
                  <GridItem>
                    <Skeleton />
                  </GridItem>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6}>
                    <BankTextField
                      name={`banks.${index}.ifscCode`}
                      bankFieldName={`banks.${index}.bankName`}
                      branchFieldName={`banks.${index}.bankBranch`}
                      disabled={isFieldDisabled}
                      pennydropFieldCheck={`banks.${index}.pennydropCheck`}
                      address1FieldName={`banks.${index}.address1`}
                      address2FieldName={`banks.${index}.address2`}
                      address3FieldName={`banks.${index}.address3`}
                      micrFieldName={`banks.${index}.micrCode`}
                      addressFieldName={`banks.${index}.bankAddress`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`banks.${index}.bankAccountNumber`}
                      label="Bank Account Number *"
                      placeholder="Enter Bank Account Number"
                      disabled={
                        isFieldDisabled ||
                        getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                      }
                      onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          'banks',
                          values.banks.map((bank, ind) => {
                            if (ind === index) {
                              return {
                                ...bank,
                                bankAccountNumber: value,
                                pennydropCheck: false,
                              };
                            }
                            return { ...bank, pennydropCheck: bank.pennydropCheck };
                          })
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MFTextField
                      name={`banks.${index}.micrCode`}
                      label="MICR Code"
                      placeholder="Enter MICR Code"
                      disabled={
                        ![
                          USER_ROLES.RM,
                          USER_ROLES.DISTRIBUTOR,
                          USER_ROLES.AMCAPPROVER,
                          USER_ROLES.SUBDISTRIBUTOR,
                        ].includes(role)
                        //For future reference
                        //check disable condition, if investor have the edit for all sections
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                    {values.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                    !['huf'].includes(application?.applicants[0]?.investorType || '') ? (
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'rgba(0,0,0,0.7)',
                          mt: { xs: 0, sm: 5 },
                          mb: { xs: 1, sm: 0 },
                        }}>
                        Bank type: Current{' '}
                      </Typography>
                    ) : (
                      <UseRadioGroup
                        formLabel="Bank type*"
                        name={`banks.${index}.bankAccountType`}
                        defaultValue="current"
                        items={Object.keys(
                          applicantStatusMasters[values.status as string] ===
                            applicantStatusMasters.NRI
                            ? NRIBankAccountTypeMaster
                            : BankAccountTypeMaster
                        ).map((accountType) => ({
                          label: accountType,
                          value:
                            applicantStatusMasters[values.status as string] ===
                            applicantStatusMasters.NRI
                              ? NRIBankAccountTypeMaster[accountType]
                              : BankAccountTypeMaster[accountType],
                        }))}
                        // onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                        //   setFieldValue(
                        //     'banks',
                        //     values.banks.map((bank, ind) => {
                        //       if (ind === index) {
                        //         return {
                        //           ...bank,
                        //           bankAccountType: value,
                        //           pennydropCheck: false,
                        //         };
                        //       }
                        //       return {
                        //         ...bank,
                        //         defaultBankAccount: false,
                        //         pennydropCheck: bank.pennydropCheck,
                        //       };
                        //     })
                        //   )
                        // }
                        disabled={
                          isFieldDisabled ||
                          getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <MFTextField
                      name={`banks.${index}.bankName`}
                      label="Bank Name"
                      placeholder="Enter Bank Name"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MFTextField
                      name={`banks.${index}.bankBranch`}
                      label="Bank Branch"
                      placeholder="Enter Bank Branch"
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MFTextField
                      name={`banks.${index}.bankAddress`}
                      multiline
                      rows={3}
                      label="Bank Address"
                      placeholder="Enter Bank Address"
                      disabled={true}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <MFCheckbox
                      name={`banks.${index}.defaultBankAccount`}
                      label="Default Bank Account *"
                      sx={{ letterSpacing: '1px' }}
                      onChange={() =>
                        setFieldValue(
                          'banks',
                          values.banks.map((bank, ind) => {
                            if (ind === index) {
                              return {
                                ...bank,
                                defaultBankAccount: !bank.defaultBankAccount,
                              };
                            }
                            return {
                              ...bank,
                              defaultBankAccount: false,
                            };
                          })
                        )
                      }
                      disabled={
                        isFieldDisabled ||
                        getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                      }
                    />
                  </Grid> */}
                </>
              )}
              {IS_PENNYDROP_APPLICABLE_FOR_AMC && (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                  }}>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Button
                      variant="outlined"
                      type="button"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 600,
                        fontSize: 14,
                        px: 3,
                        marginRight: 1,
                        width: '230px',
                        borderColor: 'primary.main',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        ':hover': {
                          color: 'common.white',
                          backgroundColor: 'primary.main',
                        },
                      }}
                      onClick={() => {
                        bank.bankAccountType =
                          values.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
                          !['huf'].includes(application?.applicants[0]?.investorType || '')
                            ? BankAccountTypeMaster.Current
                            : bank.bankAccountType;
                        handleBankVerification(values, index);
                      }}
                      disabled={bank.pennydropCheck || isFieldDisabled || pennyDropCheck(values)}>
                      {!bank.pennydropVerified || !bank.pennydropCheck
                        ? 'Verify Bank Details'
                        : 'Verified'}
                    </Button>
                    {bank.loading && (
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress size={30} />
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    sm={6}
                    xs={12}
                    sx={{ position: 'relative', bottom: 10, mb: { xs: 2, sm: 0 } }}>
                    <Typography
                      component="div"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: { xs: 0, sm: '10px 20px' },
                        borderRadius: '5px',
                        alignItems: 'flex-start',
                        mt: 2,
                        width: '100%',
                        boxSizing: 'border-box',
                      }}>
                      <InfoOutlinedIcon color="info" fontSize="small" />
                      <Typography
                        sx={{
                          color: 'rgba(41, 49, 57, 0.7)',
                          fontSize: '13px',
                          fontWeight: 500,
                          ml: 1,
                        }}>
                        {bank.defaultBankAccount && bank.pennydropCheck && !bank.pennydropVerified
                          ? 'Penny drop verification failed, Unable to verify your bank details. Please upload your bank account proofs In Document Details'
                          : "The Primary investor's bank account will be verified using a penny drop validation"}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                sm={12}
                xs={12}
                sx={{ position: 'relative', bottom: { xs: 5, sm: 20 }, mb: { xs: 2, sm: 0 } }}>
                <Typography
                  sx={{
                    color: 'rgba(41, 49, 57, 0.7)',
                    fontSize: '13px',
                    fontWeight: 500,
                    ml: 1,
                  }}>
                  {bank.pennydropCheck && !bank.defaultBankAccount && !bank.pennydropVerified
                    ? 'Penny drop verification failed'
                    : null}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
          {/* {values.banks.length < 2 && ![USER_ROLES.POAAPPROVER].includes(role) && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: 14,
                  mt: 2,
                }}
                onClick={() =>
                  setFieldValue('banks', [...values.banks, { ...bankObject, applicationId: id }])
                }
                disabled={
                  pennyDropCheck(values) ||
                  getDisableStatusForjointHolder(role, referenceDetail.applicant_type)
                }>
                Add Another Bank
              </Button>
            </Grid>
          )} */}
          <ProceedSaveLater
            saveLater={() => setFieldValue('saveType', 'save for later')}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
            loader={loading}
            clickedButton={values.saveType}
            disabled={pennyDropCheck(values)}
          />
          <ConfirmationDialog
            message={'Are you sure you want to delete bank details ?'}
            open={bankToDelete !== null}
            setOpen={() => setBankToDelete(null)}
            onSave={async () => {
              try {
                const { id } = application || {};
                const remainedBanks = values.banks.map((bank, i) => {
                  const { loading, ...rest } = bank;
                  if (i === bankToDelete?.index) {
                    return {
                      ...rest,
                      isActive: false,
                      defaultBankAccount: bank.defaultBankAccount ? false : bank.defaultBankAccount,
                    };
                  }
                  return { ...rest, defaultBankAccount: true };
                });
                setBankToDelete(null);
                if (id && bankToDelete?.bank.id) {
                  await dispatch(
                    updateApplication({
                      body: {
                        ...application,
                        banks: remainedBanks,
                        status:
                          !application?.hasPOA &&
                          checkInitialApplicationStatusIsChecker(application) &&
                          application?.status !== 'draft' &&
                          applyRoleBasedStatus(role) &&
                          application?.applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL
                            ? 'sent_to_amc_approver'
                            : application?.status,
                      },
                      applicationId: id,
                    })
                  );
                  return;
                }
                setFieldValue(
                  'banks',
                  remainedBanks.filter((bank) => bank.isActive)
                );
              } catch (e) {
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setBankToDelete(null)}
          />
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                All the Bank Details Should be Verified
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1.5,
                  '.MuiButton-root': {
                    minWidth: '100px',
                  },
                }}>
                <Button variant="contained" onClick={handleClose} sx={{ color: 'common.white' }}>
                  Ok
                </Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            onClose={handlePopUpClose}
            open={pennyDropStatusPopUp}
            sx={{
              '.MuiPaper-root ': {
                maxWidth: 400,
                p: { xs: 2, sm: '45px 40px' },
                borderRadius: '10px',
                height: 'unset',
                overflowY: 'auto',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiButton-root': {
                  minWidth: 100,
                  fontSize: 16,
                },
              }}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  color: '#337FC9',
                  textAlign: 'center',
                  mb: 5,
                }}>
                Unable to verify your bank details. Please upload your bank account proofs In
                Document Details
              </Typography>
            </Box>
            <Box
              sx={{
                gap: 1.5,
                '.MuiButton-root': {
                  minWidth: '100px',
                },
                textAlign: 'center',
              }}>
              <Button variant="contained" onClick={handlePopUpClose} sx={{ color: 'common.white' }}>
                Ok
              </Button>
            </Box>
          </Dialog>
        </Grid>
      )}
    </Formik>
  );
}
